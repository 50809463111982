import { FormikProps } from "formik"
import { ExchangeInfoInForm } from "./ExchangeFormContainer"
import { Dialog, DialogContent, DialogTitle, Grid, Typography } from "@material-ui/core"
import { TransparentButton } from "../../../components/Buttons/TransparentButton"
import { BlackButton } from "../../../components/Buttons/BlackButton"
import { useContext } from "react"
import { ExchangeFormContext } from "../../../providers/ExchangeFormCreationProvider"
import { useHistory } from "react-router-dom"

export const FinalDialog = ({
    formik,
    open,
    closeDialog
}: {
    formik: FormikProps<ExchangeInfoInForm>,
    open: boolean,
    closeDialog: () => void
}) => {
    const { setStep } = useContext(ExchangeFormContext);
    const history = useHistory();

    const onClose = () => {
        closeDialog();
        formik.resetForm();
        setStep(0);
    }

    return (
        <Dialog open={open} onClose={onClose} style={{ textAlign: 'center' }}>
            <DialogContent style={{ marginTop: '2em', marginBottom: '2em' }}>
                <Grid container>
                    <Grid item xs={12} >
                        <img src="/svgs/coupons/exchange_dialog_icon.svg" width={76} height={82} alt="An image that shows how an exchange work" />
                    </Grid>
                    <Grid item xs={12}>
                        <DialogTitle>{formik.values.amountOfExchangesAvailable} Exchange</DialogTitle>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" style={{ color: '#000000' }}>
                            validi fino al {formik.values.endDate.toLocaleDateString()}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" style={{ color: '#000000' }}>
                            saranno pubblicati con successo in qualche secondo!
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', marginTop: '1em' }}>
                        <TransparentButton
                            onClick={() => {
                                history.push('/user/exchanges')
                            }}
                            style={{ width: '100%', marginRight: '16px' }}
                            text={'Vai ai miei exchange'}
                        />
                        <BlackButton
                            onClick={onClose}
                            text={'Crea altro exchange'}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}
import {createSlice, Dispatch, PayloadAction} from "@reduxjs/toolkit";
import {logger} from "../../utilities/logger/logger";
import {RootState} from "../store";
import config from "../../config";
import {ethers, Wallet} from "ethers";

import {getWallet} from '../../api/walletAPI';
import {addPropertyToUserProfile, getUserDataById} from '../../api/userAPI';

import {userSetCurrentProfile, userSetUserProfile} from "./userSlice";
import type {ExtendedUserData} from "../../types/user.type";

import SturdyWebSocket from "sturdy-websocket";


type ethersInitialState = {
    currentAccount: string | null,
    ethersInstance: Wallet | null
}
const initialState: ethersInitialState = {
    currentAccount: null,
    ethersInstance: null
};

export const ethersSlice = createSlice({
    name: 'Ethers',
    initialState,
    reducers: {
        ethersStartCheck(state) {
            state.ethersInstance = null;
            state.currentAccount = '';
        },
        ethersSetWallet(state, action: PayloadAction<{currentAccount: string, ethersInstance: Wallet | null }>) {
            state.ethersInstance = action.payload.ethersInstance;
            state.currentAccount = action.payload.currentAccount;
        }
    }
})

export const {ethersSetWallet, ethersStartCheck} = ethersSlice.actions;

export const ethersCheck = () => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        logger.log("ethersslice, etherscheck: start");
        try {
            dispatch(ethersStartCheck());
            const userID = localStorage.getItem('userId');
            const wallet = await getWallet(true); //call to our walletApi service
            const user = await getUserDataById( userID );
            logger.log("ethersSlice, ethersCheck: wallet ", wallet, " user: ", user);
            console.log(wallet);

            if(user == null){
                throw new Error('[ethers] user currentProfile is empty');
            }

            if(userID && (
                user.additional_properties == null || //no wallet registered yet in the profile
                user.additional_properties.commonshoodWallet == null || //no wallet registered yet in the profile
                user.additional_properties.commonshoodWallet != wallet.address //wallet already present, but it is different from the one in the walletApi service
            )){
                //adding to profile saved in firstlife oauth
                await addPropertyToUserProfile(wallet.address, userID);
                //now we have to add the wallet to the saved profiles in this app, else they will remain without wallet and break the app
                let userProfile : ExtendedUserData | null = getState().user.userProfile;
                if(userProfile != null) {
                    userProfile = {
                        ...userProfile,
                        additional_properties: {
                            commonshoodWallet: wallet.address
                        }
                    }
                    dispatch(userSetUserProfile({profile: userProfile}));
                    dispatch(userSetCurrentProfile({profile: userProfile}));
                }else{
                    logger.error(`userProfile is undefined in ethersCheck, this won't allow the profile to be correctly updated with the wallet address`);
                }
            }

            if(wallet.found){
                // const provider = new ethers.providers.JsonRpcProvider(config.blockchain.rpcEndpoint);
                const provider = new ethers.providers.WebSocketProvider(
                    new SturdyWebSocket(config.blockchain.rpcEndpoint)
                );
                const ethersInstance = new ethers.Wallet(wallet.privateKey, provider)
                logger.info('ethers instance created', ethersInstance);
                console.log(ethersInstance);
                dispatch(ethersSetWallet({ ethersInstance: ethersInstance, currentAccount: wallet.address}));
            }else{
                logger.error('It was not possible to get a wallet for this user account');
                dispatch(ethersSetWallet({ethersInstance: null, currentAccount: ''}));
            }

        } catch (error) {
            logger.info(`an error occurred: ${error} `);
            dispatch(ethersSetWallet({ethersInstance: null, currentAccount: ''}));
        }
    }
};

export default ethersSlice.reducer;
import { Button, makeStyles } from "@material-ui/core"
import { CSSProperties } from "react";

export const BlackButton = ({ text, onClick, disabled, style, width }: {
    text: string,
    onClick: any,
    disabled?: boolean,
    width?: number,
    style?: CSSProperties
}) => {
    const legacyStyle = makeStyles({
        button: {
            background: 'black',
            borderRadius: '15px',
            textTransform: 'none',
            height: '34px',
            width: '100%',
            '&:hover': {
                background: 'rgba(235, 176, 76, 1)',
                color: 'black'
            },
            '&:disabled': {
                background: 'rgba(129, 129, 129, 1)',
                color: 'black'
            },
            '&:focus': {
                background: 'rgba(218, 93, 62, 1)',
                color: 'black'
            }
        },
        '@media (max-width:600px)': {
            button: {
                background: 'black',
                borderRadius: '15px',
                textTransform: 'none',
                height: '34px',
                width: '100%',
                fontSize: '9px'
            }
        },

    })
    const classes = legacyStyle();
    return (
        style === undefined ? (
            <Button onClick={onClick} className={classes.button}
                disabled={disabled ? disabled : false} style={{ color: 'white', width: width }}> {text}</Button >
        ) : (
            <Button onClick={onClick} className={classes.button}
                disabled={disabled ? disabled : false} style={style}> {text}</Button >
        )
    )
}
import { Button, makeStyles } from "@material-ui/core"
import { CSSProperties } from "react";

const legacyStyle = makeStyles({
    button: {
        color: 'black',
        background: 'none',
        borderRadius: '15px',
        border: '1px solid rgba(24, 27, 29, 1)',
        textTransform: 'none',
        height: '34px',
        minWidth: '100px',
        '&:hover': {
            background: 'rgba(235, 176, 76, 1)',
            color: 'black'
        },
        '&:disabled': {
            background: 'rgba(129, 129, 129, 1)',
            color: 'black'
        },
        '&:focus': {
            background: 'rgba(218, 93, 62, 1)',
            color: 'black'
        }
    },
    '@media (max-width:600px)': {
        button: {
            color: 'black',
            background: 'none',
            borderRadius: '15px',
            border: '1px solid rgba(24, 27, 29, 1)',
            textTransform: 'none',
            height: '34px',
            width: '100%',
            fontSize: '10px'
        }
    }

})

export const TransparentButton = ({ text, onClick, style, disabled = false, width }: {
    text: string,
    onClick: any,
    style?: CSSProperties,
    disabled?: boolean
    width?: number
}) => {
    const classes = legacyStyle();
    if (style === undefined) {
        return (
            <Button style={{ width: width }} disabled={disabled} onClick={onClick} className={classes.button}> {text}</Button >
        );
    }
    else {
        return (
            <Button disabled={disabled} onClick={onClick} className={classes.button} style={style}> {text}</Button >
        )
    }
}
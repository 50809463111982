import React, { CSSProperties, MouseEvent } from 'react';
import Button from '@material-ui/core/Button';
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: '242px',
            height: '50px',
            flexShrink: 0,
            borderRadius: '15px',
            border: '1px dashed #000',
        },
        label: {
            textTransform: 'none'
        },
    });

interface DashedButtonProps extends WithStyles<typeof styles> {
    onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
    children: React.ReactNode;
    style?: CSSProperties
}

const CustomButton: React.FC<DashedButtonProps> = ({ classes, onClick, children, style }) => {
    return (
        <Button variant="outlined" className={classes.root} onClick={onClick} style={style}>
            {children}
        </Button>
    );
};

const DashedButton = withStyles(styles)(CustomButton);

export default DashedButton;


import config from "../config";
import axios from "../utilities/backend/axios-notifications";

export type CoinList = {
    symbol: string,
    amount: number,
    decimals: number
}


export type RealtimeNotificationType = {
    _id: string,
    message: string
}

export type NotificationParameters = {
    eventType: string
    symbol: string,
    decimals: number,
    owner: string,
    from: string
    contract_address: string,
    amount: number,
    sender: string,
    receiver: string,
    member: string,
    member_name: string,
    crowdsaleId: string,
    buyer: string,
    title: string,
    name: string,
    newExpiration: number,
    to_name: string,
    from_name: string,
    to_realm: string,
    from_realm: string,
    buyer_name: string,
    buyer_realm: string,
    firstlifePlaceID: string,
    newRole: number,
    coinOffered: CoinList[],
    coinAccepted: CoinList[],
    timestamp: number,
    lender?: string,
    borrower?: string,
    itemID?: string,
    itemName?: string,
    borrowerFSName?: string,
    lenderFSName?: string
}
export type NotificationType = {
    id: string,
    body: {
        category: string,
        message: string,
        params: NotificationParameters
    }
    delivered: true,
    timestamp: string,
    type: string
}

export const getAllMyNotification = async () => {
    const url = '/notifications';
    const params = new URLSearchParams({ 'page': '1', 'limit': '20', 'events': 'ALL', 'realm': config.realm.REALM});
    const response = await axios.get(url, { params })
    const data: NotificationType[] = response.data
    return data
}

export const getNotificationPage = async (page: number, amount: number, calendarAddress: string) => {
    const url = '/notifications';
    let params = new URLSearchParams({ 'page': page.toString(), 'limit': amount.toString(), 'calendar_address': calendarAddress, 'realm': config.realm.REALM });
    const response = await axios.get(url, { params })
    const data: NotificationType[] = response.data
    return data;
}

export const getUnreadNotification = async () => {
    const url = '/notifications/unread';
    let params = new URLSearchParams({'realm': config.realm.REALM});
    const response = await axios.get(url, { params })
    const notification: RealtimeNotificationType[] = response.data
    const filteredNotfs = notification.filter((item) => {
        return !JSON.parse(item.message).eventType.includes('COIN_CREATED') && !JSON.parse(item.message).eventType.includes('COLLECTION_CREATED') && !JSON.parse(item.message).eventType.includes('TRANSFER');
    });
    return filteredNotfs;
}

export const markNotificationAsRead = async (notification_id: string) => {
    const url = '/notifications/read';
    let params
    if (notification_id) {
        params = new URLSearchParams({ 'notification': notification_id , 'realm': config.realm.REALM })
    } else {
        params = new URLSearchParams({ 'notification': 'ALL', 'realm': config.realm.REALM })
    }

    const response = await axios.get(url, { params })

    return response.data;
}

export const getMostFrequentUsers = async (limit: number, daoAddress: string | null): Promise<string[]> => {
    const url = '/notifications/suggestedUsers';
    let params;
    if (!daoAddress) {
        params = new URLSearchParams({ 'limit': limit.toString(), 'mode': 'FREQUENT', 'realm': config.realm.REALM });
    } else {
        params = new URLSearchParams({ 'limit': limit.toString(), 'dao_address': daoAddress, 'mode': 'FREQUENT' , 'realm': config.realm.REALM});
    }
    const response = await axios.get(url, { params })
    return response.data
}

export const getMostRecentUsers = async (limit: number, daoAddress: string | null): Promise<string[]> => {
    const url = '/notifications/suggestedUsers';
    let params;
    if (!daoAddress) {
        params = new URLSearchParams({ 'limit': limit.toString(), 'mode': 'RECENT', 'realm': config.realm.REALM });
    } else {
        params = new URLSearchParams({ 'limit': limit.toString(), 'dao_address': daoAddress, 'mode': 'RECENT' , 'realm': config.realm.REALM});
    }
    const response = await axios.get(url, { params })
    return response.data
}

import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../store/hooks";
import { getTokensOwnedFromMetadata } from "../../../../api/coinAPI";
import { Grid, useMediaQuery } from "@material-ui/core";
import { CouponCard } from "../MyCoupons/CouponCard";
import { Skeleton } from "@material-ui/lab";
import { NoItemsDisplay } from "../../../LibOfThings/BasicComponents/NoItemsDisplay";

export const ListCouponInWallet = () => {
    const account = useAppSelector(state => state.user.currentProfile);
    const isMobile = useMediaQuery('(max-width:600px)');
    const [coupons, setCoupons] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async () => {
            setLoading(true);
            const address = account?.additional_properties?.commonshoodWallet;
            if (address != null) {
                const tokens = await getTokensOwnedFromMetadata(
                    address,
                    'goods',
                    1,
                    true
                );
                setCoupons(tokens);
            }
            setLoading(false);
        })()
    }, []);

    return (
        <Grid container spacing={1} style={{ margin: '16px' }}>
            {
                loading && (
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <Skeleton variant="rect" height={150} />
                    </Grid>
                )
            }
            {
                (coupons.length === 0 && !loading) && (
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <NoItemsDisplay style={{ marginTop: '3em' }} text={'Non hai Coupon'} />
                    </Grid>
                )
            }
            {
                isMobile ? (
                    <>
                        {
                            coupons.slice(0, 3).map((c: any) => {
                                return (
                                    <Grid item xs={4} md={3}>
                                        <CouponCard renderAmount={true} coupon={c} />
                                    </Grid>
                                )
                            })
                        }
                    </>

                ) : (
                    <>
                        {
                            coupons.slice(0, 4).map((c: any) => {
                                return (
                                    <Grid item xs={4} md={3}>
                                        <CouponCard renderAmount={true} coupon={c} />
                                    </Grid>
                                )
                            })
                        }
                    </>

                )
            }

        </Grid>
    );
}
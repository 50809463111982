import { Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useContext, useEffect, useState } from "react"
import { useAppSelector } from "../../../../store/hooks";
import { Preorder } from "../../../../types/calendar.type";
import { getActivePreordersAPI } from "../../../../api/calendar";
import { MyRequestsPreorderInList } from "../PreorderInList/MyRequestsPreorderInList";
import { PaginationContainer } from "../../../../components/UI/Pagination/PaginationContainer";
import { CalendarContractContext } from "../../../../providers/CalendarContractProvider";
import { CustomToaster } from "../../../Notifications/CustomToaster";
import { NoItemsDisplay } from "../../BasicComponents/NoItemsDisplay";
import toast from "react-hot-toast";

export const ListOfActivePreorders = ({ shouldRefresh }: {
    shouldRefresh: boolean,
}) => {
    const [preordersToShow, setPreordersToShow] = useState<Preorder[]>([]);
    const [numberOfPreorders, setNumberOfPreorders] = useState<number>(0);
    const { preordersPerPage, getPreordersInLastXHours } = useContext(CalendarContractContext);
    const loading = useAppSelector(state => state.calendar.loading);
    const user = useAppSelector(state => state.user.user);
    const { getUserStats } = useContext(CalendarContractContext);
    const account = useAppSelector(state => state.user.currentProfile);

    useEffect(() => {
        console.log('should refresh', shouldRefresh)
        if (shouldRefresh) {
            (async () => {
                console.log('refreshing active preorders');
                await getPreordersFromMetadata(0);
            })()
        }
    }, [shouldRefresh])


    useEffect(() => {
        (async () => {
            await getPreordersFromMetadata(0);
            if (account?.additional_properties?.commonshoodWallet) {
                const almostFinishedPreorders = await getPreordersInLastXHours(
                    account?.additional_properties?.commonshoodWallet,
                    false,
                    48
                )
                if (almostFinishedPreorders.length > 0) {
                    toast.error('Il proprietario è in attesa di ricevere il suo oggetto indietro, contattalo tramite email per restituirglielo!')
                }
            }
        })()
    }, [])

    const handleClickOnPagination = async (selectedPage: number) => {
        await getPreordersFromMetadata(selectedPage);
    }

    const getPreordersFromMetadata = async (page: number) => {
        const address = user?.additional_properties?.commonshoodWallet;
        if (address) {
            const activePreorders = await getActivePreordersAPI(
                address,
                false,
                page * preordersPerPage,
                preordersPerPage
            );
            setPreordersToShow(activePreorders.preorders.preorders);
            setNumberOfPreorders(activePreorders.preorders.numberOfPreorders);
        }
    }

    const getTextForStepper = (preorder: Preorder) => {
        switch (preorder.status) {
            case 'Accepted':
                return 'Accettata';
            case 'Started':
                return 'Iniziata';
            case 'Completed':
                return 'Completata';
            default:
                return '';
        }
    }

    if (preordersToShow.length > 0) {
        return (
            <Grid container style={{ position: 'relative', top: '3em' }} spacing={4}>
                <CustomToaster />
                {
                    preordersToShow.map((p) => {
                        return <Grid style={{ marginBottom: '100px' }} key={p.preorderID} item xs={12}>
                            <MyRequestsPreorderInList
                                preorder={p}
                                textUnderStepper={getTextForStepper(p)}
                                callback={async () => {
                                    await getPreordersFromMetadata(0);
                                    await getUserStats();
                                }}
                            />
                        </Grid>
                    })
                }
                <Grid item xs={12} style={{ marginBottom: '15em' }}>
                    <PaginationContainer pageCount={Math.ceil(numberOfPreorders / preordersPerPage)} onChange={handleClickOnPagination} />
                </Grid>
            </Grid>
        )
    } else {
        return (
            <NoItemsDisplay style={{ marginTop: '3em' }} />
        )
    }
}
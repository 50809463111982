import { Badge, Box, Tab, Tabs, useMediaQuery } from "@material-ui/core";
import { useContext, useState } from "react";
import { styled } from '@material-ui/core/styles';
import { CalendarContractContext } from "../../../providers/CalendarContractProvider";
import { MyRequestsCounters } from "./MyRequestsContainer";

export const MyRequestsDashboardMenu = ({ handleMenuChange, showWarning, myStats }: {
    handleMenuChange: (newMenu: number) => void,
    showWarning?: boolean,
    myStats: MyRequestsCounters
}) => {
    const [value, setValue] = useState(0);
    const isMobile = useMediaQuery('(max-width: 600px)');
    const { stats } = useContext(CalendarContractContext);

    const handleChange = (event: any, newValue: number) => {
        setValue(newValue);
        handleMenuChange(newValue);
    };

    return (
        <Box style={{ borderColor: 'divider' }}>
            {
                (isMobile && stats != undefined && myStats.canceled >= 0) ? (
                    <>
                        <Tabs value={value} style={{ padding: '16px' }} onChange={handleChange} aria-label="basic tabs example" centered>
                            <CustomTab width="25%" icon={
                                <Badge badgeContent={stats.pending} color={'secondary'} max={99}>
                                    <img src="/svgs/pending_request_borrower.svg"></img>
                                </Badge>
                            } />
                            <CustomTab width="25%" badgeBackgroundColor="transparent" icon={
                                <>
                                    {
                                        showWarning ? (
                                            <Badge badgeContent={<img src="/svgs/warning.svg" style={{ backgroundColor: 'transparent' }}
                                                alt="warning icon" />} showZero max={99}>
                                                <img src="/svgs/active_request_borrower.svg"></img>
                                            </Badge>
                                        ) : (
                                            <Badge badgeContent={stats.accepted + stats.started} color={'secondary'} max={99}>
                                                <img src="/svgs/active_request_borrower.svg"></img>
                                            </Badge>
                                        )
                                    }
                                </>

                            } />
                            <CustomTab width="25%" icon={
                                <Badge badgeContent={stats.ended} color={'secondary'} max={99}>
                                    <img src="/svgs/completed_request_borrower.svg"></img>
                                </Badge>
                            }
                            />
                            <CustomTab width="25%" icon={
                                <Badge badgeContent={myStats.canceled} color={'secondary'} max={99}>
                                    <img src="/svgs/denied_request_borrower.svg"></img>
                                </Badge>
                            } />
                        </Tabs>
                    </>
                ) : (
                    <>
                        <Tabs variant={'fullWidth'} value={value} onChange={handleChange} aria-label="basic tabs example">
                            <CustomTab width={'25%'} label={`In attesa${stats !== undefined && myStats.canceled >= 0 && stats.pending > 0 ? ` (${stats.pending})` : ''}`} />
                            {
                                showWarning ? (
                                    <CustomTabWithImage imgSrc="/svgs/warning.svg" label={`Attive${stats !== undefined && myStats.canceled >= 0 && stats.accepted + stats.started > 0 ? ` (${stats.accepted + stats.started})` : ''}`} />
                                ) : (
                                    <CustomTab width={'25%'} label={`Attive${stats !== undefined && myStats.canceled >= 0 && stats.accepted + stats.started > 0 ? ` (${stats.accepted + stats.started})` : ''}`} />
                                )
                            }
                            <CustomTab width={'25%'} label={`Completate${stats !== undefined && myStats.canceled >= 0 && stats.ended > 0 ? ` (${stats.ended})` : ''}`} />
                            <CustomTab width={'25%'} label={`Rifiutate${myStats !== undefined && myStats.canceled > 0 ? ` (${myStats.canceled})` : ''}`} />
                        </Tabs>
                    </>
                )
            }
        </Box>
    )
}

export const CustomTab = styled(Tab)(({ badgeBackgroundColor, width }: { badgeBackgroundColor?: string, width?: string }) => ({
    textTransform: 'none',
    borderBottom: '1px solid black',
    width: '100%',
    '@media (max-width: 600px)': {
        width: width != null ? width : '20%'  
    },
    '& .MuiBadge-badge': {
        backgroundColor: badgeBackgroundColor || 'black', // Default to black if not provided
        color: 'white',
        marginTop: '5px',
    }
}));
export const CustomTabWithImage = ({ label, imgSrc, ...props }: {
    label: string,
    imgSrc: string
}) => {
    return (
        <CustomTab
            label={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {label}
                    {imgSrc && <img src={imgSrc} alt="icon" style={{ marginLeft: '8px' }} />}
                </div>
            }
            {...props}
        />
    );
};

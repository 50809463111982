import { CSSProperties } from "react"


export type TMobileTutorialProgressStepNumber = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;

export interface IMobileTutorialProgressProps {
    step: TMobileTutorialProgressStepNumber;
    style?: CSSProperties
}

const MobileTutorialProgress: React.FC<IMobileTutorialProgressProps> = (props: IMobileTutorialProgressProps) =>  {
    return(
        <img src={`/svgs/tutorial/circles-bar-${props.step}.svg`} style={props.style} alt="Mobile tutorial progress" />
    )
}

export default MobileTutorialProgress;
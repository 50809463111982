import React from 'react';
import { createStyles, makeStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { BlackButton } from '../../components/Buttons/BlackButton';
import { Backdrop, CircularProgress, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { CosoSeparator } from '../LibOfThings/BasicComponents/CosoSeparator';
import { ExchangeTokenEntry } from '../LibOfThings/Exchange/ExchangeTokenEntry';
import { IExchangeEntryCardInfo } from '../LibOfThings/Exchange/ExchangeEntryCard';
import { IExchangeSummaryData } from './ExchangeSummaryDialog';
import NewBlackButton from '../../components/Buttons/NewBlackButton';
import { exchangeAccept } from '../../store/slices/exchangeSlice';
import { useAppDispatch } from '../../store/hooks';

interface IExchangeAcceptDialogProps {
    exchangeInfo: IExchangeEntryCardInfo;
    onAccept: (exchangeInfo: IExchangeSummaryData) => void;
}

const useStyles = makeStyles((theme) => ({
    exchangeAcceptDialog: {
        maxWidth: '784px',
        borderRadius: '30px',
        background: '#FFF',
        boxShadow: '0px 3px 15px 2px rgba(72, 55, 146, 0.20)',
        margin: '20px',
        padding: '20px',
        position: 'relative'
    },
    closeButtonX: {
        position: 'absolute',
        top: '-29px',
        right: '-2px',
        cursor: 'pointer',
        width: '27px',
        height: '27px',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
    },
    circularButton: {
        borderRadius: '50%',
        minWidth: '0',
        width: '36px',
        height: '36px',
        backgroundColor: '#DA5D3E',
        color: '#FFF',
        '&:hover': {
            backgroundColor: '#B64935',
        },
    },
    acceptButton: {
        color: 'var(--Bianco-FFFFFF, #FFF)',
        textAlign: 'center',
        fontFamily: '"Be Vietnam Pro", sans-serif',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '16px',
    },
    title: {
        color: '#181B1D',
        textAlign: 'center',
        fontFamily: '"Be Vietnam Pro", sans-serif',
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
    },
    dialogText: {
        color: 'var(--Text-abilitato, #181B1D)',
        // textAlign: 'right',
        fontFamily: '"Be Vietnam Pro", sans-serif',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '25px',
    },
    subtext: {
        color: 'var(--Text-abilitato, #181B1D)',
        textAlign: 'right',
        fontFamily: '"Be Vietnam Pro", sans-serif',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
    },

}))

export default function ExchangeAcceptDialog(props: IExchangeAcceptDialogProps) {
    const [open, setOpen] = React.useState(false);
    const [acceptedExchangesAmount, setAcceptedExchangesAmount] = React.useState(1);
    const style = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
    const dispatch = useAppDispatch();

    const [accepting, setAccepting] = React.useState(false);

    const { exchangeInfo, onAccept } = props;

    const handleAcceptRequest = async () => {
        setAccepting(true);
        const exchangeAddress = props.exchangeInfo.apiObject.address;
        const coinsRequired = props.exchangeInfo.apiObject.coinsRequiredData;
        await dispatch(exchangeAccept(exchangeAddress, coinsRequired, acceptedExchangesAmount));
        setOpen(false);
        onAccept({ exchangeInfo: exchangeInfo, acceptedCount: acceptedExchangesAmount })
        setAccepting(false);
    }

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <NewBlackButton onClick={handleClickOpen} style={{ width: '145px', height: '38px', color: 'white', margin: '0 auto' }}>
                <span>Accetta</span>
            </NewBlackButton>
            <Backdrop className={style.backdrop} open={open}>
                <Grid container direction="column" justifyContent='center' alignContent='center' alignItems='center' className={style.exchangeAcceptDialog} >
                    {/* Outside of the component flow, the X button above the top right corner */}
                    <div className={style.closeButtonX} onClick={handleClose}>
                        <img src="/images/x.png" alt="" />
                    </div>
                    {/* Dialog Title */}
                    <Typography className={style.title} style={{ marginBottom: '50px' }}>Scambio con {exchangeInfo.fromUserEmail}</Typography>
                    {/* Exchange Amount Row */}
                    <Grid item container direction="row" justifyContent='center' alignItems='center'>
                        <Grid item style={{ marginRight: isMobile ? '0px' : '50px', marginBottom: isMobile ? '22px' : '0px' }}>
                            <Typography className={style.dialogText}>Quanti scambi vuoi effettuare?</Typography>
                        </Grid>
                        <Grid item container direction="row" style={{ width: 'fit-content' }} alignItems='center'>
                            <Button className={style.circularButton} onClick={() => setAcceptedExchangesAmount(acceptedExchangesAmount - 1)} disabled={acceptedExchangesAmount === 1}>
                                <RemoveIcon />
                            </Button>
                            <Grid item container direction="column" justifyContent='center' alignItems='center' style={{ width: 'fit-content' }}>
                                <Grid item container justifyContent="center" alignItems="center" style={{ margin: '0 16px', width: 137, height: 35, borderRadius: 21, backgroundColor: '#f5f5f5', userSelect: 'none' }}>
                                    <strong>{acceptedExchangesAmount}</strong>
                                </Grid>
                                <Typography className={style.subtext} style={{ userSelect: 'none' }}>Scambi disponibili: {exchangeInfo.maxExchangeAmount}</Typography>
                            </Grid>

                            <Button className={style.circularButton} onClick={() => setAcceptedExchangesAmount(acceptedExchangesAmount + 1)} disabled={acceptedExchangesAmount === exchangeInfo.maxExchangeAmount}>
                                <AddIcon />
                            </Button>
                        </Grid>
                    </Grid>
                    {/* Separator */}
                    <CosoSeparator direction="Horizontal" style={{ width: '79%', margin: '20px auto', borderColor: '#ABABAB' }} />
                    {/* Exchange Overview */}
                    <Typography align='center' className={style.dialogText} style={{ margin: '30px 0' }}>Risultato dello scambio</Typography>
                    <Grid container direction="row" justifyContent='center' alignItems='center'>
                        {/* Exchange Details */}
                        <Grid item container direction="column" justifyContent='center' alignItems='center' style={{ width: 'fit-content' }}>
                            <Typography className={style.dialogText}>Ricevi</Typography>
                            {
                                exchangeInfo.offeredTokens.map((t) => (
                                    <Grid item container direction="row" justifyContent="center" alignItems="center" style={{ width: 150, height: 20, textTransform: 'uppercase' }}>
                                        <ExchangeTokenEntry symbol={t.symbol} amount={t.amount * acceptedExchangesAmount} />
                                    </Grid>
                                ))
                            }
                        </Grid>
                        {/* Exchange Divider, vertical if desktop, horizontal if mobile */}
                        <Grid item xs={isMobile ? 12 : false}>
                            <CosoSeparator direction={isMobile ? 'Horizontal' : 'Vertical'} style={{ width: isMobile ? '196px' : 'auto', height: isMobile ? 'auto' : '92px', margin: isMobile ? '16px auto' : '0 40px', borderColor: '#ABABAB' }} />
                        </Grid>
                        <Grid item container direction="column" justifyContent='center' alignItems='center' style={{ width: 'fit-content' }}>
                            <Typography className={style.dialogText}>Offri</Typography>
                            {
                                exchangeInfo.desiredTokens.map((t) => (
                                    <Grid item container direction="row" justifyContent="center" alignItems="center" style={{ width: 150, height: 20, textTransform: 'uppercase' }}>
                                        <ExchangeTokenEntry symbol={t.symbol} amount={t.amount * acceptedExchangesAmount} />
                                    </Grid>
                                ))
                            }
                        </Grid>
                        {/* Exchange Accept Button */}
                        <Grid item xs={12}>
                            <NewBlackButton onClick={handleAcceptRequest} style={{
                                width: 277, height: 50, color: '#FFF', display: 'block', margin: '0 auto',
                                textAlign: 'center',
                                fontFamily: '"Be Vietnam Pro", sans-serif',
                                fontSize: '20px',
                                fontStyle: 'normal',
                                fontWeight: 600,
                                lineHeight: '16px',
                                marginTop: isMobile ? '34px' : '42px',
                                marginBottom: '25px'
                            }}
                                disabled={accepting}
                            >
                                {!accepting &&
                                    <span>Accetta scambio</span>
                                }
                                {accepting &&
                                    <Grid container direction="row" justifyContent='center' alignItems='center'>
                                        <CircularProgress color='inherit' style={{ marginRight: '1rem' }} />
                                        <div>Accetto...</div>
                                    </Grid>
                                }
                            </NewBlackButton>
                        </Grid>
                    </Grid>
                    {/* Exchange Cancel Separator */}
                    <svg width="624" height="2" viewBox="0 0 624 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1H623" stroke="#ABABAB" stroke-linecap="round" />
                    </svg>
                    <Button style={{ color: '#DA5D3E' }} onClick={handleClose}>
                        Annulla
                    </Button>
                </Grid>
            </Backdrop>
        </div>
    );
}
import {
    Box, Grid, useMediaQuery,
} from "@material-ui/core";
import HomeIconForPillMenu from "../../../../assets/img/home_icon.svg"
import WalletIconForPillMenu from "../../../../assets/img/wallet_icon.svg"
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import { RealmContext } from "../../../../providers/RealmProvider";

type ListMenuPropsType = {
    navHandler: (path: string) => void;
    itemList: any;
    isVertical: boolean;
};

const MenuHomeMobile = (props: ListMenuPropsType) => {
    const history = useHistory();
    const { isCurrentRealmCOSO, isCurrentRealmChGarden } = useContext(RealmContext);
    const isMobile = useMediaQuery('(max-width:967px)');

    const openWalletPage = () => {
        history.push("/user/wallet");
    }
    const openHomePage = () => {
        history.push("/");
    }

    const getMarginBottom = () => {
        if (history.location.pathname.includes('/items/')) {
            return '2em';
        } else {
            return '2em';
        }
    }

    return (
        <>
            {
                isMobile && (
                    <Box
                        style={{
                            backgroundColor: 'white',
                            borderRadius: "38px",
                            border: "1px solid white",
                            boxShadow: "2px 3px 4px 0px rgba(0, 0, 0, 0.25)",
                            padding: '0 1rem',
                            height: "57.45px",
                            width: "119.45px",
                            position: 'fixed',
                            bottom: `${getMarginBottom()}`,
                            left: '50%',
                            transform: 'translateX(-50%)',
                        }}
                    >
                        <Grid
                            container
                            direction={"row"}
                            justifyContent="space-evenly"
                            alignItems="center"
                            style={{ height: '100%' }}
                        >
                            <Grid item
                            >
                                <img src={HomeIconForPillMenu} alt="Homepage button" style={{ cursor: "pointer" }} onClick={openHomePage} />
                            </Grid>
                            <Grid item>
                                <img src={WalletIconForPillMenu} alt="Wallet button" style={{ cursor: "pointer" }} onClick={openWalletPage} />
                            </Grid>
                            {
                                isCurrentRealmChGarden && (
                                    <Grid item>
                                        <img src={'/svgs/exchange.svg'} alt="Exchange button" style={{ cursor: "pointer" }} onClick={() => history.push('/user/exchanges')} />
                                    </Grid>
                                )
                            }
                        </Grid>
                    </Box>
                )
            }
        </>
    );
};

export default MenuHomeMobile;

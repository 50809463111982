import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { CSSProperties } from "react";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, 1)',
        borderRadius: '15px',
        width: '100%',
        textTransform: 'none',
        cursor: 'pointer',
        '&:hover, &:active': {
            background: 'transparent',
            borderColor: '#DA5D3E',
            color: '#DA5D3E'
        }
    },
    title: {
        fontFamily: 'Roboto',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '16.41px',
        letterSpacing: '-0.02em',
        fontStyle: 'normal',
        display: 'flex',
        whiteSpace: 'nowrap',
        position: 'relative'
    }
});

export const AccountAction = ({
    title,
    link,
    onClick,
    style
}: { link: string, title: string, onClick?: () => {}, style?: CSSProperties }) => {
    const classes = useStyles();

    return (
        <Link to={link} style={{ textDecoration: 'none', color: 'black', ...style }} onClick={onClick}>
            <Button className={classes.root}>{title}</Button>
        </Link>
    );
}

export const AccountActionWithDashedBorder = ({
    title,
    link,
    onClick
}: { link: string, title: string, onClick?: any }) => {
    const classes = useStyles();

    return (
        <Link to={link} style={{ textDecoration: 'none', color: 'black' }}>
            <Button className={classes.root} onClick={onClick} style={{
                border: '1px dashed rgba(24, 27, 29, 1)'
            }}>{title}</Button>
        </Link>
    );
}

export const AccountActionWithOnClick = ({ title, onClickHook }: {
    title: string,
    onClickHook: () => void
}) => {
    const classes = useStyles();

    return (
        <Button onClick={onClickHook} className={classes.root}>{title}</Button>
    );
}
import { Badge, CardMedia, Typography, makeStyles } from "@material-ui/core";
import { Item } from "../../../../types/libofthings.type";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { getCollectionFromSymbol, getURIByIDSlice } from "../../../../store/slices/nftSlice";
import config from "../../../../config";
import { getFromIpfs, getJsonFromResource } from "../../../../api/resourceAPI";
import Skeleton from "@material-ui/lab/Skeleton";
import { CollectionData } from "../../../../types/nft.types";

const useStyles = makeStyles({
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
        borderRadius: '18px 18px 0px 0px'
    },
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        borderRadius: '18px 18px 0px 0px'
    },
    text: {
        color: 'white',
        zIndex: 2,
    }
});

export const ItemCardMedia = ({ itemID, collection, paused }: {
    itemID: number,
    collection: CollectionData | undefined,
    paused: boolean
}) => {
    const classes = useStyles();
    const [uri, setUri] = useState<string | null>(null);
    const [metadata, setMetadata] = useState<any>(null);
    const dispatch = useAppDispatch();

    useEffect(() => {
        (async () => {
            if (collection != null) {
                const uri = await dispatch(getURIByIDSlice(itemID, collection.contractAddress));
                if (uri != null) {
                    setUri(uri);
                }
            }
        })()
    }, [collection]);

    useEffect(() => {
        (async () => {
            if (uri != null) {
                if (uri.includes(config.network.ipfs.default_url)) {
                    const uriFromIpfs = await getFromIpfs(uri);
                    setMetadata(uriFromIpfs);
                } else {
                    setMetadata(await getJsonFromResource(uri));
                }
            }
        })()
    }, [uri]);

    useEffect(() => {
        if (metadata !== null) {
            if (metadata.image.includes(config.network.ipfs.default_url)) {
                setMetadata({
                    ...metadata,
                    image: metadata.image.replace(
                        config.network.ipfs.default_url,
                        config.network.ipfs.gateway_url
                    ),
                });
            }
            if (!metadata.image.includes(config.network.ipfs.default_url)) {
                let tempImage = new Image();
                tempImage.src = metadata.image;
            }
        }
    }, [metadata]);

    return (
        <>
            {
                metadata == null ? (
                    <Skeleton variant="rect" width={'100%'} height={'125px'} />
                ) : (
                    <div style={{ position: 'relative' }}>
                        <CardMedia
                            className={classes.media}
                            title={`Item image`}
                            image={metadata.image}
                            style={{ opacity: paused ? '0.3' : '1' }}
                        />
                        {
                            paused && (
                                <div className={classes.overlay}>
                                    <Typography variant="body1" className={classes.text}>
                                        Non disponibile
                                    </Typography>
                                </div>
                            )
                        }
                    </div>
                )
            }
        </>
    );
};

import { Box, Grid, Typography, makeStyles, useMediaQuery } from "@material-ui/core"
import { OrangeLine } from "./OrangeLine"
import { InputField } from "../../../../components/Input/InputField";
import { FormikProps } from "formik";
import { CouponInfoInForm } from "./CreateCouponForm";
import { BlackButton } from "../../../../components/Buttons/BlackButton";
import { TransparentButton } from "../../../../components/Buttons/TransparentButton";
import { useContext, useEffect, useState } from "react";
import { CouponContext } from "../../../../providers/CouponProvider";

const useStyle = makeStyles({
    textField: {
        '@media (max-width:600px)': {
            maxWidth: '230px'
        },
        '@media (min-width:600px)': {
            maxWidth: '400px'
        },
        '@media (min-width:600px) and (max-width: 860px)': {
            maxWidth: '300px'
        }
    },
    input: {
        position: 'absolute',
        top: '0px',
        left: '0px',
        zIndex: 1,
        height: '69px',
        width: '69px',
        cursor: 'pointer',
        margin: 0,
        background: 'transparent',
        border: 'none',
        fontSize: '0px',
        outline: 0,
        opacity: 0
    },
    '@media (max-width:600px)': {
        textField: {
            maxWidth: '90%'
        },
    }
})

export const FirstSectionInfoForm = ({ formik }: { formik: FormikProps<CouponInfoInForm> }) => {
    const isMobile = useMediaQuery('(max-width:600px)');

    return (
        <>
            <Grid container item xs={12} sm={3} justifyContent="center" direction="column">
                <Box textAlign={isMobile ? 'center' : 'right'}>
                    <Typography variant='h6'>Come sarà il nuovo Coupon?</Typography>
                </Box>
            </Grid>
            <Grid item xs={12} sm={1}>
                <OrangeLine />
            </Grid>
            <Grid item xs={12} sm={8} style={{ marginTop: '16px', margin: isMobile ? '16px' : '' }} >
                <NameInput formik={formik} />
                <SymbolInput formik={formik} />
                <CouponIcon formik={formik} />
                <InitialSupply formik={formik} />
                <DescriptionCoupon formik={formik} />
            </Grid>
            <Grid container style={{ marginTop: '2em' }}>
                <ConfirmationButton formik={formik} />
            </Grid>
        </>
    )
}

const NameInput = ({ formik }: { formik: FormikProps<CouponInfoInForm> }) => {
    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue('name', event.target.value);
    }

    return (
        <Grid container>
            <Grid item xs={12} sm={3} style={{ marginTop: '7px' }}>
                <Typography variant='body1'>Nome del Coupon*</Typography>
            </Grid>
            <Grid item xs={12} sm={10} style={{ textAlign: 'center', maxWidth: '300px' }} className={useStyle().textField}>
                <InputField value={formik.values.name} placeholder='Scrivi il nome...' variant='outlined' onChange={onNameChange} />
            </Grid>
        </Grid>
    )
}

const SymbolInput = ({ formik }: { formik: FormikProps<CouponInfoInForm> }) => {
    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue('symbol', event.target.value);
    }

    return (
        <Grid container style={{ marginTop: '20px' }}>
            <Grid item xs={12} sm={3} style={{ marginTop: '7px' }}>
                <Typography variant='body1'>Crea una sigla*</Typography>
            </Grid>
            <Grid item xs={12} sm={10} style={{ textAlign: 'center', maxWidth: '300px' }} className={useStyle().textField}>
                <InputField value={formik.values.symbol} placeholder='Scrivi il simbolo...' variant='outlined' onChange={onNameChange} />
                <Grid item xs={12} sm={10} style={{ textAlign: 'left' }}>
                    <Typography variant='caption'>Massimo 5 caratteri</Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}

const CouponIcon = ({ formik }: { formik: FormikProps<CouponInfoInForm> }) => {

    useEffect(() => {
        console.log(`formik.values.icon`, formik.values.icon);
    }, [formik.values.icon])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target != null && event.target.files != null) {
            const file = event.target.files[0];
            formik.setFieldValue('icon', file);
        }
    }

    return (
        <Grid container style={{ marginTop: '20px' }} >
            <Grid item xs={5} sm={3} style={{ marginTop: '7px' }} >
                <Grid container>
                    <Typography style={{ marginTop: '16px' }} variant='body1'>Inserisci icona*</Typography>
                </Grid>
            </Grid>
            <Grid item xs={7} sm={9} style={{ position: 'relative' }}>
                <input
                    id="contained-button-file"
                    name="image"
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleChange(e)}
                    className={useStyle().input}
                >
                </input>
                {
                    formik.values.icon ?
                        <img src={URL.createObjectURL(formik.values.icon)} alt="placeholder" style={{
                            borderRadius: '30px',
                            width: '69px',
                            height: '69px'
                        }} /> :
                        (
                            <img src="/svgs/coupons/input_upload_image_coupon.svg" alt="placeholder" />
                        )
                }
            </Grid>
        </Grid>
    );
}

const InitialSupply = ({ formik }: { formik: FormikProps<CouponInfoInForm> }) => {
    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue('initialQuantity', event.target.value);
    }

    return (
        <Grid container style={{ marginTop: '20px' }}>
            <Grid item xs={5} sm={3} style={{ marginTop: '7px' }}>
                <Typography variant='body1'>Quantità iniziale*</Typography>
            </Grid>
            <Grid item xs={7} sm={10} style={{ textAlign: 'center', maxWidth: '100px' }} className={useStyle().textField}>
                <InputField
                    type={'number'}
                    value={formik.values.initialQuantity.toString()}
                    placeholder='_ _ _'
                    variant='outlined'
                    onChange={onNameChange}
                    style={{ textAlign: 'center' }}
                    min={1}
                    max={999}
                />
            </Grid>
        </Grid>
    )
}

const DescriptionCoupon = ({ formik }: { formik: FormikProps<CouponInfoInForm> }) => {
    return (
        <Grid container>
            <Grid item xs={5} sm={3}>
                <Typography style={{ marginTop: '20px' }} variant='body1'>Breve descrizione*</Typography>
            </Grid>
            <Grid item xs={6} sm={9} className={useStyle().textField} style={{ marginTop: '15px' }}>
                <InputField
                    value={formik.values.description}
                    placeholder='Scrivi descrizione...'
                    variant='outlined'
                    onChange={(event) => formik.setFieldValue('description', event.target.value)}
                />
            </Grid>
        </Grid>
    )
}

const ConfirmationButton = ({ formik }: { formik: FormikProps<CouponInfoInForm> }) => {
    const [disabled, setDisabled] = useState(true);
    const { loading } = useContext(CouponContext);

    const handleCancel = () => {
        formik.resetForm();
    }

    useEffect(() => {
        setDisabled(isDisabled());
    }, [formik.values.name, formik.values.symbol, formik.values.icon, formik.values.initialQuantity])

    const isDisabled = () => {
        return formik.values.name === '' || formik.values.symbol === '' || formik.values.icon == null || formik.values.initialQuantity === 0;
    }

    return (
        <>
            <Grid item xs={3} sm={5}></Grid>
            <Grid item xs={6} sm={2} style={{ textAlign: 'center' }}>
                <BlackButton disabled={loading || disabled} text='Crea Coupon' onClick={() => { formik.handleSubmit() }} />
            </Grid>
            <Grid item xs={3} sm={5}></Grid>
            <Grid item xs={3} sm={5}></Grid>
            <Grid item xs={6} sm={2} style={{ textAlign: 'center' }}>
                <TransparentButton disabled={loading || disabled} style={{ background: 'none', border: 'none', color: disabled ? 'grey' : 'black' }} text='Annulla' onClick={handleCancel} />
            </Grid>
            <Grid item xs={3} sm={5}></Grid>
        </>
    )
}
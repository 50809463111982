import { Box, Button, Grid, Typography } from "@material-ui/core";
import { ContactList, SearchBar } from "../../Coupon/CouponWallet";
import { useContext, useEffect, useState } from "react";
import { getUsersList } from "../../../../api/userAPI";
import { ContactsContext } from "../../../../providers/ContactsProvider";
import { BlackButton } from "../../../../components/Buttons/BlackButton";
import { InputField } from "../../../../components/Input/InputField";
import toast from "react-hot-toast";
import { getTokenFromSymbolSlice } from "../../../../store/slices/coinSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { getTokenWithBalanceFromMetadata } from "../../../../api/coinAPI";
import { coinSend } from "../../../../store/slices/coinSlice";
import { Skeleton } from "@material-ui/lab";

export const CosoInWalletMobile = () => {
    const [contacts, setContacts] = useState<any[]>([]);
    const [recentContacts, setRecentContacts] = useState<any[]>([]);
    const [recentContactsFiltered, setRecentContactsFiltered] = useState<any[]>([]);
    const [receiver, setReceiver] = useState<any>();
    const [amount, setAmount] = useState<number>(0);
    const user = useAppSelector((state) => state.user.currentProfile);
    const dispatch = useAppDispatch();
    const [erc20Info, setErc20Info] = useState<any>();
    const [coinBalance, setCoinBalance] = useState<number>(0);

    const { getListOfRecentContacts } = useContext(ContactsContext);

    const onContactSearch = (
        search: React.ChangeEvent<HTMLInputElement>
    ) => {
        const filtered = recentContacts.filter((contact) => {
            return contact.first_name.toLowerCase().includes(search.target.value.toLowerCase());
        })
        if (filtered.length === 0) {
            searchAmongOtherUsers(search.target.value);
        } else {
            setRecentContactsFiltered(filtered);
        }
    }

    const searchAmongOtherUsers = (search: string) => {
        const filtered = contacts.filter((contact) => {
            return contact.first_name.toLowerCase().includes(search.toLowerCase());
        })
        setRecentContactsFiltered(filtered);
    }

    const onContactClick = (contact: any) => {
        setReceiver(contact);
        setRecentContactsFiltered([contact]);
    }

    const onAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAmount(parseInt(e.target.value));
    }

    useEffect(() => {
        getListOfRecentContacts(10)
            .then((users) => {
                setRecentContactsFiltered(users);
                setRecentContacts(users);
            })
            .catch((error) => {
                console.log('Error in getUsersList, ', error);
            })
        getUsersList()
            .then((users) => {
                setContacts(users);
            })
            .catch((error) => {
                console.log('Error in getUsersList, ', error);
            })
    }, []);

    useEffect(() => {
        (async () => {
            const tokenAddr = await dispatch(getTokenFromSymbolSlice('COSO'));
            if (tokenAddr != null && user?.additional_properties?.commonshoodWallet != null) {
                const balance = await getTokenWithBalanceFromMetadata(user?.additional_properties?.commonshoodWallet, (tokenAddr as any).contractAddress, 'COSO');
                setErc20Info(balance[0]);
                if (balance[0].balance)
                    setCoinBalance((balance[0] as any).balance);
            }
        })()
    }, [])

    const sendCoins = () => {
        const toastId = toast.loading('Invio in corso...');
        try {
            if (!receiver) {
                toast.error('Seleziona un destinatario');
                return;
            }
            const recipientFiltered = contacts?.find(contact => contact.email === receiver.email);
            if (!recipientFiltered) {
                toast.error('Utente non trovato');
                return;
            }
            if (amount <= 0) {
                toast.error('Inserisci una quantità positiva');
                return;
            }
            if (amount >= coinBalance) {
                toast.error('Non hai abbastanza COSO');
                return;
            }
            toast.promise(
                dispatch(coinSend({
                    recipient: recipientFiltered,
                    amount: amount.toString(),
                    symbol: 'COSO',
                    decimals: 2
                })),
                {
                    loading: 'Invio in corso...',
                    success: 'Invio completato!',
                    error: 'Invio fallito'
                }
            );
        } catch (e: any) {
            console.log(e.message);
            toast.error(e.message);
        } finally {
            toast.dismiss(toastId);
        }
    }

    return (
        <Grid container style={{ marginTop: '15px', padding: '20px' }} spacing={2}>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
                <Typography variant="h6">Scegli il destinatario</Typography>
            </Grid>
            <Grid item xs={12}>
                <SearchBar text="Ricerca contatti..." onChange={onContactSearch} />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6">Contatti recenti</Typography>
            </Grid>
            {
                recentContactsFiltered.length > 0 ? (
                    <Grid item xs={12} style={{ marginBottom: '20px' }}>
                        <ContactList contacts={recentContactsFiltered} onClick={onContactClick} />
                    </Grid>
                ) : (
                    <Skeleton
                        variant='rect'
                        width={'100%'}
                        height={90}
                    />
                )
            }
            <Grid item xs={6} style={{ textAlign: 'center' }} >
                <Typography variant="subtitle1">Quantità</Typography>
            </Grid>
            <Grid item xs={4} style={{ marginBottom: '20px' }}>
                <InputField
                    type={'number'}
                    value={amount.toString()}
                    placeholder='_ _ _'
                    variant='outlined'
                    onChange={onAmountChange}
                    style={{ textAlign: 'center' }}
                    min={1}
                    max={999}
                />
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
                <BlackButton
                    style={{
                        width: '200px',
                        color: 'white'
                    }}
                    onClick={sendCoins}
                    text="Invia"
                />
            </Grid>
        </Grid>
    );
}

import { FormikProps } from "formik";
import { CouponInfoInForm } from "./CreateCouponForm";
import { Box, Dialog, DialogActions, DialogContent, Grid, Typography, makeStyles } from "@material-ui/core";
import { TransparentButton } from "../../../../components/Buttons/TransparentButton";
import { BlackButton } from "../../../../components/Buttons/BlackButton";
import { useHistory } from "react-router-dom";

const useStyle = makeStyles({
    dialog: {
        textAlign: 'center',
        '@media (max-width: 600px)': {
            width: '100%',
        },
        '@media (min-width: 600px)': {
            width: '100%',
        },
    },
    buttonGroup: {
        display: 'flex',
        justifyContent: 'center',
        gap: '10px',
    },
})

export const CouponCreationRecap = ({ formik, open, closeDialog }: { formik: FormikProps<CouponInfoInForm>, open: boolean, closeDialog: () => void }) => {
    const classes = useStyle();
    const history = useHistory();

    const createAnotherHandle = () => {
        formik.setFieldValue('icon', null);
        formik.setFieldValue('initialQuantity', 0);
        formik.setFieldValue('description', '');
        formik.setFieldValue('name', '');
        formik.setFieldValue('symbol', '');
        closeDialog();
    }

    return (
        <Dialog open={open} className={classes.dialog} onClose={closeDialog}>
            <Box textAlign={'center'}>
                <img width={100} height={100} src="/svgs/coupons/coupon_dialog_recap.svg"></img>
            </Box>
            <DialogContent style={{ textAlign: 'center' }}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h6" style={{ fontWeight: 'bold' }}>Coupon {formik.values.name} - {formik.values.symbol}</Typography>
                    </Grid>
                    <Grid item xs={3} />
                    <Grid item xs={6}>
                        <Typography variant='caption' style={{ fontWeight: 'bold' }}>
                            è in fase di creazione e sarà disponibile in pochi secondi!
                        </Typography>
                    </Grid>
                    <Grid item xs={3} />
                    <Grid item xs={12}>
                        <DialogActions className={classes.buttonGroup}>
                            <TransparentButton style={{
                                width: '200px',
                            }} text={'Vai ai miei Coupon'} onClick={() => {
                                history.push('/coupon/home');
                            }} />
                            <BlackButton style={{
                                width: '200px',
                                color: 'white'
                            }} text={'Crea un altro Coupon'} onClick={createAnotherHandle} />
                        </DialogActions>
                    </Grid>

                </Grid>
            </DialogContent>
        </Dialog>
    );
}
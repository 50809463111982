import { makeStyles } from "@material-ui/core";
import config from "../../../../config";


const styles = makeStyles({
    image: {
        width: '45px',
        height: '45px',
        borderRadius: '50%'
    }
})

export const CouponLogo = ({ logo, selected }: { logo: string, selected: boolean }) => {
    return (
        <img src={logo.replace(config.network.ipfs.default_url, config.network.ipfs.gateway_url)} className={styles().image} alt="placeholder" style={selected ? {border: '2px solid black'} : {border: 'unset'}} />
    )
}

import { createContext, useEffect, useState } from "react";
import config from "../config";

export const RealmContext = createContext<{
  isCurrentRealmChGarden: boolean,
  isCurrentRealmCOSO: boolean,
  currentRealm: string
}>({
  isCurrentRealmChGarden: false,
  isCurrentRealmCOSO: false,
  currentRealm: ''
});
export const RealmProvider = (props: any) => {
  const children = props.children;
  const [currentRealm, setCurrentRealm] = useState<string>('');
  const [isCurrentRealmChGarden, setIsCurrentRealmChGarden] = useState(false);
  const [isCurrentRealmCOSO, setIsCurrentRealmCOSO] = useState(false);

  useEffect(() => {
    if (config && config.realm && config.realm.REALM) {
      setCurrentRealm(config.realm.REALM)
      setIsCurrentRealmCOSO(config.realm.REALM === 'beta' || config.realm.REALM === 'prod');
      setIsCurrentRealmChGarden(config.realm.REALM === 'chgarden');      
      console.log(`Current realm: ${config.realm.REALM}\nIs realm COSO: ${config.realm.REALM === 'beta' || config.realm.REALM === 'prod'}\nIs realm GARDEN: ${config.realm.REALM === 'chgarden'}`);
    } else
      console.error('REALM NOT FOUND IN CONFIGURATION FILE')
  }, [])

  return <RealmContext.Provider value={{
    isCurrentRealmChGarden,
    isCurrentRealmCOSO,
    currentRealm
  }}> {children} </RealmContext.Provider>;
};

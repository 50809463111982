import { FormikProps } from "formik"
import { ExchangeInfoInForm } from "./ExchangeFormContainer"
import { Box, Button, Grid, Typography, makeStyles, useMediaQuery } from "@material-ui/core"
import { OrangeLine } from "../../Wallet/Coupon/CreationForm/OrangeLine"
import { InputField } from "../../../components/Input/InputField"
import { BlackButton } from "../../../components/Buttons/BlackButton"
import { TransparentButton } from "../../../components/Buttons/TransparentButton"
import { useContext } from "react"
import { ExchangeFormContext } from "../../../providers/ExchangeFormCreationProvider"
import { useHistory } from "react-router-dom"

const useStyle = makeStyles({
    textField: {
        '@media (max-width:600px)': {
            maxWidth: '230px'
        },
        '@media (min-width:600px)': {
            maxWidth: '400px'
        },
        '@media (min-width:600px) and (max-width: 860px)': {
            maxWidth: '300px'
        }
    },
    container: {
        marginTop: '3em',
        '@media (max-width:600px)': {
            marginTop: '2em'
        }

    },
    descriptionContainer: {

        '@media (min-width:600px)': {
            marginTop: '4em',
        }
    }
})

export const FirstStep = ({ formik }: {
    formik: FormikProps<ExchangeInfoInForm>
}) => {
    const isMobile = useMediaQuery('(max-width:600px)');

    return (
        <>
            
            <Grid container className={useStyle().container}>
                <Description />
                <Grid item xs={12} sm={8}
                    style={{ marginTop: '4em', margin: isMobile ? '16px' : '0px' }}
                >
                    <Box borderRadius={30} width={200} height={75} style={{
                        border: '1px solid #E0E0E0',
                        marginTop: '4em'
                    }}>
                        <Grid container>
                            <Grid item xs={4} style={{ padding: '16px' }}>
                                <img src="/svgs/coso_logo_rounded.svg" width={40} height={40}></img>
                            </Grid>
                            <Grid item xs={8} justifyContent="center" direction="column" style={{
                                marginTop: '22px'
                            }}>
                                <Typography variant='h6'>
                                    COSO
                                </Typography>
                            </Grid>
                            
                        </Grid>

                    </Box>
                <Grid item xs={12}>
                    <InitialSupply formik={formik} />
                </Grid>

                </Grid>
                <ContinueButton formik={formik} />
            </Grid>
        </>
    )
}

const Description = () => {
    const isMobile = useMediaQuery('(max-width:600px)');

    return (
        <>
            <Grid item xs={12} sm={3} justifyContent="center" direction="column" className={useStyle().descriptionContainer}>
                <Box textAlign={isMobile ? 'center' : 'right'}>
                    <Typography variant='h6'>
                        Quanti COSO <br /> richiedi?
                    </Typography>
                    {
                        isMobile && (
                            <Grid item xs={12} sm={1}>
                                <OrangeLine />
                            </Grid>
                        )
                    }
                    <Typography variant={'caption'} style={{ top: '2em', position: 'relative' }}>
                        Il tuo indirizzo email verrà mostrato a tutti gli utenti che vedranno la tua offerta nella sezione Exchange
                    </Typography>
                </Box>
            </Grid>
            {
                !isMobile && (
                    <Grid item xs={12} sm={1}>
                        <OrangeLine />
                    </Grid>
                )
            }
        </>
    )
}

const InitialSupply = ({ formik }: { formik: FormikProps<ExchangeInfoInForm> }) => {
    const onQntChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue('tokensRequestedAmount', event.target.value);
    }

    return (
        <Grid container style={{ marginTop: '3em' }}>
            <Grid item xs={5} sm={6} style={{ marginTop: '7px' }}>
                <Typography variant='body1'>Quantità</Typography>
            </Grid>
            <Grid item xs={7} sm={6} style={{ textAlign: 'center', maxWidth: '100px' }} className={useStyle().textField}>
                <InputField
                    type={'number'}
                    value={formik.values.tokensRequestedAmount.toString()}
                    placeholder='_ _ _'
                    variant='outlined'
                    onChange={onQntChange}
                    style={{ textAlign: 'center' }}
                    min={1}
                    max={999}
                />
            </Grid>
        </Grid>
    )
}

const ContinueButton = ({ formik }: { formik: FormikProps<ExchangeInfoInForm> }) => {
    const history = useHistory();
    const { setStep } = useContext(ExchangeFormContext);

    return (
        <Grid container item style={{ marginTop: '1em' }} spacing={2}>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
                <BlackButton
                    width={227}
                    onClick={() => { setStep(1) }}
                    text={'Avanti'}
                    disabled={formik.values.tokensRequestedAmount === 0}
                />
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
                <TransparentButton
                    width={227}
                    style={{
                        color: 'rgba(218, 93, 62, 1)',
                        border: 'none'
                    }}
                    onClick={() => history.push('/user/exchanges')}
                    text={'Annulla'}
                />
            </Grid>
        </Grid>
    )
}
import { createContext, useState } from "react";
import { Item } from "../types/libofthings.type";
import config from "../config";
import * as axiosSOT from "../utilities/backend/axios-metadata";

export const LibraryOfThingsContractContext = createContext<{
    getNewArrivals: (offset: number, limit: number) => Promise<Item[]>;
    getUserItems: (address: string, offset: number) => Promise<{ items: Item[], numberOfItems: number }>;
    getItems: (category: string | undefined, offset: number, limit: number) => Promise<{ items: Item[] }>;
    numberOfItemsPerPage: number,
    loading: boolean,
    setLoading: (loading: boolean) => void,
    getItemsByName: (name: string, offset: number) => Promise<Item[]>;
}>({
    getNewArrivals: async (offset: number, limit: number) => { return []; },
    getUserItems: async (address: string, offset: number) => { return { numberOfItems: 0, items: [] }; },
    getItems: async (category: string | undefined, offset: number, limit: number) => { return { items: [] }; },
    numberOfItemsPerPage: 12,
    loading: false,
    setLoading: (loading: boolean) => { },
    getItemsByName: async (name: string, offset: number) => { return []; },
});
export const LibraryOfThingsContractProvider = (props: any) => {
    const children = props.children;
    const numberOfItemsPerPage = 12;
    const [loading, setLoading] = useState<boolean>(false);

    const getNewArrivals = async (offset: number, limit: number) => {
        const items: Item[] = [];
        const q = `${config.network.metadata.url}lot/items/newArrivals?offset=${offset * numberOfItemsPerPage}&limit=${limit}`;

        const res = await axiosSOT.default.get(q);
        if (res.data != null && res.data.items != null) {
            res.data.items.map((item: any) => {
                items.push({
                    itemID: item.itemID,
                    idInCollection: item.idInCollection,
                    price: item.price,
                    caution: item.caution,
                    owner: item.owner,
                    name: item.name,
                    collectionSymbol: item.collectionSymbol,
                    paymentToken: item.paymentToken,
                    option: item.option,
                    dateOfUpload: item.dateOfUpload,
                    category: item.category,
                    hidden: item.hidden,
                    requireSupervision: item.requireSupervision,
                    numberOfTimesRequested: item.numberOfTimesRequested
                })
            })
        }
        return items;
    }

    const getUserItems = async (address: string, offset: number): Promise<{ items: Item[], numberOfItems: number }> => {
        const limit = 12;
        const items: Item[] = [];
        let numberOfItems = 0;
        const q = `${config.network.metadata.url}lot/${address}/myItems?offset=${offset * numberOfItemsPerPage}&limit=${limit}`;
        const res = await axiosSOT.default.get(q);
        if (res.data != null && res.data.items != null) {
            numberOfItems = res.data.numberOfItemsOwned;
            res.data.items.map((item: any) => {
                items.push({
                    itemID: item.itemID,
                    idInCollection: item.idInCollection,
                    price: item.price,
                    caution: item.caution,
                    owner: item.owner,
                    name: item.name,
                    collectionSymbol: item.collectionSymbol,
                    paymentToken: item.paymentToken,
                    option: item.option,
                    dateOfUpload: item.dateOfUpload,
                    category: item.category,
                    hidden: item.hidden,
                    requireSupervision: item.requireSupervision,
                    numberOfTimesRequested: item.numberOfTimesRequested
                })
            })
        }
        return {
            items: items,
            numberOfItems: numberOfItems,
        };
    }

    const getItems = async (category: string | undefined, offset: number, limit: number): Promise<{ items: Item[] }> => {
        let items: Item[] = [];
        try {
            if (category != null) {
                const q = `${config.network.metadata.url}lot/items?category=${category}&offset=${offset * numberOfItemsPerPage}&limit=${limit}`;
                const res = await axiosSOT.default.get(q);
                if (res.data != null) {
                    items = res.data.items;
                }
            } else {
                const q = `${config.network.metadata.url}items/all?offset=${0}&limit=${24}`;
                const res = await axiosSOT.default.get(q);
                console.log(res);
                if(res && res.data != null && res.status === 200)
                    items = res.data.items;
                else    
                    throw new Error('Something went wrong while fetching items')
            }

        } catch (error) {
            console.log(error);
        }
        return { items };
    }

    const getItemsByName = async (name: string, offset: number): Promise<Item[]> => {
        let items: Item[] = [];
        const limit = 12;

        try {
            const q = `${config.network.metadata.url}lot/items?name=${name}&offset=${offset * numberOfItemsPerPage}&limit=${limit}`;
            const res = await axiosSOT.default.get(q);
            if (res.data != null)
                items = res.data.items;
        } catch (e: any) {
            console.log(e.message);
        }
        return items;
    }

    const viewContextData = {
        getNewArrivals,
        getUserItems,
        getItems,
        getItemsByName,
        numberOfItemsPerPage,
        loading,
        setLoading
    };

    return <LibraryOfThingsContractContext.Provider value={viewContextData}> {children} </LibraryOfThingsContractContext.Provider>;
};

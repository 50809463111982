import { Grid } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { useAppSelector } from "../../../../store/hooks"
import { Item } from "../../../../types/libofthings.type"
import { ItemCard } from "../../Cards/ItemCard/ItemCard"
import { useContext, useEffect, useState } from "react"
import { LibraryOfThingsContractContext } from "../../../../providers/LibraryOfThingsContractProvider"
import { PaginationContainer } from "../../../../components/UI/Pagination/PaginationContainer"
import { NoItemsDisplay } from "../../BasicComponents/NoItemsDisplay"

export const ListOfItems = ({ shouldRefresh }: {
    collections: any[],
    shouldRefresh: boolean
}) => {
    const [items, setItems] = useState<Item[]>([]);
    const [numberOfItems, setNumberOfItems] = useState<number>(0);
    const account = useAppSelector(state => state.user.currentProfile);
    const loading = useAppSelector(state => state.libOfThings.loading);
    const { getUserItems, numberOfItemsPerPage } = useContext(LibraryOfThingsContractContext);

    useEffect(() => {
        (async () => {
            await getItems();
        })()
    }, [])

    useEffect(() => {
        if (shouldRefresh) {
            (async () => {
                await getItems();
            })()
        }
    }, [shouldRefresh])

    const getItems = async () => {
        const address = account?.additional_properties?.commonshoodWallet;
        if (address != null) {
            const items = await getUserItems(address, 0);
            setItems(items.items);
            setNumberOfItems(items.numberOfItems);
        }
    }

    const handleClickOnPagination = async (selectedPage: number) => {
        const address = account?.additional_properties?.commonshoodWallet;
        if (address != null) {
            const items = await getUserItems(address, selectedPage);
            setItems(items.items);
        }
    }

    const deletionCallback = async () => {
        setItems([]);
        await getItems();
    }

    return (
        <>
            <Grid container style={{ position: 'relative', top: '1em', margin: '3px' }} spacing={2}>
                {
                    items.length === 0 ? (
                        <NoItemsDisplay style={{ marginTop: '3em' }} />
                    ) : (
                        <>
                            {
                                (!loading) ? (
                                    items.map((i) => {
                                        return <Grid style={{ marginBottom: '50px' }} key={i.itemID} item xs={6} md={2}>
                                            <ItemCard
                                                callback={deletionCallback}
                                                item={i}
                                            />
                                        </Grid>
                                    })
                                ) : (
                                    <>
                                        <Skeleton
                                            style={{
                                                position: 'relative',
                                                top: '1em',
                                            }}
                                            variant="rect"
                                            width={'100%'}
                                            height={'400px'}
                                        />
                                    </>
                                )
                            }
                        </>
                    )
                }

                <Grid item xs={12} style={{ marginBottom: '15em' }}>
                    <PaginationContainer pageCount={Math.ceil(numberOfItems / numberOfItemsPerPage)} onChange={handleClickOnPagination} />
                </Grid>
            </Grid>
        </>

    )
}
import { Button, Grid, Typography, makeStyles, useMediaQuery } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { ListOfItems } from "./MyItems/ListOfItems";
import { MyInventoryTabs } from "./MyInventoryTabs";
import { UploadItemTitle } from "../UploadItem/UploadItemTitle";
import { useHistory } from "react-router-dom";
import { ListOfActivePreorders } from "./Lendings/Active/ListOfActivePreorders";
import { ListOfPreordersPending } from "./Lendings/Pending/ListOfPreordersPending";
import { ListOfPreordersCompleted } from "./Lendings/Completed/ListOfPreordersCompleted";
import { useTranslation } from "react-i18next";
import { ListOfPreordersCancelled } from "../MyRequests/Cancelled/ListOfCancelledPreorders";
import { CalendarContractContext } from "../../../providers/CalendarContractProvider";
import { TutorialContext } from "../../../providers/TutorialProvider";
import { TutorialFab } from "../Tutorial/TutorialFab";
import theme from "../../../theme/theme";
import { AccountActionWithDashedBorder } from "../MyAccount/AccountActions";
import { isAddressPaused, togglePauseStatus } from "../../../store/slices/calendarSlice";
import { CustomToaster } from "../../Notifications/CustomToaster";
import toast from "react-hot-toast";
import { ENDING_PREORDER_WARNING_HOURS } from "../../../utilities/costants.calendar";

const mainContainerStyle = makeStyles({
    root: {
        position: 'relative',
        '@media (min-width: 837px)': {
            left: '0.2em',
        }
    },
    button: {
        border: '1px solid rgba(0, 0, 0, 1)',
        borderRadius: '15px',
        width: '100%',
        textTransform: 'none',
        cursor: 'pointer',
        maxHeight: '38.5px'
    }
})

export const MyInventory = () => {
    const { openTutorialDesktop, openTutorialMobile } = useContext(TutorialContext);
    const useMainContainerStyle = mainContainerStyle();
    const currentprofile = useAppSelector((state: any) => state.user.currentProfile)
    const collections = useAppSelector((state: any) => state.nft.collectionList);
    const isUserPaused = useAppSelector((state: any) => state.calendar.isUserPaused);
    const [isLoggedUserPaused, setIsLoggedUserPaused] = useState(false);
    const [activeMenu, setActiveMenu] = useState(0);
    const [refreshingItems, setRefreshingItems] = useState(false);
    const [refreshingPending, setRefreshingPending] = useState(false);
    const [refreshActive, setRefreshActive] = useState(false);
    const [refreshCompleted, setRefreshCompleted] = useState(false);
    const [refreshingDenied, setRefreshingDenied] = useState(false);
    const { getUserStats } = useContext(CalendarContractContext);
    const { t } = useTranslation('LibOfThings');
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
    const dispatch = useAppDispatch();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [showWarning, setShowWarning] = useState(false);
    const account = useAppSelector(state => state.user.currentProfile);
    const { getPreordersInLastXHours } = useContext(CalendarContractContext);

    useEffect(() => {
        (async () => {
            if (currentprofile?.additional_properties?.commonshoodWallet) {
                const isPaused = await dispatch(isAddressPaused(
                    currentprofile?.additional_properties?.commonshoodWallet
                ));
                setIsLoggedUserPaused(isPaused);
            }
        })()
    }, [])

    useEffect(() => {
        checkIfShouldShowWarning();
    }, [])

    useEffect(() => {
        setIsLoggedUserPaused(isUserPaused);
    }, [isUserPaused]);

    const refresh = () => {
        switch (activeMenu) {
            case 0:
                setRefreshingItems(true);
                break;
            case 1:
                setRefreshingPending(true);
                break;
            case 2:
                setRefreshActive(true);
                break;
            case 3:
                setRefreshCompleted(true);
                break;
            case 4:
                setRefreshingDenied(true);
                break;
        }
    }

    const handleMenuChange = (menuToActivate: number) => {
        setActiveMenu(menuToActivate);
    }

    useEffect(() => {
        getUserStats();
        checkIfShouldShowWarning();
        if (refreshingItems || refreshingPending || refreshActive || refreshCompleted || refreshingDenied) {
            setRefreshingItems(false);
            setRefreshingPending(false);
            setRefreshActive(false);
            setRefreshCompleted(false);
            setRefreshingDenied(false);
        }
    }, [refreshingItems, refreshingPending, refreshActive, refreshCompleted, refreshingDenied])

    const tutorialFabCallback = () => {
        if (isMobile)
            openTutorialMobile();
        else
            openTutorialDesktop();
    }

    const togglePauseStatusHandler = async () => {
        setLoading(true);
        const toastId = toast.loading('');
        await dispatch(togglePauseStatus());
        toast.dismiss(toastId);
        setLoading(false);
    }

    const checkIfShouldShowWarning = async () => {
        const myAddr = account?.additional_properties?.commonshoodWallet
        if (myAddr) {
            const almostFinishedPreorders = await getPreordersInLastXHours(
                myAddr,
                true,
                ENDING_PREORDER_WARNING_HOURS
            )
            setShowWarning(almostFinishedPreorders.length > 0)
        }
    }


    return (
        <>
            <CustomToaster />
            <TutorialFab callback={tutorialFabCallback} />
            <Grid container className={useMainContainerStyle.root} spacing={1}>
                {
                    !isMobile &&
                    <Grid item sm={3} onClick={() => { history.push('/items/upload') }} style={{ marginBottom: '30px' }} >
                        <AccountActionWithDashedBorder
                            link={'/items/'}
                            title={'Aggiungi un nuovo oggetto'}
                        />
                    </Grid>
                }
                {
                    !isMobile &&
                    <Grid item sm={3} style={{ marginBottom: '30px' }} >
                        <Button style={{
                            color: isLoggedUserPaused ? 'rgba(218, 93, 62, 1)' : 'black',
                            borderColor: isLoggedUserPaused ? 'rgba(218, 93, 62, 1)' : 'black'
                        }} disabled={loading} className={useMainContainerStyle.button} onClick={() => togglePauseStatusHandler()} >
                            {
                                isLoggedUserPaused ? (
                                    <img
                                        src="/svgs/umbrella-orange.svg"
                                        alt="icon"
                                        style={{
                                            marginRight: '30px',
                                        }}
                                    />
                                ) : (
                                    <img
                                        src="/svgs/umbrella-black.svg"
                                        alt="icon"
                                        style={{
                                            marginRight: '30px',
                                        }}
                                    />
                                )
                            }

                            <div style={{ textAlign: 'left' }}>
                                <Typography variant="body2" style={{ display: 'block' }}>
                                    Modalità vacanza
                                </Typography>
                                <Typography variant="caption" style={{ display: 'block', marginTop: '-5px' }}>
                                    {isLoggedUserPaused ? 'Attivo' : 'Inattivo'}
                                </Typography>
                            </div>
                        </Button>
                    </Grid>
                }

                <Grid item xs={12}>
                    <Grid item xs={12} style={{
                        cursor: 'pointer'
                    }}>
                        <Grid container>
                            <Grid item xs={7} sm={3} md={2} lg={2} onClick={() => history.push('/')} >
                                <UploadItemTitle text={t('seeOwnedItems')} />
                            </Grid>
                            {
                                isMobile && (
                                    <Grid item xs={1} onClick={() => togglePauseStatusHandler()}>

                                        <>
                                            {
                                                isLoggedUserPaused ? (
                                                    <img
                                                        src="/svgs/umbrella-orange.svg"
                                                        alt="icon"
                                                        style={{
                                                            marginLeft: '-20px',
                                                        }}
                                                    />
                                                ) : (
                                                    <img
                                                        src="/svgs/umbrella-black.svg"
                                                        alt="icon"
                                                        style={{
                                                            marginLeft: '-20px',
                                                        }}
                                                    />
                                                )
                                            }
                                        </>

                                    </Grid>
                                )
                            }
                            <Grid item xs={2} sm={3}>
                                <img style={{
                                    cursor: 'pointer'
                                }} onClick={() => refresh()} src="/svgs/reload.svg" alt="reload" width={24} height={28} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <MyInventoryTabs
                        handleMenuChange={handleMenuChange}
                        showWarning={showWarning}
                    />
                </Grid>
                {
                    (activeMenu === 0) && (
                        <ListOfItems
                            shouldRefresh={refreshingItems}
                            collections={collections}
                        />
                    )
                }
                {
                    (activeMenu === 1) && (
                        <ListOfPreordersPending shouldRefresh={refreshingPending} />
                    )
                }

                {
                    (activeMenu === 2) && (
                        <ListOfActivePreorders shouldRefresh={refreshActive} />
                    )
                }
                {
                    (activeMenu === 3) && (
                        <ListOfPreordersCompleted shouldRefresh={refreshCompleted} />
                    )
                }
                {
                    (activeMenu === 4) && (
                        <ListOfPreordersCancelled shouldRefresh={refreshingDenied} isLender={true} />
                    )
                }
            </Grid>
        </>
    )
}
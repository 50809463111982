import { Typography, makeStyles, Grid, CircularProgress } from "@material-ui/core";
import { style } from "openlayers";
import { ExchangeEntryCard, IExchangeEntryCardInfo } from "../../LibOfThings/Exchange/ExchangeEntryCard";
import ExchangeSummaryDialog, { IExchangeSummaryData } from "../ExchangeSummaryDialog";
import { useEffect, useState } from "react";
import { ExchangeOwnCard } from "../../LibOfThings/Exchange/ExchangeOwnCard";
import { getExchanges } from "../../../api/exchangeAPI";
import { UserNameType, getUserNameByAddress } from "../../../api/userAPI";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { IExchangeTokenEntry } from "../../LibOfThings/Exchange/ExchangeTokenEntry";
import { exchangeCancel } from "../../../store/slices/exchangeSlice";

const useStyle = makeStyles((theme) => ({
    title: {
        color: '#18181D',
        fontFamily: 'Be Vietnam Pro',
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
        [theme.breakpoints.down('md')]: {
            marginLeft: '1.25rem',
        }
    },
    exchangeCard: {
        width: 243,
        height: 282,
        backgroundColor: '#FFF',
        borderRadius: 19,
        position: 'relative',
        padding: '24px 0',
        color: '#18181d',
        fontFamily: 'Be Vietnam Pro',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal'
    },
    exchangeCardBadge: {
        width: '92.377px',
        height: 20,
        borderRadius: 10,
        backgroundColor: '#DA5D3E',
        color: '#FFF',
        textAlign: 'center',
        fontFamily: 'Be Vietnam Pro',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '18px',
        position: 'absolute',
        top: '-3%',
        left: '50%',
        transform: 'translateX(-50%)',
    },
    exchangeCardExpiryText: {
        color: '#181B1D',
        textAlign: 'center',
        fontFamily: 'Be Vietnam Pro',
        fontSize: '10px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '16px',
    },
    exchangeCardEmailText: {
        color: '#DA5d3e',
        textAlign: 'center',
        fontFamily: 'Be Vietnam Pro',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '18px'
    },
    exchangeDefaultText: {
        color: '#18181d',
        fontFamily: 'Be Vietnam Pro',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal'
    },
    cardSpacing: {
        '& > *': {
            marginLeft: '1.25rem',
            marginTop: '1.25rem'
        },
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center',
            marginLeft: '0'
        }
    }
}))


function adaptToObject(input: any, fromUsername?: string): IExchangeEntryCardInfo {
    const offeredTokens: IExchangeTokenEntry[] = input.coinsOfferedData.map((coin: any) => ({
        symbol: coin.symbol,
        amount: coin.amount,
        tokenImageSrc: coin.logoUrl
    }));

    const desiredTokens: IExchangeTokenEntry[] = input.coinsRequiredData.map((coin: any) => ({
        symbol: coin.symbol,
        amount: coin.amount,
        tokenImageSrc: coin.logoUrl
    }));



    const x = {
        fromUserEmail: fromUsername!,
        offeredTokens,
        desiredTokens,
        expiry: new Date(input.expiration),
        maxExchangeAmount: input.repeatings,
        apiObject: input
    }
    return x;
}

export const ExchangeOwnTab: React.FC = () => {
    const style = useStyle();
    const [exchanges, setExchanges] = useState<IExchangeEntryCardInfo[]>([]);
    const [loading, setLoading] = useState(true);
    const user = useAppSelector(state => state.user.currentProfile);
    const dispatch = useAppDispatch();

    useEffect(() => {
        // Fetch exchanges from the API
        const fetchExchanges = async () => {
            try {
                const apiExchanges = await getExchanges(user?.additional_properties?.commonshoodWallet!, 0, true, 0, 20);

                // Convert the retrieved array to match the interface
                const adaptedExchanges = await Promise.all(apiExchanges.map(async (e: any) => {
                    const userName = user!.name;
                    return adaptToObject(e, userName);
                }));
                setExchanges(adaptedExchanges);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching exchanges:', error);
            }
        };

        fetchExchanges();
    }, []); // Run once on component mount

    const exchangeDeletedHandler = async (e: IExchangeSummaryData, indexOf: number) => {
        const exchangeAddress = exchanges[indexOf].apiObject.address;
        await dispatch(exchangeCancel(exchangeAddress));
        exchanges.splice(indexOf, 1);
        setExchanges([...exchanges]);
    }

    if (loading)
        return <WaitingForApi />

    return (
        <>
            <Typography className={style.title} style={{ marginTop: '1.5rem', marginBottom: '34px' }}>Novit&agrave;</Typography>

            <Grid container direction="row" className={style.cardSpacing}>
                {
                    exchanges.map((mockExc, i) => (
                        <ExchangeOwnCard exchangeCardInfo={mockExc} onExchangeDeleted={(e) => exchangeDeletedHandler(e, i)} key={i} />
                    ))
                }
            </Grid>
        </>)
}

const WaitingForApi = () => (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '200px' }}>
        <CircularProgress />
        <Typography variant="body1" style={{ marginTop: '1.25rem' }}>Caricamento</Typography>
    </div>
)
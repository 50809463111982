import { useState } from "react";
import AilinTabs from "../LibOfThings/BasicComponents/AilinTabs";
import { Grid, Typography, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import { CosoSeparator } from "../LibOfThings/BasicComponents/CosoSeparator";
import { AccountActionWithDashedBorder } from "../LibOfThings/MyAccount/AccountActions";
import { BlackButton } from "../../components/Buttons/BlackButton";
import { ExchangeEntryCard, IExchangeEntryCardInfo } from "../LibOfThings/Exchange/ExchangeEntryCard";
import ExchangeSummaryDialog, { IExchangeSummaryData } from "./ExchangeSummaryDialog";
import { ExchangeAllTab } from "./ExchangePageTabs/ExchangeAllTab";
import { ExchangeOwnTab } from "./ExchangePageTabs/ExchangeOwnTab";
import { ExchangeHistoryTab } from "./ExchangePageTabs/ExchangeHistoryTab";
import { useHistory } from "react-router-dom";
import { getAcceptableExchanges, getExchanges } from "../../api/exchangeAPI";
import { useAppSelector } from "../../store/hooks";


const useStyle = makeStyles((theme) => ({
    title: {
        color: '#18181D',
        fontFamily: 'Be Vietnam Pro',
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
        [theme.breakpoints.down('md')]: {
            marginLeft: '1.25rem',
        }
    },
    exchangeCard: {
        width: 243,
        height: 282,
        backgroundColor: '#FFF',
        borderRadius: 19,
        position: 'relative',
        padding: '24px 0',
        color: '#18181d',
        fontFamily: 'Be Vietnam Pro',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal'
    },
    exchangeCardBadge: {
        width: '92.377px',
        height: 20,
        borderRadius: 10,
        backgroundColor: '#DA5D3E',
        color: '#FFF',
        textAlign: 'center',
        fontFamily: 'Be Vietnam Pro',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '18px',
        position: 'absolute',
        top: '-3%',
        left: '50%',
        transform: 'translateX(-50%)',
    },
    exchangeCardExpiryText: {
        color: '#181B1D',
        textAlign: 'center',
        fontFamily: 'Be Vietnam Pro',
        fontSize: '10px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '16px',
    },
    exchangeCardEmailText: {
        color: '#DA5d3e',
        textAlign: 'center',
        fontFamily: 'Be Vietnam Pro',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '18px'
    },
    exchangeDefaultText: {
        color: '#18181d',
        fontFamily: 'Be Vietnam Pro',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal'
    },
    cardSpacing: {
        '& > *': {
            marginLeft: '1.25rem',
            marginTop: '1.25rem'
        },
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center',
            marginLeft: '0'
        }
    }
}))

export const ExchangePage: React.FC = () => {
    const style = useStyle();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
    const history = useHistory();
    const user = useAppSelector(state => state.user.currentProfile);

    const exchangeCreatorEmail = "ortodellasme@gmail.com";


    return (
        <div style={{ height: '100svh' }}>
            <Typography className={style.title} style={{ marginBottom: '24px' }}>Exchange</Typography>
            {
                user?.email === exchangeCreatorEmail &&
                <Grid item container direction={isMobile ? 'column' : 'row'} alignItems="center">
                    <Grid item style={{ marginTop: isMobile ? '12px' : '0' }}>
                        <AccountActionWithDashedBorder onClick={() => {
                            history.push('/exchange/create');
                        }} link={"#"} title={"Crea nuovo Exchange"} />
                    </Grid>
                </Grid>
            }
            {user?.email !== exchangeCreatorEmail &&
                <ExchangeAllTab />}
            {user?.email === exchangeCreatorEmail &&
                <ExchangeOwnTab />}
        </div >
    )
}
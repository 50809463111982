import { FormikProps } from "formik"
import { ExchangeInfoInForm } from "./ExchangeFormContainer"
import { Box, Grid, Typography, makeStyles, useMediaQuery } from "@material-ui/core"
import { OrangeLine } from "../../Wallet/Coupon/CreationForm/OrangeLine"
import { BlackButton } from "../../../components/Buttons/BlackButton"
import { TransparentButton } from "../../../components/Buttons/TransparentButton"
import { useContext } from "react"
import { ExchangeFormContext } from "../../../providers/ExchangeFormCreationProvider"

const useStyle = makeStyles({
    container: {
        marginTop: '3em',
        '@media (max-width:600px)': {
            marginTop: '2em'
        }
    },
    card: {
        width: '236px',
        margin: '0 auto',
        '@media (max-width:600px)': {
            width: '150px'
        }
    },
    amountContainer: {
        background: 'white',
        border: '1.5px solid black',
        borderRadius: '40px',
        width: '139px',
        textAlign: 'center',
        '@media (max-width:600px)': {
            width: '104px'
        }
    }
})

export const SecondStep = ({ formik }: {
    formik: FormikProps<ExchangeInfoInForm>
}) => {
    const isMobile = useMediaQuery('(max-width:600px)');

    return (
        <>
            <Grid container className={useStyle().container}>
                <Description />
                <Grid item xs={6} sm={3} container alignItems="center" justify="center">
                    <COSOLogoRecap />
                </Grid>
                <Grid item xs={2} sm={2} container alignItems="center" justify="center">
                    <Typography variant='h6'>
                        {isMobile ? 'x' : 'Quantità'}
                    </Typography>
                </Grid>
                <Grid item xs={4} container sm={3} alignItems="center" justify="center">
                    <COSOAmount formik={formik} />
                </Grid>
            </Grid>
            <Grid container item>
                <ContinueButtons formik={formik} />
            </Grid>
        </>
    )
}

const COSOLogoRecap = () => {
    return (
        <Box borderRadius={30} height={60} width={236} style={{
            border: '1.5px solid rgba(24, 27, 29, 1)',
            borderRadius: '32.5px',
            position: 'relative',
            alignItems: 'center',  // Vertically center the content
            justifyContent: 'center'  // Horizontally center the content
        }} className={useStyle().card}>
            <Grid container>
                <Grid item xs={4} style={{ padding: '13px' }}>
                    <img src="/svgs/coso_logo_rounded.svg" width={35} height={35}></img>
                </Grid>
                <Grid item xs={5} justifyContent="center" direction="column" style={{
                    marginTop: '16px'
                }}>
                    <Typography variant='h6'>
                        COSO
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}



const Description = () => {
    const isMobile = useMediaQuery('(max-width:600px)');

    return (
        <>
            <Grid item xs={12} sm={3} container alignItems="center" justify="center">
                <Box textAlign={isMobile ? 'center' : 'right'}>
                    <Typography variant='h6'>
                        La tua richiesta
                    </Typography>
                </Box>
            </Grid>
            {
                isMobile && (
                    <Grid item xs={12} sm={1}>
                        <OrangeLine />
                    </Grid>
                )
            }
            {
                !isMobile && (
                    <Grid item xs={12} sm={1}>
                        <OrangeLine />
                    </Grid>
                )
            }
        </>
    )
}

const COSOAmount = ({ formik }: {
    formik: FormikProps<ExchangeInfoInForm>
}) => {
    return (
        <Box className={useStyle().amountContainer} >
            <Typography variant='h6' style={{ marginTop: '5px', marginBottom: '5px' }}>
                {formik.values.tokensRequestedAmount}
            </Typography>
        </Box>
    )
}

const ContinueButtons = ({
    formik
}: {
    formik: FormikProps<ExchangeInfoInForm>
}) => {
    const { setStep } = useContext(ExchangeFormContext);

    return (
        <>
            <Grid container style={{ marginTop: '1em' }} spacing={2}>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <BlackButton
                        width={227}
                        onClick={() => { setStep(2) }}
                        text={'Avanti'}
                        disabled={formik.values.tokensRequestedAmount === 0}
                    />
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <TransparentButton
                        width={227}
                        style={{
                            color: 'rgba(218, 93, 62, 1)',
                            border: 'none'
                        }}
                        onClick={() => {
                            formik.resetForm()
                            setStep(0)
                        }}
                        text={'Annulla'}
                    />
                </Grid>
            </Grid>
        </>
    );
}
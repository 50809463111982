import React, { CSSProperties, MouseEvent } from 'react';
import Button from '@material-ui/core/Button';
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            background: 'black',
            borderRadius: '15px',
            textTransform: 'none',
            height: '34px',
            width: '100%',
            '&:hover': {
                background: 'rgba(235, 176, 76, 1)',
                color: 'black'
            },
            '&:disabled': {
                background: 'rgba(129, 129, 129, 1)',
                color: 'black'
            },
            '&:focus': {
                background: 'rgba(218, 93, 62, 1)',
                color: 'black'
            }
        },
        '@media (max-width:600px)': {
            button: {
                background: 'black',
                borderRadius: '15px',
                textTransform: 'none',
                height: '34px',
                width: '100%',
                fontSize: '9px'
            }
        },
        
    });

interface NewBlackButtonProps extends WithStyles<typeof styles> {
    onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
    children: React.ReactNode;
    style?: CSSProperties;
    disabled?: boolean;
    className?: string
}

const CustomButton: React.FC<NewBlackButtonProps> = ({ classes, onClick, children, style, disabled, className }) => {
    return (
        <Button
        variant="outlined"
        className={`${classes.root} ${className}`}
        onClick={onClick}
        style={style}
        disabled={disabled}>
            {children}
        </Button>
    );
};

const NewBlackButton = withStyles(styles)(CustomButton);

export default NewBlackButton;


import {
  Box, Grid,
} from "@material-ui/core";
import { Home } from "mdi-material-ui";
import WalletIconForPillMenu from "../../../../assets/img/wallet_icon.svg"
import HomeIconForPillMenu from "../../../../assets/img/home_icon.svg"
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import { RealmContext } from "../../../../providers/RealmProvider";

type ListMenuPropsType = {
  navHandler: (path: string) => void;
  itemList: any;
  isVertical: boolean;
};

const ListMenu = (props: ListMenuPropsType) => {
  const history = useHistory();
  const isUrlInWallet = history.location.pathname.includes("/user/wallet");
  const isUrlInExchanges = history.location.pathname.includes("/user/exchanges");
  const { isCurrentRealmChGarden } = useContext(RealmContext);

  const openWalletPage = () => {
    history.push("/user/wallet");
  }
  const openExchangePage = () => {
    history.push("user/exchanges");
  }
  const openHomePage = () => {
    history.push("/");
  }

  return (
    <>
      {
        props.isVertical && (
          <Box
            style={{
              backgroundColor: 'white',
              borderRadius: "38px",
              border: "1px solid white",
              boxShadow: "2px 3px 4px 0px rgba(0, 0, 0, 0.25)",
              width: props.isVertical ? "60.45px" : "auto",
              height: props.isVertical ? "auto" : "57.45px",
              padding: props.isVertical ? "auto" : "0.25rem 1.5rem"
            }}
          >
            <Grid
              container
              direction={props.isVertical ? "column" : "row"}
              justifyContent="center"
              alignItems="center"
              spacing={2}
              style={{
                paddingTop: props.isVertical ? "1em" : "0",
                paddingBottom: props.isVertical ? "1em" : "0",
              }}
            >
              <Grid item>
                <img src={HomeIconForPillMenu} alt="Homepage button" style={{ cursor: "pointer" }} onClick={openHomePage} />
              </Grid>
              <Grid item>
                {
                  isUrlInWallet ? (
                    <img src='/svgs/wallet_selected.svg' alt="Wallet button" style={{ cursor: "pointer" }} onClick={openWalletPage} />
                  ) : (
                    <img src={WalletIconForPillMenu} alt="Wallet button" style={{ cursor: "pointer" }} onClick={openWalletPage} />
                  )
                }
              </Grid>
              {
                isCurrentRealmChGarden && (
                  <Grid item>
                    {
                      isUrlInExchanges ? (
                        <img src='/svgs/exchange_selected.svg' alt="Wallet button" style={{ cursor: "pointer" }} onClick={openExchangePage} />
                      ) : (
                        <img src='/svgs/exchange.svg' alt="Wallet button" style={{ cursor: "pointer" }} onClick={openExchangePage} />
                      )
                    }
                  </Grid>
                )
              }
            </Grid>
          </Box>
        )
      }
    </>
  );
};

export default ListMenu;

import { Dispatch, SetStateAction, createContext, useState } from "react";

export const ExchangeFormContext = createContext<{
    step: number,
    setStep: Dispatch<SetStateAction<number>>
}>({
    step: 0,
    setStep: () => { }
});
export const ExchangeFormProvider = (props: any) => {
    const children = props.children;
    const [step, setStep] = useState(0);

    return <ExchangeFormContext.Provider value={{
        step,
        setStep
    }}> {children} </ExchangeFormContext.Provider>;
};

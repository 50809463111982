// View for List of NotificationsHistory
import React, { useEffect, useState } from 'react';

import { useTranslation } from "react-i18next";

import getNotificationText from '../../utilities/notification/notification-messages';
import { Divider, IconButton, Link, ListItem, ListItemText, Tooltip, Typography } from '@material-ui/core';
import {
    notificationGetAllMinePagination,
    notificationMarkAsRead,
    notificationRemoveAllFromCurrentlyListed,
    paginationEnable,
    paginationReset
} from "../../store/slices/notificationSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import MiniLoader from "../UI/Loading/MiniLoader";
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles } from "@material-ui/core/styles";
import EmailIcon from '@material-ui/icons/Email';
import { getDate } from "../../utilities/utilities";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import { RealtimeNotificationType } from "../../api/notificationAPI";
import Notification from "../RealTimeNotification/Notification";
import DoneAllIcon from '@material-ui/icons/DoneAll';
const useStyles = makeStyles((theme) => {
    return createStyles({
        container: {
            justifyContent: "center",
        },
        typo: {
            textAlign: "center",
        },
        formControl: {
            marginLeft: theme.spacing(1),
            height: "2.5vw"
        },
        filterBar: {
            marginBottom: theme.spacing(2),
        },
        filterGroup: {
            color: theme.palette.primary.main,
            border: "1px solid " + theme.palette.primary.main,
            height: "4.5vh",
            "&.Mui-selected, &.Mui-selected:hover": {
                background: theme.palette.primary.main,
                color: "#ffffff",
            }
        },
        markAllButton: {
            marginLeft: '20px'
        }

    });
});
const Messages = () => {
    const { t } = useTranslation(['NotificationMessages']);
    const dispatch = useAppDispatch()
    const classes = useStyles();
    const notificationsList = useAppSelector(state => state.notification.notificationsPaginated);
    const paginationHandler = useAppSelector(state => state.notification.paginationHandler);
    const paginationLoading = useAppSelector(state => state.notification.paginationLoading);
    const notfOfCurrentSession = useAppSelector(state => state.notification.notificationsOfCurrentSession);
    const currentprofile = useAppSelector(state => state.user.currentProfile)

    const [page, setPage] = useState(1)
    const [amount, setAmount] = useState(20)
    const [reloadAfterProfileChange, setReloadAfterProfileChange] = useState(false)
    const [selectedFilter, setSelectedFilter] = useState(0);
    const [handleChangeList, setHandleChangeList] = useState(false);

    const handleChangeFilter = (event: object | null, selectedFilter: any) => {
        if (selectedFilter !== null) {
            setSelectedFilter(selectedFilter);
        }
    }

    const historyReloadFrom = () => {
        dispatch(paginationEnable())
        dispatch(paginationReset())
        dispatch(notificationGetAllMinePagination(1, amount))
        setPage(2)
    }

    useEffect(() => {
        historyReloadFrom()
    }, []);

    useEffect(() => {
        if (reloadAfterProfileChange) {
            historyReloadFrom()
        }
        setReloadAfterProfileChange(true)
    }, [currentprofile]);


    useEffect(() => {
        if (handleChangeList) {
            if (selectedFilter === 0) {
                historyReloadFrom()
            }
        }
        setHandleChangeList(true);

    }, [selectedFilter]);

    const shouldDisplay = (realm: string) => {
        switch (realm) {
            case 'ItemMarketplace':
                return true;
            case 'unknown':
                return true;
            case 'user':
                return true;
            default: 
                return false;
        }
    }

    window.onscroll = () => {
        if (!paginationLoading) {
            if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 1) {
                if (paginationHandler) {
                    dispatch(notificationGetAllMinePagination(page, amount))
                    setPage(page + 1)
                }
            }
        }
    };

    let localNotificationList: JSX.Element[] | RealtimeNotificationType[] = [];
    if (selectedFilter === 0) {
        let messageString = '';
        let orderedList = [...notificationsList]

        if (orderedList && orderedList.length > 0) {
            localNotificationList = orderedList.map((item, index) => {
                messageString = getNotificationText(item.body.message, item.body.params, t, currentprofile!.additional_properties!.commonshoodWallet!);

                return (
                    <>
                        {
                            (messageString.length > 0 && !messageString.includes('unrecognized messageKey for notification')) && (
                                <>
                                    <ListItem key={index}>
                                        <Grid container alignItems={'center'}>
                                            <Grid item xs={1}>
                                                <EmailIcon />
                                            </Grid>
                                            <Grid item xs={9}>
                                                <ListItemText primary={messageString} secondary={getDate(item.timestamp)} />
                                            </Grid>
                                            <Grid container item xs={2} justifyContent="flex-end">
                                            </Grid>
                                        </Grid>
                                    </ListItem>
                                    <Divider />
                                </>
                            )
                        }
                    </>
                )
            });
        }
    } else if (selectedFilter === 1) {
        let orderedList = [...notfOfCurrentSession]

        if (orderedList && orderedList.length > 0) {
            localNotificationList = orderedList.sort((notf1, notf2) => { //order with last created in 0 position
                const notf1_message = JSON.parse(notf1.message);
                const notf2_message = JSON.parse(notf2.message);
                return notf2_message.timestamp - notf1_message.timestamp;
            }).map((item: RealtimeNotificationType, index: number) => {
                return (
                    <>
                        {
                            (item.message != undefined && item.message.length > 0 ) && (
                                <Typography key={item._id} component={'span'}>
                                    <Notification
                                        params={item}
                                    />
                                </Typography>
                            )
                        }
                    </>

                )
            })
        }
    }

    let notificationEndedMessage = selectedFilter === 0 ? t('NoMoreNotf') : t('NoUnreadNotf')

    const notificationsEndedComponent =
        <Grid container className={classes.container}>
            <Typography component={'span'}> {notificationEndedMessage} </Typography>
        </Grid>

    const markALLNotificationAsRead = () => {
        dispatch(notificationMarkAsRead(null)) // null means 'ALL'
        dispatch(notificationRemoveAllFromCurrentlyListed())
    }

    const markAllButton =
        <IconButton onClick={() => markALLNotificationAsRead()} className={classes.markAllButton}
            color="primary">
            <Tooltip aria-label="Bold" role="presentation" title={'Mark ALL as read'}><DoneAllIcon /></Tooltip>
        </IconButton>

    return (
        <>
            <Grid container justifyContent='center'>
                <Grid container className={classes.filterBar} spacing={3}>
                    <Grid item container xs={12} md={6} justifyContent="flex-start">
                        <ToggleButtonGroup
                            value={selectedFilter}
                            exclusive
                            onChange={handleChangeFilter}
                            aria-label="text alignment"
                        >
                            <ToggleButton disabled={paginationLoading} value={0} className={classes.filterGroup}>
                                {t('All')}
                            </ToggleButton>
                            <ToggleButton disabled={paginationLoading} value={1} className={classes.filterGroup}>
                                {t('Unread')}
                            </ToggleButton>
                        </ToggleButtonGroup>
                        {selectedFilter === 1 && notfOfCurrentSession.length > 1 ? markAllButton : null}
                    </Grid>
                </Grid>
            </Grid>
            {localNotificationList}
            {paginationLoading ? <MiniLoader text={true} /> : null}
            {!paginationHandler && !paginationLoading && selectedFilter === 0 || selectedFilter === 1 && notfOfCurrentSession.length === 0 ? notificationsEndedComponent : null}
        </>
    );
}

export default Messages;

import { createContext, useState } from "react";
import { getMostRecentUsers } from "../api/notificationAPI";
import { UserNameType, getUserNameByAddress } from "../api/userAPI";

export const ContactsContext = createContext<{
    loading: boolean,
    getListOfRecentContacts: (limit: number) => Promise<UserNameType[]>
}>({
    loading: false,
    getListOfRecentContacts: async (limit: number) => [],
});
export const ContactsProvider = (props: any) => {
    const children = props.children;
    const [loading, setLoading] = useState(false);

    const getListOfRecentContacts = async (limit: number): Promise<UserNameType[]> => {
        const result: UserNameType[] = [];
        setLoading(true);
        try {
            const listOfRecentAddresses = await getMostRecentUsers(limit, null);
            for (let i = 0; i < listOfRecentAddresses.length; i++) {
                const u = await getUserNameByAddress(listOfRecentAddresses[i]);
                if (u) {
                    result.push(u);
                }
            }
        } catch (error) {
            console.log('Error in getListOfRecentContacts, ', error);
        } finally {
            setLoading(false);
        }
        return result;
    }

    return <ContactsContext.Provider value={{
        loading,
        getListOfRecentContacts,
    }}> {children} </ContactsContext.Provider>;
};

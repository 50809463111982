import React, { CSSProperties, useState } from 'react';
import { Tabs, Tab, makeStyles, ThemeProvider, createTheme } from '@material-ui/core';

interface IAilinTabsProps {
    value: number,
    labels: string[],
    onChange: (_event: React.ChangeEvent<{}>, newValue: number) => void | undefined
}

const theme = createTheme({
    overrides: {
        MuiTab: {
            root: {
                '&.Mui-selected': {
                    backgroundColor: 'white',
                    color: 'black',
                    borderRadius: '25px'
                },
                '&:not(.Mui-selected)': {
                    opacity: 1,
                }
            },

        },
    },
});

const useStyles = makeStyles({
    ailinTabs: {
        borderRadius: '25px',
        background: '#181B1D',
        color: 'white',
        width: 'fit-content',
        padding: '1px'
    },

})

const AilinTabs: React.FC<IAilinTabsProps> = ({ value, labels, onChange }) => {

    const style = useStyles();
    const handleChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
        onChange(_event, newValue);
    };

    return (
        <ThemeProvider theme={theme}>
            <Tabs
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example"
                className={style.ailinTabs}
                TabIndicatorProps={{
                    style: {
                        display: 'none',
                    },
                }}
            >
                {labels.map(tabLabel => {
                    return <Tab label={tabLabel} />
                })}
            </Tabs>
        </ThemeProvider>
    );
};

export default AilinTabs;

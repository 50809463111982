import { useMediaQuery, Typography, Stepper, Step, StepLabel, Grid, makeStyles, Box } from "@material-ui/core";
import { useState, useEffect, useContext } from "react";
import { getItemInfoByID } from "../../../../../api/libOfThingsAPI";
import { Preorder, PreorderStatus, PreorderStatusStrings, PreorderStatuses } from "../../../../../types/calendar.type"
import { Item } from "../../../../../types/libofthings.type";
import { ButtonsToHandlePreorder } from "./ButtonsToHandlePreorder";
import { getCollectionBySymbol } from "../../../../../api/nftAPI";
import { EndLendingPeriodDialog } from "./EndLendingPeriodDialog";
import { cancelPreorder, endLendingPeriod } from "../../../../../store/slices/calendarSlice";
import { isApproved } from "../../../../../store/slices/nftSlice";
import config from "../../../../../config";
import { useAppDispatch } from "../../../../../store/hooks";
import toast from "react-hot-toast";
import { ActivePreordersStepper } from "../../../MyRequests/Steppers/ActivePreordersStepper";
import { ItemImage } from "../../MyItems/ItemImage";
import { CosoSeparator } from "../../../BasicComponents/CosoSeparator";
import { useTranslation } from "react-i18next"
import { FirstLifeContextImpl } from "../../../../../providers/FirstLifeProvider";

const useStyles = makeStyles({
    preorder: {
        background: 'white',
        width: '100%',
        borderRadius: '18px',
    },
    centerOnlyInMobile: {
        ['@media (max-width:600px)']: {
            textAlign: 'center',
        }
    },
    requestDateContainer: {
        ['@media (max-width:600px)']: {
            textAlign: 'center',
        },
        ['@media (min-width:600px)']: {
            textAlign: 'right',
        }
    },
    hideInMobile: {
        ['@media (max-width:600px)']: {
            display: 'none',
        }
    },
    displayOnlyInMobile: {
        ['@media (min-width:600px)']: {
            display: 'none',
        }
    }
})

export const ActivePreorderInList = ({
    preorder,
    callback
}: {
    preorder: Preorder,
    callback: any
}) => {
    const [item, setItem] = useState<Item>();
    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
    const [requestDate, setRequestDate] = useState<string>('');
    const [openDialogToEndLendingPeriod, setOpenDialogToEndLendingPeriod] = useState<boolean>(false);
    const [status, setStatus] = useState<PreorderStatus>(PreorderStatus.Pending);
    const [collectionAddress, setCollectionAddress] = useState<string>('');
    const useMobileQuery = useMediaQuery('(max-width: 600px)');
    const { getUserByAddress } = useContext(FirstLifeContextImpl);
    const styles = useStyles();
    const dispatch = useAppDispatch();
    const { t } = useTranslation('LibOfThings');
    const [borrowerEmail, setBorrowerEmail] = useState<string>('');


    useEffect(() => {
        (async () => {
            await handleSetItem();
            await retrieveEmailBorrower();
        })()
    }, [])

    const handleSetItem = async () => {
        const item = await getItemInfoByID(preorder.itemID);
        if (item != null) {
            const collection = await getCollectionBySymbol(item.collectionSymbol);
            if (collection != null) {
                setCollectionAddress(collection.contractAddress);
            }
        }
        setItem(item);
    }

    const retrieveEmailBorrower = async () => {
        if (preorder.borrower != null) {
            const user = await getUserByAddress(preorder.borrower);
            if (user != null) {
                setBorrowerEmail(user.email);
            }
        }
    }

    useEffect(() => {
        if (preorder != null) {
            const currentLocale = navigator.language;
            const dateOptions: Intl.DateTimeFormatOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
            const startDate = new Date(preorder.startDate * 1000);
            const endDate = new Date(preorder.endDate * 1000);
            const requestDate = new Date(preorder.timestamp * 1000);

            setStartDate(startDate.toLocaleString(currentLocale, dateOptions));
            setEndDate(endDate.toLocaleString(currentLocale, dateOptions));
            setRequestDate(requestDate.toLocaleString(currentLocale, dateOptions));
            handleStatus(preorder.status);
        }
    }, [preorder])

    const handleStatus = (status: string) => {
        switch (status) {
            case 'Pending':
                setStatus(PreorderStatus.Pending);
                break;
            case 'Accepted':
                setStatus(PreorderStatus.Accepted);
                break;
            case 'Started':
                setStatus(PreorderStatus.Started);
                break;
            case 'Ended':
                setStatus(PreorderStatus.Ended);
                break;
            default:
                break;
        }
    }

    const handleEndLendingPeriod = async (comment: string, isFine: boolean) => {
        const toastid = toast.loading('Termino il prestito');
        if (item == null) { return; }
        try {
            const borrowerApprovedTheEndOfLendingPeriod = await dispatch(isApproved(collectionAddress, config.smartContracts.CALENDAR_ADDRESS, item.idInCollection))
            if (borrowerApprovedTheEndOfLendingPeriod == null) {
                toast.dismiss(toastid);
                toast.error('Errore durante la verifica del prestito');
                return;
            }
            await dispatch(endLendingPeriod(preorder.preorderID, comment, isFine));
            callback();
        } catch (e: any) {
            console.log(e);
            toast.error(e.message);
        } finally {
            toast.dismiss(toastid);
        }
    }

    const handleClickOnEndLendingPeriod = async () => {
        if (item == null) { return; }
        const borrowerApprovedTheEndOfLendingPeriod = await dispatch(isApproved(collectionAddress, config.smartContracts.CALENDAR_ADDRESS, item.idInCollection))
        if (borrowerApprovedTheEndOfLendingPeriod == null) {
            toast.error('Errore durante la verifica del prestito');
            return;
        }
        if (!borrowerApprovedTheEndOfLendingPeriod) {
            toast.error('Aspetta che il prestatore approvi la fine del prestito');
            return;
        } else {
            //We shall direttamente chiamare con is broken false
            // setOpenDialogToEndLendingPeriod(true);
            await handleEndLendingPeriod("", true);
        }
    }

    const getMessageForStepper = () => {
        switch (status) {
            case PreorderStatus.Pending:
                return 'In attesa';
            case PreorderStatus.Accepted:
                return 'Accettata';
            case PreorderStatus.Started:
                return 'Iniziata';
            default:
                return '';
        }
    }

    return (
        <>
            <Grid container className={styles.preorder} spacing={1} >
                <EndLendingPeriodDialog
                    open={openDialogToEndLendingPeriod}
                    setOpen={setOpenDialogToEndLendingPeriod}
                    confirmFallback={handleEndLendingPeriod}
                    cancelFallback={() => { setOpenDialogToEndLendingPeriod(false) }}
                />
                {
                    (preorder != null && item != null && startDate != null) && (
                        <>
                            <Grid item xs={4} sm={2} style={{ paddingLeft: '20px' }}>
                                <ItemImage
                                    collectionSymbol={item.collectionSymbol}
                                    id={item.idInCollection}
                                    option={undefined}
                                    respectRatio={true}
                                    style={{ display: 'block', margin: 'auto' }}
                                />

                                <Grid container>
                                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>{item.name}</Typography>
                                    </Grid>
                                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                                        <Typography variant="body1" style={{ fontWeight: 'bold', color: 'rgba(218, 93, 62, 1)' }}>{item.category[0].concat(item.category.slice(1).toLowerCase())}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={1} className={styles.hideInMobile} style={{
                            }}>
                                <img height={'90%'} src="/svgs/orange_line_desktop.svg" className={styles.hideInMobile} style={{ display: 'block', margin: 'auto' }} />
                            </Grid>
                            <Grid item xs={8} sm={9}>
                                <Grid container spacing={1} style={{ marginTop: '1em' }}>
                                    <Grid item xs={12} sm={6} className={styles.centerOnlyInMobile}>
                                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>{t('requestNoun')} {preorder.preorderID.slice(0, 3).concat(preorder.preorderID.slice(-4)).toLocaleUpperCase()}</Typography>
                                    </Grid>
                                    {
                                        !useMobileQuery && (
                                            <Grid item xs={12} sm={6} style={{ padding: '16px 30px 16px 30px', textAlign: 'right' }} >
                                                <Typography variant="body1" >{borrowerEmail}</Typography>
                                            </Grid>
                                        )
                                    }
                                    <Grid item xs={12} className={styles.centerOnlyInMobile}>
                                        {
                                            (useMobileQuery) ? (
                                                <Typography variant="body1" >Richiesto il <strong>{requestDate}</strong><br />per il periodo dal<br /> <strong>{startDate}</strong> al <strong>{endDate}</strong></Typography>
                                            ) : (
                                                <Typography variant="body1" >Richiesto il <strong>{requestDate}</strong> per il periodo dal <strong>{startDate}</strong> al <strong>{endDate}</strong></Typography>
                                            )
                                        }
                                    </Grid>
                                    <Grid item xs={12} md={7} className={styles.centerOnlyInMobile} style={{
                                        marginTop: '1em'
                                    }}>
                                        {
                                            (useMobileQuery) ? (<>
                                                <></>
                                            </>) : (<>
                                                <div>
                                                    <ActivePreordersStepper status={status} />
                                                </div>
                                            </>)
                                        }
                                    </Grid>
                                    {
                                        (item && preorder.status === PreorderStatuses.Accepted || preorder.status === PreorderStatuses.Started) && (
                                            <ButtonsToHandlePreorderDesktop
                                                callback={callback}
                                                preorder={preorder}
                                                handleClickOnEndLendingPeriod={handleClickOnEndLendingPeriod}
                                                item={item}
                                                collectionAddress={collectionAddress}
                                                borrowerEmail={borrowerEmail}
                                            />
                                        )
                                    }
                                </Grid>
                            </Grid>
                            <Grid item xs={12} className={styles.displayOnlyInMobile}>
                                <CosoSeparator direction="Horizontal" style={{ width: '90%', margin: '20px auto' }} />
                            </Grid>
                        </>
                    )
                }
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                    {
                        (useMobileQuery) && <ActivePreordersStepper status={status} />
                    }
                </Grid>
                <Grid item xs={12} className={styles.displayOnlyInMobile} style={{ textAlign: 'center', fontSize: '1rem', lineHeight: '1.25rem', fontWeight: 'bold', marginBottom: '1rem' }}>
                    <Typography variant="body1" style={{ fontWeight: 'bold' }}>{getMessageForStepper()}</Typography>
                </Grid>
                {
                    useMobileQuery && (
                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                            <Typography variant="body1" style={{ fontWeight: 'bold' }}>{borrowerEmail}</Typography>
                        </Grid>
                    )
                }
                {
                    (item != undefined && (preorder.status === PreorderStatuses.Accepted || preorder.status === PreorderStatuses.Started)) && (
                        <ButtonsToHandlePreordersMobile borrowerEmail={borrowerEmail} item={item} collectionAddress={collectionAddress} callback={callback} preorder={preorder} handleClickOnEndLendingPeriod={handleClickOnEndLendingPeriod} />
                    )
                }
            </Grid>
        </>
    )
}

const ButtonsToHandlePreorderDesktop = ({ collectionAddress, item, callback, preorder, handleClickOnEndLendingPeriod, borrowerEmail }: {
    callback: any,
    preorder: Preorder,
    handleClickOnEndLendingPeriod: () => {},
    item: Item,
    collectionAddress: string,
    borrowerEmail?: string
}) => {
    const styles = useStyles();
    return (
        <Grid item md={5} justifyContent="flex-end" className={styles.hideInMobile} style={{ width: '100%', marginBottom: '16px', textAlign: 'center', }}>
            <ButtonsToHandlePreorder borrowerEmail={borrowerEmail} collectionAddress={collectionAddress} item={item} callback={callback} preorder={preorder} openDialogFallback={handleClickOnEndLendingPeriod} />
        </Grid>
    )
}

const ButtonsToHandlePreordersMobile = ({ borrowerEmail, collectionAddress, callback, preorder, handleClickOnEndLendingPeriod, item }: {
    callback: any,
    preorder: Preorder,
    item: Item,
    collectionAddress: string,
    handleClickOnEndLendingPeriod: () => {},
    borrowerEmail: string
}) => {
    const styles = useStyles();
    return (
        <Grid container style={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto', marginBottom: '1em', width: '16.5em' }} className={styles.displayOnlyInMobile}>
            <ButtonsToHandlePreorder borrowerEmail={borrowerEmail} item={item} collectionAddress={collectionAddress} callback={callback} preorder={preorder} openDialogFallback={handleClickOnEndLendingPeriod} />
        </Grid>
    )

}
import { useTranslation } from 'react-i18next';
import getNotificationText from '../../utilities/notification/notification-messages';
import { NotificationParameters, RealtimeNotificationType } from "../../api/notificationAPI";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { notificationMarkAsRead, notificationRemoveFromCurrentlyListed } from "../../store/slices/notificationSlice";
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Grid, IconButton, ListItemText, Tooltip, Typography, useMediaQuery } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

type NotificationProps = {
    params: RealtimeNotificationType
}

const Notification = (props: NotificationProps) => {
    const {
        params
    } = props;

    const { t } = useTranslation('NotificationMessages');
    const currentProfile = useAppSelector(state => state.user.currentProfile)
    const dispatch = useAppDispatch()

    const notification: NotificationParameters = JSON.parse(params.message)
    let fullMessageString = '';
    let messageString = '';

    try {
        if (currentProfile) {
            let chWallet = currentProfile.additional_properties?.commonshoodWallet
            if (chWallet) {
                const messageKey = translateMessageKey(notification, chWallet)
                messageString = getNotificationText(messageKey, notification, t, chWallet);
                fullMessageString = messageString;
                messageString = messageString.slice(0, 30)
                if (messageString.length >= 30) {
                    messageString += '...'
                }
            }
        }
    } catch (error: any) {
        messageString = error.message;
    }

    const markNotificationAsRead = (expanded: boolean) => {
        if (!expanded) {
            dispatch(notificationMarkAsRead(params._id))
            dispatch(notificationRemoveFromCurrentlyListed({ messageId: params._id }))
        }
    }

    const getFrom = () => {
        if (notification.from_name === currentProfile?.name)
            return 'da me';
        if (notification.from_name == null && notification.borrower != currentProfile?.additional_properties?.commonshoodWallet)
        {
            if (notification.from_name) {
                return `da ${notification.from_name}`
            }
            else
                return '';
        }
        if (notification.from_name == null && notification.borrower == currentProfile?.additional_properties?.commonshoodWallet)
            return 'da me';
        if (notification.from_name === 'zero_address')
            return '';
        else {
            return '';
        }

    }

    const getDateFormated = () => {
        const date = new Date(notification.timestamp * 1000)
        const istoday = new Date().getDate() === date.getDate() && new Date().getMonth() === date.getMonth() && new Date().getFullYear() === date.getFullYear()
        const isYesterday = new Date().getDate() - 1 === date.getDate() && new Date().getMonth() === date.getMonth() && new Date().getFullYear() === date.getFullYear()

        if (istoday)
            return `oggi \n${date.getHours()}:${date.getMinutes()}`
        else if (isYesterday)
            return `ieri \n${date.getHours()}:${date.getMinutes()}`
        else
            return `${date.getDate()}/${date.getMonth() + 1}`
    }
    return (
        <>
            <Accordion style={{ border: 'none', boxShadow: 'none', padding: '0px' }} onChange={(event, expanded) => markNotificationAsRead(expanded)}>
                <AccordionSummary
                    style={{ margin: '0px' }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Grid container>
                        <Grid item xs={10}>
                            <Typography>{messageString}</Typography>
                        </Grid>
                        <Grid item xs={2} style={{
                            color: 'rgba(24, 27, 29, 0.6)'
                        }}>
                            <Typography style={{ fontSize: '12px' }}>{getDateFormated()}</Typography>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: '-0.40em' }}>
                            <ListItemText secondary={getFrom()} />
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        {fullMessageString}
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Box textAlign={'center'} margin={2}>
                <Divider />
            </Box>

        </>
    );
};

export default (Notification);

function translateMessageKey(notification: NotificationParameters, chWallet: string) {
    if (notification.eventType !== 'COIN_TRANSFER') {
        // If the event type is not a coin transfer, return the event type as is
        return notification.eventType;
    } else {
        // If the event type is a coin transfer, check the sender
        if (notification.sender === chWallet) {
            // If the sender is chWallet, return 'COIN_SENT'
            return 'COIN_SENT';
        } else {
            // If the sender is not chWallet, return 'COIN_RECEIVED'
            return 'COIN_RECEIVED';
        }
    }
}


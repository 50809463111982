import { createContext, useEffect, useState } from "react";
import { generateHashForPreorder } from "../api/libOfThingsAPI";
import { Item, NumberOfPreordersAsBorrower, NumberOfPreordersAsLender, StatsResponse } from "../types/libofthings.type";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { createPreorderSlice } from "../store/slices/calendarSlice";
import config from "../config.js";
import * as axiosSOT from "../utilities/backend/axios-metadata";
import { Preorder, PreorderStatus } from "../types/calendar.type";

export const CalendarContractContext = createContext<{
    handlePreorder: (item: Item) => Promise<any>,
    setDates: (startDate: Date, endDate: Date) => void,
    startDate?: Date,
    endDate?: Date,
    preordersPerPage: number,
    getUserStats: () => Promise<StatsResponse>,
    stats: NumberOfPreordersAsBorrower | undefined,
    statsLender: NumberOfPreordersAsLender | undefined,
    getPreordersBetweenDates: (start: number, end: number, itemID: number) => Promise<Preorder[]>,
    getPreordersInLastXHours: (address: string, isLender: boolean, hours: number) => Promise<Preorder[]>
}>({
    handlePreorder: async (item: Item) => { },
    setDates: (startDate: Date, endDate: Date) => { },
    getPreordersBetweenDates: async (start: number, end: number, itemID: number) => { return []; },
    preordersPerPage: 5,
    startDate: undefined,
    endDate: undefined,
    stats: undefined,
    statsLender: undefined,
    getPreordersInLastXHours: async (address: string, isLender: boolean, hours: number) => {
        return []
    },
    getUserStats: async () => {
        return {
            ok: false,
            itemsOwned: [],
            borrowings: [],
            lendings: [],
            manifestoAccepted: false,
            preordersAsBorrower: { pending: 0, accepted: 0, started: 0, ended: 0, canceled: 0 },
            preordersAsLender: { pending: 0, accepted: 0, started: 0, ended: 0, canceled: 0 },
        }
    }
});
export const CalendarContractProvider = (props: any) => {
    const children = props.children;
    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();
    const preordersPerPage = 5;
    const profile = useAppSelector(state => state.user.currentProfile)
    const dispatch = useAppDispatch();
    const [stats, setStats] = useState<NumberOfPreordersAsBorrower | undefined>(undefined);
    const [statsLender, setStatsLender] = useState<NumberOfPreordersAsLender | undefined>(undefined);

    useEffect(() => {
        (async () => {
            await getUserStats();
        })();
    }, [profile]);

    const setDates = (startDate: Date, endDate: Date) => {
        setStartDate(startDate);
        setEndDate(endDate);
    }

    const isDateSelectedValid = (dateSelected: Date): boolean => {
        let dateIsvalid = true;
        const monthSelected = dateSelected.getMonth();
        const yearSelected = dateSelected.getFullYear();
        const daySelected = dateSelected.getDate();
        const today = new Date();
        const monthToday = today.getMonth();
        const yearToday = today.getFullYear();
        if (yearSelected < yearToday) {
            dateIsvalid = false;
        } else if (yearSelected === yearToday && monthSelected < monthToday) {
            dateIsvalid = false;
        } else if (yearSelected === yearToday && monthSelected === monthToday && daySelected < today.getDate()) {
            dateIsvalid = false;
        }
        return dateIsvalid;
    }

    const handlePreorder = async (item: Item): Promise<any> => {
        if (!startDate || !endDate) {
            throw new Error('Seleziona una data di inizio e una di fine');
        }
        const address = profile?.additional_properties?.commonshoodWallet;
        if (address === item.owner) {
            throw new Error("Possiedi questo oggetto");
        }
        if (!isDateSelectedValid(startDate) || !isDateSelectedValid(endDate)) {
            throw new Error("La data selezionata non è valida");
        }
        if (!address) {
            throw new Error('No address found');
        }
        const { hash, signature } = await generateHashForPreorder(
            item.itemID,
            startDate,
            endDate,
            address
        );
    
        const { trx } = await dispatch(createPreorderSlice(item.itemID,
            startDate,
            endDate,
            hash,
            signature,
            item.paymentToken,
            item.price,
            item.caution
        ))
        return trx;
    }

    const getUserStats = async (): Promise<StatsResponse> => {
        let response: StatsResponse | null = null;
        try {
            const loggedAddress = profile?.additional_properties?.commonshoodWallet;
            if (loggedAddress != undefined) {
                //offset and limit are not used since this call is just to get the number of preorders for each status
                const q = `${config.network.metadata.url}lot/${loggedAddress}?offset=0&limit=1`;
                const res = await axiosSOT.default.get(q);
                if (res.status === 200) {
                    response = res.data as StatsResponse;
                    setStats(response.preordersAsBorrower);
                    setStatsLender(response.preordersAsLender);
                } else {
                    throw new Error("Error while getting user stats");
                }
            }
        } catch (e: any) {
            console.log(e.message);
        }
        return response as StatsResponse;
    }

    const getPreordersBetweenDates = async (start: number, end: number, itemID: number): Promise<Preorder[]> => {
        let preorders: Preorder[] = [];
        try {
            const q = `${config.network.metadata.url}preorders?startDate=${start}&endDate=${end}&itemID=${itemID}`;
            const res = await axiosSOT.default.post(q);
            console.log(res.data);
        } catch (e) {
            console.log(e);
        }
        return preorders;
    }

    const getPreordersInLastXHours = async (address: string, isLender: boolean, hours: number): Promise<Preorder[]> => {
        console.log(`Getting the preorders in the X last hours`);
        let preorders: Preorder[] = [];
        try {
            hours = hours * 3600
            const body = {
                "order": {
                    "startDate": -1
                },
                "filter": {
                    "realm": config.realm.REALM,
                    "endDate": {
                        "$gte": Math.floor(new Date().getTime() / 1000),
                        "$lte": Math.floor(new Date().getTime() / 1000 + hours) 
                    },
                    "status": "Started"
                }
            }
            if (isLender) {
                (body["filter"] as any).lender = address;
            } else {
                (body["filter"] as any).borrower = address;
            }
            const q = `${config.network.metadata.url}preorders`;
            const res = await axiosSOT.default.post(q, body);
            preorders = res.data.preorders;
        } catch (err: any) {
            console.log(err.message);
        }
        return preorders;
    }

    const viewContextData = {
        handlePreorder,
        setDates,
        getUserStats,
        getPreordersInLastXHours,
        startDate,
        endDate,
        preordersPerPage,
        stats,
        statsLender,
        getPreordersBetweenDates
    }

    return <CalendarContractContext.Provider value={viewContextData}> {children} </CalendarContractContext.Provider>;
};


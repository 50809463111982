import { TextField, makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
    textField: {
        width: '100%',
        borderRadius: '15px',
        padding: '10px',
        height: '15px',
        border: '1.5px solid rgba(24, 27, 29, 1)'
    },
})

export const InputField = ({ value, placeholder, onChange, type = 'text', style, min, max, disabled = false }: {
    value: string, placeholder: string, variant: 'outlined' | 'standard' | 'filled' | null,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    style?: any,
    type?: string,
    min?: number,
    max?: number,
    disabled?: boolean
}) => {
    const classes = useStyles();

    return (
        <input
            type={type}
            placeholder={placeholder}
            value={value}
            style={style}
            id="standard-basic"
            onChange={onChange}
            className={classes.textField}
            min={min}
            max={max}
            disabled={disabled}
        />
    );
}


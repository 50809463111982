import { CircularProgress, Grid, Typography, makeStyles } from "@material-ui/core"
import { style } from "openlayers"
import { BlackButton } from "../../../components/Buttons/BlackButton"
import { CosoSeparator } from "../BasicComponents/CosoSeparator"
import { ExchangeTokenEntry, IExchangeTokenEntry } from "./ExchangeTokenEntry";
import { format } from "date-fns";
import ExchangeAcceptDialog from "../../Exchanges/ExchangeAcceptDialog";
import { IExchangeSummaryData } from "../../Exchanges/ExchangeSummaryDialog";
import NewBlackButton from "../../../components/Buttons/NewBlackButton";


const separatorColor = '#C7C7C7';

const useStyle = makeStyles({
    exchangeCard: {
        width: 243,
        minHeight: 282,
        backgroundColor: '#FFF',
        borderRadius: 19,
        position: 'relative',
        padding: '24px 0',
        color: '#18181d',
        fontFamily: 'Be Vietnam Pro',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal'
    },
    exchangeCardBadge: {
        width: '92.377px',
        height: 20,
        borderRadius: 10,
        backgroundColor: '#DA5D3E',
        color: '#FFF',
        textAlign: 'center',
        fontFamily: 'Be Vietnam Pro',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '18px',
        position: 'absolute',
        top: '-3%',
        left: '50%',
        transform: 'translateX(-50%)',
    },
    exchangeCardExpiryText: {
        color: '#181B1D',
        textAlign: 'center',
        fontFamily: 'Be Vietnam Pro',
        fontSize: '10px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '16px',
    },
    exchangeCardEmailText: {
        color: '#DA5d3e',
        textAlign: 'center',
        fontFamily: 'Be Vietnam Pro',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '18px'
    },
    exchangeDefaultText: {
        color: '#18181d',
        fontFamily: 'Be Vietnam Pro',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal'
    }
});

export interface IExchangeOwnCardInfo {
    fromUserEmail: string,
    offeredTokens: IExchangeTokenEntry[],
    desiredTokens: IExchangeTokenEntry[],
    expiry: Date,
    maxExchangeAmount: number,
    apiObject: any
}

interface IExchangeOwnCardProps {
    exchangeCardInfo: IExchangeOwnCardInfo,
    onExchangeDeleted: (e: IExchangeSummaryData) => void;
}

export const ExchangeOwnCard: React.FC<IExchangeOwnCardProps> = ({
    exchangeCardInfo,
    onExchangeDeleted
}) => {
    const { fromUserEmail, offeredTokens, desiredTokens, expiry, maxExchangeAmount } = exchangeCardInfo;
    const style = useStyle();
    const { expiryDate, expiryHour } = formatDate(expiry);

    function formatDate(data: Date): { expiryDate: string, expiryHour: string } {
        const expiryDate = format(data, 'dd/MM/yyyy');
        const expiryHour = format(data, 'HH:mm');

        return { expiryDate, expiryHour };
    }
    return (
        <>
            <Grid container className={style.exchangeCard} direction='column' justifyContent="space-around" alignItems="center">
                <div className={style.exchangeCardBadge}>{maxExchangeAmount === 1 ? '1 disponibile' : `${maxExchangeAmount} disponibili`}</div>
                <Typography className={style.exchangeCardEmailText}>{fromUserEmail}</Typography>
                <Typography className={style.exchangeDefaultText} align="center">offre</Typography>
                {
                    offeredTokens.map(t => {
                        return (
                            <Grid item container direction="row" justifyContent="center" alignItems="center" style={{ width: 150, height: 20, textTransform: 'uppercase' }}>
                                <ExchangeTokenEntry symbol={t.symbol} amount={t.amount} />
                            </Grid>
                        );
                    })
                }
                <CosoSeparator direction="Horizontal" style={{ width: '34%', borderColor: separatorColor }} />
                <Typography className={style.exchangeDefaultText} align="center">richiede</Typography>
                {
                    desiredTokens.map(t => {
                        return (
                            <Grid item container direction="row" justifyContent="center" alignItems="center" style={{ width: 150, height: 20, textTransform: 'uppercase' }}>
                                <ExchangeTokenEntry symbol={t.symbol} amount={t.amount} />
                            </Grid>
                        );
                    })
                }
                <CosoSeparator direction="Horizontal" style={{ width: '60%', borderColor: separatorColor }} />
                <Typography align="center" className={style.exchangeCardExpiryText}>Scade il {expiryDate} - <br />{expiryHour}</Typography>
                {/* This component consists in a blackbutton and an embedded accept dialog */}
                <NewBlackButton style={{borderRadius: '50%', width: 35, height: 35, minWidth: 0}} onClick={() => onExchangeDeleted({exchangeInfo: exchangeCardInfo, acceptedCount: 0})}>
                    <img src="/svgs/trash.svg" alt="Delete Exchange Button" style={{flexShrink: 0}}/>
                </NewBlackButton>
            </Grid>
        </>
    )
}
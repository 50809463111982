import { Grid, Typography, makeStyles, useMediaQuery } from "@material-ui/core";
import { FormikProps } from "formik";
import { ItemInfoInForm } from "../../../types/items.type";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
    box: {
        height: '90%',
        width: '90%',
        minWidth: '300px',
        minHeight: '300px',
        borderRadius: '18px',
        background: 'white',
        position: 'relative',
        ['@media (min-width: 600px)']: {
            margin: 'auto'
        },
        ['@media (max-width: 600px)']: {
            width: '100%',
        },
    },
    input: {
        position: 'absolute',
        top: '0px',
        left: '0px',
        zIndex: 1,
        height: '100%',
        width: '100%',
        cursor: 'pointer',
        margin: 0,
        paddingLeft: '30px',
        background: 'transparent',
        border: 'none',
        fontSize: '0px',
        outline: 0,
        opacity: 0
    },
    image: {
        width: 'auto', // Allow the width to adjust based on the aspect ratio
        maxHeight: '100%',
        maxWidth: '100%', // Limit the maximum width to 100% of its container
        ['@media (max-width: 600px)']: {
            marginTop: '1em',
            maxHeight: '150px',
        }
    }
})

export const UploadItemLeftPanel = ({ formik }: {
    formik: FormikProps<ItemInfoInForm>
}) => {
    const classes = useStyles();
    const mobile = useMediaQuery('(max-width:600px)');
    const { t } = useTranslation('LibOfThings')
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target != null && event.target.files != null) {
            const file = event.target.files[0];
            formik.setFieldValue('image', file);
        }
    }

    return (
        <Grid container className={classes.box} direction="column" justifyContent="center" alignContent="center" style={{ position: 'relative' }}>
            <input
                id="contained-button-file"
                name="image"
                type="file"
                accept="image/*"
                onChange={handleChange}
                className={classes.input}
            />
            {formik.values.image ? (
                <img className={classes.image} src={URL.createObjectURL(formik.values.image)} alt="placeholder" />
            ) : (
                <>
                    <img src="/svgs/media_image_icon.svg" alt="placeholder" />
                    <Typography variant="h5">{t('addImage')}</Typography>
                    {!mobile && <Typography variant="body1">{t('dragFileOrSearch')}</Typography>}
                </>
            )}
        </Grid>
    );
}

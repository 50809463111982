import { Avatar, Box, FormControl, Grid, Input, InputAdornment, Theme, Typography, createStyles, makeStyles, useMediaQuery } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { ListCouponInWallet } from "./CouponInWalletPage/ListCouponInWallet";
import { TransparentButton } from "../../../components/Buttons/TransparentButton";
import SearchIcon from '@material-ui/icons/Search';
import { useEffect, useState } from "react";
import { getUserNameByAddress } from "../../../api/userAPI";
import { UserData } from "../../../types/user.type";
import { getMostRecentUsers } from "../../../api/notificationAPI";
import { CouponLogo } from "./MyCoupons/CouponLogo";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getTokensOwnedFromMetadata } from "../../../api/coinAPI";
import config from "../../../config";
import { InputField } from "../../../components/Input/InputField";
import { BlackButton } from "../../../components/Buttons/BlackButton";
import { coinSend } from "../../../store/slices/coinSlice";
import toast from "react-hot-toast";
import { ListContactCoso } from "../COSO/CosoInWallet";
import { Skeleton } from "@material-ui/lab";

export const CouponWallet = () => {
    const history = useHistory();
    return (
        <>
            <Grid container style={{ marginBottom: '10em', marginTop: '1.5em' }}>
                <Grid item xs={12} style={{ textAlign: 'right', marginRight: '20px', marginBottom: '-5px' }}>
                    <Typography variant='body2' style={{ cursor: 'pointer' }} onClick={() => {
                        history.push('/coupon/home');
                    }}>
                        Vedi tutto &gt;&gt;
                    </Typography>
                </Grid>
                <ListCouponInWallet />
                <SendCoupons />
            </Grid>
        </>
    )
}

const SendCoupons = () => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const [contacts, setContacts] = useState<UserData[]>([]);
    const [contactsFiltered, setContactsFiltered] = useState<UserData[]>([]);
    const [contact, setContact] = useState<UserData | null>(null);
    const [coupons, setCoupons] = useState<any[]>([]);
    const [couponsFiltered, setCouponsFiltered] = useState<any[]>([]);
    const [coupon, setCoupon] = useState<any>();

    const account = useAppSelector(state => state.user.currentProfile);
    const loading = useAppSelector(state => state.coin.coinSendLoading);
    const [qnt, setQnt] = useState(0);
    const dispatch = useAppDispatch();

    useEffect(() => {
        getAllRecentContacts();
        getMyCoupons();
    }, []);

    useEffect(() => {
        if (loading) {
            toast.loading('Invio in corso...');
        } else {
            toast.dismiss();
        }
    }, [loading]);

    const getAllRecentContacts = async () => {
        const users = await getMostRecentUsers(10, null)
        let recentContacts: UserData[] = [];
        if (!users) return;
        for (let i = 0; i < users.length; i++) {
            const address = users[i];
            const contact = await addContactToRecentContacts(address);
            if (contact) {
                recentContacts.push(contact);
            }
        }
        setContactsFiltered(recentContacts);
        setContacts(recentContacts);
    }

    const getMyCoupons = async () => {
        const address = account?.additional_properties?.commonshoodWallet;
        if (address != null) {
            const tokens = await getTokensOwnedFromMetadata(
                address,
                'goods',
                1,
                true
            );
            setCoupons(tokens);
            setCouponsFiltered(tokens);
        }
    }

    const addContactToRecentContacts = async (address: string): Promise<UserData | undefined> => {
        const user = await getUserNameByAddress(address)
        if (user) {
            const { profilePicture, first_name, email, last_name } = user;
            return ({
                id: address,
                name: first_name,
                profilePicture: profilePicture,
                email: email,
                realm: 'user',
                last_name: last_name
            });
        }
        return undefined;
    }

    //Allow user to search for a contact by name or email
    const onContactSearch = (e: any) => {
        const isWritingEmail = e.target.value.includes('@');
        isWritingEmail && filterContactsByEmail(e.target.value);
        !isWritingEmail && filterContactsByName(e.target.value);
    }

    const onCouponSearch = (e: any) => {
        const filtered = coupons.filter((coupon) => {
            return coupon.name.includes(e.target.value);
        });
        setCouponsFiltered(filtered);
    }

    const filterContactsByEmail = (email: string) => {
        const filtered = contacts.filter((contact) => {
            return contact.email.includes(email);
        });
        setContactsFiltered(filtered);
    }

    const sendCoins = async () => {
        if (qnt <= 0) {
            toast.error('Inserisci una quantità positiva');
            return;
        }
        if (!contact) {
            toast.error('Seleziona un contatto');
            return;
        }
        if (!coupon) {
            toast.error('Seleziona un coupon');
            return;
        }
        if (qnt > coupon.balance.balance) {
            toast.error('Non hai abbastanza coupon');
            return;
        }
        await dispatch(coinSend({
            recipient: {
                additional_properties: {
                    commonshoodWallet: `${contact.additional_properties?.commonshoodWallet}`
                },
                email: contact.email,
                id: '',
                name: contact.name,
                profilePicture: contact.profilePicture,
                realm: 'user'
            },
            amount: qnt.toString(),
            symbol: coupon.symbol,
            decimals: 0
        }))
        toast.success('Coupon inviati con successo');
    }

    const filterContactsByName = (name: string) => {
        const filtered = contacts.filter((contact) => {
            return contact.name.includes(name);
        });
        setContactsFiltered(filtered);
    }

    const onClickContact = (contact: UserData) => {
        setContact(contact);
        setContactsFiltered([contact]);
    }

    const onClickCoupon = (coupon: any) => {
        setCoupon(coupon);
        setCouponsFiltered([coupon]);
    }

    return (
        <>
            <Grid item container xs={12} style={{ marginTop: '4em' }}>
                <Grid item xs={12} sm={2} style={{ display: 'flex', marginTop: '20px', position: 'relative' }}>
                    <Box textAlign={'right'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                        <Typography variant='h6'>
                            Scegli il destinatario
                        </Typography>
                    </Box>
                </Grid>
                <Grid item sm={1}></Grid>
                <Grid item xs={12} sm={9} style={{ display: 'flex', alignItems: 'left', justifyContent: 'left', marginBottom: '2em' }}>
                    <Grid container>
                        <ListContactCoso setContactFallback={onClickContact} />
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={2} style={{ display: 'flex' }}>
                    <Box textAlign={'right'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                        <Typography variant='h6'>
                            Quale coupon vuoi inviare?
                        </Typography>
                    </Box>
                </Grid>
                <Grid item sm={1}></Grid>
                <Grid item xs={12} sm={9} style={{ display: 'flex', alignItems: 'left', justifyContent: 'left', marginBottom: '32px' }}>
                    <Grid container>
                        <Grid item xs={12}>
                            <SearchBar text={'Ricerca coupon...'} onChange={onCouponSearch} />
                        </Grid>
                        <Grid item xs={12}>
                            <CouponList coupons={couponsFiltered} onClick={onClickCoupon} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Box textAlign={'right'} display={'flex'} alignItems={'center'} justifyContent={'center'} height="100%">
                        <Typography variant='h6'>
                            Quantità da inviare
                        </Typography>
                    </Box>
                </Grid>
                <Grid item sm={1}></Grid>
                <Grid item xs={12} sm={9} style={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }}>
                    <Grid container>
                        <Grid item xs={2}>
                            <InputField
                                type={'number'}
                                value={qnt.toString()}
                                placeholder='_ _ _'
                                variant='outlined'
                                onChange={(e) => {
                                    setQnt(parseInt(e.target.value));
                                }}
                                style={{ textAlign: 'center' }}
                                min={1}
                                max={999}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} style={{ marginTop: '32px' }}>
                    <Box textAlign={'center'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                        <BlackButton disabled={loading} width={243} onClick={sendCoins} text={'Conferma'} />
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

const useStylesSearchBar = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            border: '1px solid rgba(24, 27, 29, 1)',
            borderRadius: '10px',
            minWidth: 300,
            background: 'white',
            padding: 4,
            '@media (max-width: 600px)': {
                width: '90%',
            }
        },
        searchBarContainer: {
            '@media (max-width: 600px)': {
                justifyContent: "center"
            }
        }
    }),
);

const useStyles = makeStyles({
    couponItem: {
        display: 'inline-block',
        marginRight: '1em', // Adjust spacing between items as needed
    },
    couponContainer: {
        maxWidth: '100%',
        height: 'auto',
        marginBottom: '16px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    couponsListContainer: {
        overflowX: 'auto',  // Allow horizontal scrolling
        whiteSpace: 'nowrap',  // Prevent wrapping of items
        marginTop: '2em',
        width: '100%',
    },
});


export const ContactList = ({ contacts, onClick }: {
    contacts: UserData[],
    onClick: (contact: UserData) => void
}) => {
    const classes = useStyles();

    const getAvatarInitials = (contact: UserData) => {
        if (contact.profilePicture) {
            return '';
        } else {
            if(contact.name) {
                return contact.name.slice(0, 2);
            }
            if ((contact as any).first_name && (contact as any).last_name) {
                return (contact as any).first_name[0].concat((contact as any).last_name[0]);
            } else {
                return '??';
            }
        }
    }

    const getAvatarName = (contact: UserData) => {
        if (contact.name) {
            return contact.name;
        }
        if ((contact as any).first_name) {
            return (contact as any).first_name;
        }
    }

    const [selectedContact, setSelectedContact] = useState<any>({});


    const onContactClickHandler = (contact: any) => {
        setSelectedContact(contact);
        onClick(contact);
    }

    return (
        <>
            <div className={classes.couponsListContainer}>
                {
                    contacts.length === 0 && (
                        <Skeleton
                            variant="rect"
                            width={'100%'}
                            height={90}
                        />
                    )
                }
                {contacts.map(contact => (
                    <div key={contact.id} className={classes.couponItem} >
                        <div className={classes.couponContainer} style={{ marginLeft: '16px', cursor: 'pointer' }} onClick={() => {
                            onContactClickHandler(contact);
                        }}>
                            {
                                contact.profilePicture ? (
                                    <CouponLogo logo={contact.profilePicture} selected={selectedContact.id === contact.id} />
                                ) : (
                                    <Avatar
                                        style={{ backgroundColor: 'rgba(235, 176, 76, 1)',
                                        border: selectedContact.id === contact.id ? '2px solid black' : 'unset' }}
                                    >{getAvatarInitials(contact)}</Avatar>
                                )
                            }
                            <Typography variant='subtitle2'>
                                {getAvatarName(contact)}
                            </Typography>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}

const CouponList = ({ coupons, onClick }: {
    coupons: any[],
    onClick: (coupon: any) => void
}) => {
    const classes = useStyles();
    const [selectedCoupon, setSelectedCoupon] = useState<any>({});


    const onCouponClickHandler = (coupon: any) => {
        setSelectedCoupon(coupon);
        onClick(coupon);
    }

    return (
        <>
            <div className={classes.couponsListContainer}>
                {coupons.map(coupon => (
                    <div key={coupon._id} className={classes.couponItem} >
                        <div className={classes.couponContainer} style={{ marginLeft: '16px', cursor: 'pointer' }} onClick={() => {
                            onCouponClickHandler(coupon);
                        }}>
                            <CouponLogo logo={coupon.logo.replace(config.network.ipfs.default_url, config.network.ipfs.gateway_url)} selected={selectedCoupon._id === coupon._id} />
                            <Typography variant='subtitle1' >
                                {coupon.name}
                            </Typography>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}

export const SearchBar = ({ onChange, text }: {
    onChange?: (e: any) => void,
    text: string
}) => {
    const classes = useStylesSearchBar();

    return (
        <Grid container item xs={12} sm={3} className={useStylesSearchBar().searchBarContainer}>
            <FormControl className={classes.formControl} >
                <Input
                    style={{ zIndex: 10 }}
                    inputProps={{ disableUnderline: true }}
                    onChange={onChange}
                    placeholder={text}
                    disableUnderline={true}
                    id="input-with-icon-adornment"
                    endAdornment={
                        <InputAdornment position="start">
                            <SearchIcon
                                style={{ cursor: 'pointer' }}
                            />
                        </InputAdornment>
                    }
                />
            </FormControl>
        </Grid>
    )
}
import { Grid, Typography, makeStyles } from "@material-ui/core";

import FakeCTA from "../../../../assets/img/tutorial/fake-cta.svg"
import React, { FC, ReactElement } from 'react';
import MobileTutorialProgress, { TMobileTutorialProgressStepNumber } from "../MobileProgress";

interface MobileTab2Props {
    setStep: React.Dispatch<React.SetStateAction<TMobileTutorialProgressStepNumber>>;
}


const styles = makeStyles({
    tutorialPageTitle: {
        fontFamily: 'Be Vietnam Pro',
        fontSize: '30px',
        fontWeight: 600,
        lineHeight: '38px',
        letterSpacing: '0em',
        textAlign: 'center',
    },
    tutorialText: {
        fontFamily: 'Be Vietnam Pro',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '0em',
        textAlign: 'center',
    },
    miniText: {
        fontFamily: 'Be Vietnam Pro',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '15px',
        letterSpacing: '0em',
        textAlign: 'center',
    },
    tutorialCTA: {
        fontFamily: 'Be Vietnam Pro',
        fontSize: '20px',
        fontWeight: 600,
        lineHeight: '25px',
        letterSpacing: '0em',
        color: 'rgba(255, 138, 0, 1)',
        textAlign: 'right'
    }
});


export const MobileTab2: FC<MobileTab2Props> = ({ setStep }): ReactElement => {
    const style = styles();

    return (
        <>
            <Typography className={style.tutorialPageTitle}>Cosa vuoi fare per prima cosa su COSO</Typography>
            <Grid container direction="column" justifyContent="space-around">
                <Grid item container direction="row" spacing={4} justifyContent="center">
                    <Grid item style={{ flexGrow: 1 }}>
                        <a onClick={() => setStep(3)} style={{ cursor: 'pointer' }}>
                            <Typography className={style.tutorialCTA}>Mettere<br />in prestito qualcosa</Typography>
                        </a>
                    </Grid>
                    <Grid item style={{ margin: 'auto 0' }}>
                        <img src={FakeCTA} onClick={() => setStep(3)} style={{ cursor: 'pointer' }} />
                    </Grid>
                </Grid>
                <Grid item container direction="row" spacing={4} justifyContent="center">
                    <Grid item style={{ flexGrow: 1 }}>
                        <a onClick={() => setStep(6)} style={{ cursor: 'pointer' }}>
                            <Typography className={style.tutorialCTA}>Chiedere<br />in prestito qualcosa</Typography>
                        </a>
                    </Grid>
                    <Grid item style={{ margin: 'auto 0' }}>
                        <img src={FakeCTA} onClick={() => setStep(6)} style={{ cursor: 'pointer' }} />
                    </Grid>
                </Grid>
            </Grid >
            <Typography className={style.miniText} style={{ paddingTop: '4rem' }}>
                Puoi rivedere l’intero regolamento in qualsiasi momento tramite il tasto “Guida”
            </Typography>
        </>
    );
}
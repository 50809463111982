import { Grid, Typography, makeStyles, useMediaQuery } from "@material-ui/core"
import { useContext, useEffect, useState } from "react"
import { Item } from "../../../../../types/libofthings.type"
import { getItemInfoByID } from "../../../../../api/libOfThingsAPI"
import { Preorder, PreorderStatus, PreorderStatusStrings } from "../../../../../types/calendar.type"
import { acceptPreorderRequest, denyPreorderRequest } from "../../../../../store/slices/calendarSlice"
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks"
import toast from "react-hot-toast"
import { ItemImage } from "../../MyItems/ItemImage"
import PreorderStepper from "../../../MyRequests/Steppers/PreorderStepper"
import { useTranslation } from "react-i18next"
import { BlackButton } from "../../../../../components/Buttons/BlackButton"
import { TransparentButton } from "../../../../../components/Buttons/TransparentButton"
import { CosoSeparator } from "../../../BasicComponents/CosoSeparator"
import { FirstLifeContextImpl } from "../../../../../../src/providers/FirstLifeProvider"
import { Skeleton } from "@material-ui/lab"
import { SortBoolAscending } from "mdi-material-ui"

const useStyles = makeStyles({
    preorder: {
        background: 'white',
        width: '100%',
        borderRadius: '18px',
    },
    centerOnlyInMobile: {
        ['@media (max-width:600px)']: {
            textAlign: 'center',
        }
    },
    acceptButton: {
        ['@media (max-width:600px)']: {
            height: '16px',
        }
    },
    requestDateContainer: {
        ['@media (max-width:600px)']: {
            textAlign: 'center',
        },
        ['@media (min-width:600px)']: {
            textAlign: 'right',
        }
    },
    hideInMobile: {
        ['@media (max-width:600px)']: {
            display: 'none',
        }
    },
    displayOnlyInMobile: {
        ['@media (min-width:600px)']: {
            display: 'none',
        }
    }
})
export const PreorderPendingInList = ({ preorder, callback, textUnderStepper }: {
    preorder: Preorder,
    callback: any,
    textUnderStepper: string
}) => {
    const [item, setItem] = useState<Item>();
    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
    const [requestDate, setRequestDate] = useState<string>('');
    const [status, setStatus] = useState<PreorderStatus>(PreorderStatus.Pending);
    const dispatch = useAppDispatch();
    const useMobileQuery = useMediaQuery('(max-width: 600px)');
    const styles = useStyles();
    const { t } = useTranslation('LibOfThings');
    const { getUserByAddress } = useContext(FirstLifeContextImpl);
    const [borrowerEmail, setBorrowerEmail] = useState<string | undefined>();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async () => {
            const item = await getItemInfoByID(preorder.itemID);
            setItem(item);
        })()
    }, [])

    useEffect(() => {
        if (preorder != null) {
            const currentLocale = navigator.language;
            const dateOptions: Intl.DateTimeFormatOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
            const startDate = new Date(preorder.startDate * 1000);
            const endDate = new Date(preorder.endDate * 1000);
            const requestDate = new Date(preorder.timestamp * 1000);

            setStartDate(startDate.toLocaleString(currentLocale, dateOptions));
            setEndDate(endDate.toLocaleString(currentLocale, dateOptions));
            setRequestDate(requestDate.toLocaleString(currentLocale, dateOptions));
            handleStatus(preorder.status);
            (async () => {
                const { email } = await getUserByAddress(preorder.borrower);
                setBorrowerEmail(email);
            })()
        }
    }, [preorder])

    const handleStatus = (status: string) => {
        switch (status) {
            case 'Pending':
                setStatus(PreorderStatus.Pending);
                break;
            case 'Accepted':
                setStatus(PreorderStatus.Accepted);
                break;
            case 'Started':
                setStatus(PreorderStatus.Started);
                break;
            case 'Ended':
                setStatus(PreorderStatus.Ended);
                break;
            default:
                break;
        }
    }

    const acceptPreorder = async () => {
        if (preorder.endDate < Math.floor(Date.now() / 1000)) {
            toast.error('La data di terminazione del prestito è già passata. Non puoi accettare il prestito.');
            return;
        }
        try {
            setLoading(true);
            await dispatch(acceptPreorderRequest(preorder.preorderID));
            callback();
        } catch (error) {
            toast.error('Qualcosa è andato storto. Stai richiedendo un prestito oltre la data di terminazione?');
        } finally {
            setLoading(false);
        }
    }

    const denyPreorder = async () => {
        try {
            setLoading(true);
            await toast.promise(dispatch(denyPreorderRequest(preorder.preorderID)), {
                loading: 'Rifiuto il prestito',
                success: 'Prestito rifiutato',
                error: 'Qualcosa è andato storto'
            })
            callback();
        } catch (e: any) {
            console.log(e.message);
        } finally {
            setLoading(false);
        }
    }

    return (
        <Grid container className={styles.preorder} spacing={1} >
            {
                (preorder != null && item != null && startDate != null) && (
                    <>
                        <Grid item xs={4} sm={2} style={{ paddingLeft: '20px' }}>
                            <ItemImage
                                collectionSymbol={item.collectionSymbol}
                                id={item.idInCollection}
                                option={undefined}
                                respectRatio={true}
                                style={{ display: 'block', margin: 'auto' }}
                            />

                            <Grid container>
                                <Grid item xs={12} style={{ textAlign: 'center' }}>
                                    <Typography variant="body1" style={{ fontWeight: 'bold' }}>{item.name}</Typography>
                                </Grid>
                                <Grid item xs={12} style={{ textAlign: 'center' }}>
                                    <Typography variant="body1" style={{ fontWeight: 'bold', color: 'rgba(218, 93, 62, 1)' }}>{item.category[0].concat(item.category.slice(1).toLowerCase())}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={1} className={styles.hideInMobile} style={{
                        }}>
                            <img height={'90%'} src="/svgs/orange_line_desktop.svg" className={styles.hideInMobile} style={{ display: 'block', margin: 'auto' }} />
                        </Grid>
                        <Grid item xs={8} sm={9}>
                            <Grid container spacing={1} style={{ marginTop: '1em' }}>
                                <Grid item xs={12} sm={6} className={styles.centerOnlyInMobile}>
                                    <Typography variant="body1" style={{ fontWeight: 'bold' }}>{t('requestNoun')} {preorder.preorderID.slice(0, 3).concat(preorder.preorderID.slice(-4)).toLocaleUpperCase()}</Typography>
                                </Grid>
                                <Grid item xs={12} className={styles.centerOnlyInMobile}>
                                    {
                                        (useMobileQuery) ? (
                                            <Typography variant="body1" >Richiesto il <strong>{requestDate}</strong><br />per il periodo dal<br /> <strong>{startDate}</strong> al <strong>{endDate}</strong></Typography>
                                        ) : (
                                            <Typography variant="body1" >Richiesto il <strong>{requestDate}</strong> per il periodo dal <strong>{startDate}</strong> al <strong>{endDate}</strong></Typography>
                                        )
                                    }
                                </Grid>
                                <Grid item xs={12} md={7} className={styles.centerOnlyInMobile} style={{
                                    marginTop: '1em'
                                }}>
                                    {
                                        (useMobileQuery) ? (<>
                                            <></>
                                        </>) : (<>
                                            <div>
                                                <PreorderStepper preorder={preorder} lender={true} />
                                            </div>
                                        </>)
                                    }
                                </Grid>
                                {
                                    preorder.status === PreorderStatusStrings[0] && (
                                        <Grid item md={5} className={styles.hideInMobile} style={{ width: '100%' }}>
                                            <Grid container style={{ marginTop: '2em' }} direction="row" justifyContent="center" spacing={1}>
                                                <Grid item xs={12}>
                                                    <Typography align="center">{borrowerEmail ? `${borrowerEmail} ti ha mandato la richiesta` : ''}</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <BlackButton disabled={loading} onClick={acceptPreorder} text={t('accept')} style={{ width: '7.5rem', height: 'auto', color: 'white' }} />
                                                </Grid>
                                                <Grid item>
                                                    <TransparentButton disabled={loading} onClick={denyPreorder} text={t('deny')} style={{ width: '7.5rem', height: 'auto' }} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )
                                }
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={styles.displayOnlyInMobile}>
                            <CosoSeparator direction="Horizontal" style={{ width: '90%', margin: '20px auto' }} />
                        </Grid>
                    </>
                )
            }
            <Grid item xs={12} className={styles.displayOnlyInMobile} style={{ textAlign: 'center' }}>
                <PreorderStepper preorder={preorder} lender={true} />
            </Grid>
            <Grid item xs={12} className={styles.displayOnlyInMobile} style={{ textAlign: 'center', fontSize: '1rem', lineHeight: '1.25rem', fontWeight: 'bold', marginBottom: '1rem' }}>
                <Typography variant="body1" style={{ fontWeight: 'bold' }}>{textUnderStepper}</Typography>
            </Grid>
            {
                status === PreorderStatus.Pending && (
                    <Grid container style={{ marginBottom: '1em' }}>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={10} className={styles.displayOnlyInMobile} style={{ textAlign: 'center', marginBottom: '1em' }}>
                            <Typography variant="body1" style={{ fontWeight: 'bold' }}>{borrowerEmail} ti ha mandato la richiesta</Typography>
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={5} style={{ textAlign: 'center' }} className={styles.displayOnlyInMobile}>
                            <BlackButton disabled={loading} onClick={acceptPreorder} text={t('accept')} style={{ width: '7.5rem', height: 'auto', color: 'white' }} />
                        </Grid>
                        <Grid item xs={5} style={{ textAlign: 'center' }} className={styles.displayOnlyInMobile}>
                            <TransparentButton disabled={loading} onClick={denyPreorder} text={t('deny')} style={{ width: '7.5rem', height: 'auto' }} />
                        </Grid>
                        <Grid item xs={1}></Grid>
                    </Grid>
                )
            }
        </Grid >
    )
}

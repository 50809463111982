import { Box, Button, Divider, Grid, Menu, Typography } from "@material-ui/core"
import { makeStyles } from "@mui/styles";
import { useHistory } from "react-router-dom"
import { useAppSelector } from "../../../../store/hooks";


const styles = makeStyles({
    menu: {
        width: '100vw',
        '& .MuiPaper-root': {
            borderRadius: 20,
            border: 'rgba(218, 93, 62, 1)',
            borderWidth: 2,
            borderStyle: 'solid',
            minWidth: 350,
        }
    },
})

export const Profile = ({ anchorEl, id, open, onClose }: {
    anchorEl: EventTarget & Element | null,
    id: string,
    open: boolean,
    onClose: () => void,

}) => {
    const classes = styles();
    const history = useHistory();
    return (
        <Menu
            id={id}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={open}
            onClose={onClose}
            className={classes.menu}
        >
            <Box textAlign={'center'} margin={2}>
                <Typography variant={'h5'}>Il mio profilo</Typography>
            </Box>
            <CustomDivider backgroundColor='rgba(218, 93, 62, 1)' />
            <ProfileFirstSection />
            <CustomDivider backgroundColor='rgba(218, 93, 62, 1)' />
            <Box textAlign={'center'} margin={3}>
                <ProfileSecondSection />
            </Box>
            <Box textAlign={'center'} margin={3}>
                <Divider />
            </Box>
            <Box textAlign={'center'} margin={3}>
                <ProfileThirdSection />
            </Box>
            <Box textAlign={'center'} margin={1} >
                <Button style={{
                    color: 'rgba(218, 93, 62, 1)',
                    textTransform: 'none',
                    fontSize: 20
                }} onClick={() => {
                    history.push('/messages')
                }}>
                </Button>
            </Box>
        </Menu>
    )
}


const CustomDivider = ({ backgroundColor }: {
    backgroundColor: string
}) => {
    return (
        <Box textAlign={'center'} margin={2}>
            <Divider style={{
                backgroundColor: backgroundColor,
            }} />
        </Box>
    )
}


const ProfileFirstSection = () => {
    const profile = useAppSelector(state => state.user.currentProfile);
    return (
        <Box margin={2}>
            <Grid container>
                <Grid item xs={3}>
                    <img src="/svgs/profile_orange.svg" alt="Profile" width={60} height={60} />
                </Grid>
                <Grid item xs={9}>
                    <Typography variant={'h6'}>{profile?.name}</Typography>
                    <Typography variant={'subtitle1'} style={{
                        color: 'rgba(24, 27, 29, 0.6)'
                    }}>{profile?.email}</Typography>
                </Grid>
            </Grid>
        </Box>

    )
}

const ProfileSecondSection = () => {
    const history = useHistory();
    const openMyItems = () => {
        history.push('/user/items')
    }
    return (
        <Grid container style={{
            marginTop: '2em',
            cursor: 'pointer'
        }} onClick={openMyItems} >
            <Grid item xs={9} style={{
                textAlign: 'left'
            }}>
                <Typography variant={'h6'}>Metto in prestito</Typography>
            </Grid>
            <Grid item xs={3} style={{
                margin: 'auto'
            }}>
                <img src="/svgs/arrow_right_orange.svg" alt="arrow_right" />
            </Grid>
        </Grid>
    )
}

const ProfileThirdSection = () => {
    const history = useHistory();
    const openMyNotifications = () => {
        history.push('/messages')
    }
    const logout = () => {
        history.push('/logout')
    }
    return (
        <Grid container style={{
            marginTop: '2em',
            cursor: 'pointer'
        }} >
            <Grid item xs={9} style={{
                textAlign: 'left'
            }} onClick={openMyNotifications}>
                <Typography variant={'body1'}>Gestione delle mie notifiche</Typography>
            </Grid>
            <Grid item xs={3} style={{
                margin: 'auto'
            }}>
                <img src="/svgs/arrow_right_orange.svg" alt="arrow_right" />
            </Grid>
            <Grid item xs={12} style={{
                textAlign: 'left',
                marginTop: '1em'
            }} onClick={logout}>
                <Typography variant={'body1'}>Logout</Typography>
            </Grid>
        </Grid>
    )
}
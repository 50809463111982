import { Grid, Typography } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { MyRequestsPreorderInList } from "../PreorderInList/MyRequestsPreorderInList";
import { useContext, useEffect, useState } from "react";
import { useAppSelector } from "../../../../store/hooks";
import { getPendingPreordersAPI } from "../../../../api/calendar";
import { Preorder } from "../../../../types/calendar.type";
import { PaginationContainer } from "../../../../components/UI/Pagination/PaginationContainer";
import { NoItemsDisplay } from "../../BasicComponents/NoItemsDisplay";
import { CalendarContractContext } from "../../../../providers/CalendarContractProvider";

export const MyRequestsListOfPreorder = ({ shouldRefresh }: { shouldRefresh: boolean }) => {
    const account = useAppSelector(state => state.user.user?.additional_properties?.commonshoodWallet);
    const [preorders, setPreorders] = useState<Preorder[]>([]);
    const loading = useAppSelector(state => state.calendar.loading);
    const preordersPerPage = useAppSelector(state => state.calendar.preordersPerPage);
    const [numberOfPreorders, setNumberOfPreorders] = useState<number>(0);
    const [page, setPage] = useState(0);
    const user = useAppSelector(state => state.user.user);
    const { getUserStats } = useContext(CalendarContractContext);


    useEffect(() => {
        if (shouldRefresh) {
            (async () => {
                if (account) {
                    const preorders = await getPendingPreordersAPI(account, false, page, preordersPerPage);
                    if (preorders.preorders != null) {
                        setPreorders([...preorders.preorders]);
                        setNumberOfPreorders(preorders.totalPreorders);
                    }
                }
            })()
        }
    }, [shouldRefresh])

    useEffect(() => {
        (async () => {
            if (account) {
                const preorders = await getPendingPreordersAPI(account, false, page, preordersPerPage);
                if (preorders.preorders != null) {
                    setPreorders([...preorders.preorders]);
                    setNumberOfPreorders(preorders.totalPreorders);
                }
            }
        })()
    }, []);

    const handleClickOnPagination = async (selectedPage: number) => {
        await getPreordersFromMetadata(selectedPage);
    }

    const getPreordersFromMetadata = async (page: number) => {
        const address = user?.additional_properties?.commonshoodWallet;
        if (address) {
            const activePreorders = await getPendingPreordersAPI(
                address,
                false,
                page * preordersPerPage,
                preordersPerPage
            );
            setPreorders(activePreorders.preorders);
            setNumberOfPreorders(activePreorders.totalPreorders);
        }
    }
    const getTextForStepper = (preorder: Preorder) => {
        switch (preorder.status) {
            case 'Accepted':
                return 'Accettata';
            case 'Started':
                return 'Iniziata';
            case 'Completed':
                return 'Completata';
            default:
                return '';
        }
    }

    if (preorders.length > 0) {
        return (
            <Grid container style={{ position: 'relative', top: '3em' }} spacing={4}>
                {
                    preorders.map((p) => {
                        return <Grid key={p.preorderID} item xs={12}>
                            <MyRequestsPreorderInList
                                preorder={p}
                                textUnderStepper={getTextForStepper(p)}
                                callback={async () => {
                                    await getPreordersFromMetadata(0);
                                    await getUserStats();
                                }}
                            />
                        </Grid>
                    })
                }
                <Grid item xs={12} style={{ marginBottom: '15em' }}>
                    <PaginationContainer pageCount={Math.ceil(numberOfPreorders / preordersPerPage)} onChange={handleClickOnPagination} />
                </Grid>
            </Grid>

        )
    } else {
        return (
            <NoItemsDisplay style={{ marginTop: '3em' }} />
        )
    }
}
import messageKeys from './messageKeys';
import { assetIntegerToDecimalRepresentation } from '../decimalsHandler/decimalsHandler';
import { logger } from '../logger/logger';
import { TFunction } from 'i18next';
import { CoinList, NotificationParameters } from '../../api/notificationAPI';
import notificationKeys from './messageKeys';

const getNotificationText = (
    messageKey: string | Promise<string> | void,
    params: NotificationParameters,
    t: TFunction,
    commonshoodWallet: string
) => {
    let notificationText = '';
    let amountConverted = '';
    let actualCoinAccepted = '';
    let actualCoinOffered = '';
    if (params == null) {
        throw new Error('This notification received no params, check the caller');
    }

    if (messageKey === notificationKeys.EXCHANGE_ADDED) {
        console.log(params);
    }

    // Raggruppo i casi in base al valore di `messageKey`
    switch (messageKey) {
        case messageKeys.COIN_CREATED:
            if (!params.symbol || !params.from || params.decimals == null) {
                throw new Error('missing a required parameter for ' + messageKey);
            }
            if (params.amount) {
                amountConverted = assetIntegerToDecimalRepresentation(params.amount, params.decimals);
            }
            notificationText = `Monete con simbolo ${params.symbol} create`;
            break;

        case messageKeys.COIN_SENT:
            if (
                !params.symbol ||
                params.amount == null ||
                !params.sender ||
                !params.receiver ||
                params.decimals == null ||
                !params.to_name ||
                !params.to_realm ||
                !params.from_realm ||
                !params.from_name
            ) {
                throw new Error('missing a required parameter for ' + messageKey);
            }
            amountConverted = assetIntegerToDecimalRepresentation(params.amount, params.decimals);


            if (params.to_realm === 'user') {
                notificationText = t('NotificationMessages:coinSent', {
                    params: {
                        ...params,
                        amount: amountConverted,
                    },
                });
            } else if (params.to_realm === 'unknown') {
                notificationText = t('NotificationMessages:coinSentToUnknown', {
                    params: {
                        ...params,
                        amount: amountConverted,
                    },
                });
            } else if (params.to_realm === 'ItemMarketplace') {
                notificationText = t('NotificationMessages:coinSentToItemMarketplace', {
                    params: {
                        ...params,
                        amount: amountConverted,
                    },
                });
            } else if (params.to_realm === "exchange") {
                const amountWorkaround = params.symbol.toUpperCase() === "COSO" ? params.amount / 100 : params.amount;
                notificationText = `${amountWorkaround} ${params.symbol} ${t('mandati ad un exchange')}`;
            } else if (params.from_realm === "exchange") {
                if (params.to_realm === "dao") {
                    notificationText = t('NotificationMessages:daoCoinReceivedByExchange', {
                        params: {
                            ...params,
                            amount: amountConverted,
                        }
                    });
                }
                else if (params.to_realm === "user") {
                    notificationText = t('NotificationMessages:coinReceivedByExchange', {
                        params: {
                            ...params,
                            amount: amountConverted,
                        }
                    });
                }
                else if (params.to_realm === "unknown") {
                    notificationText = t('NotificationMessages:coinReceivedFromExchangeToUnknown', {
                        params: {
                            ...params,
                            amount: amountConverted,
                        }
                    });
                }
                else if (params.to_realm === 'ItemMarketplace') {
                    notificationText = t('NotificationMessages:coinReceivedFromItemMarketplace', {
                        params: {
                            ...params,
                            amount: amountConverted
                        }
                    })
                }
                else {
                    logger.info(params)
                    throw new Error('no realm matching for receiver: ' + messageKey + ' ' + params.to_realm);
                }
            }
            break;
        case messageKeys.COIN_RECEIVED:
            if (
                !params.symbol ||
                params.amount == null ||
                !params.sender ||
                !params.receiver ||
                params.decimals == null ||
                !params.to_name ||
                !params.to_realm ||
                !params.from_realm ||
                !params.from_name
            ) {
                throw new Error('missing a required parameter for ' + messageKey);
            }
            amountConverted = assetIntegerToDecimalRepresentation(params.amount, params.decimals);

            if (params.from_realm === 'unknown') {
                if (params.to_realm === 'user') {
                    notificationText = t('NotificationMessages:coinReceivedFromUnknown', {
                        params: {
                            ...params,
                            amount: amountConverted,
                        },
                    });
                } else if (params.to_realm === 'unknown') {
                    notificationText = t('NotificationMessages:coinReceivedFromUnknownToUnknown', {
                        params: {
                            ...params,
                            amount: amountConverted,
                        },
                    });
                } else if (params.to_realm === 'ItemMarketplace') {
                    notificationText = t('NotificationMessages:coinReceivedFromItemMarketplace', {
                        params: {
                            ...params,
                            amount: amountConverted,
                        },
                    });
                } else {
                    throw new Error('no realm matching in: ' + messageKey + ' ' + params.to_realm);
                }
            } else if (params.from_realm === 'user') {
                if (params.to_realm === 'user') {
                    notificationText = t('NotificationMessages:coinReceived', {
                        params: {
                            ...params,
                            amount: amountConverted,
                        },
                    });
                } else if (params.to_realm === 'unknown') {
                    notificationText = t('NotificationMessages:coinReceivedFromUserToUnknown', {
                        params: {
                            ...params,
                            amount: amountConverted,
                        },
                    });
                } else if (params.to_realm === 'ItemMarketplace') {
                    notificationText = t('NotificationMessages:coinReceivedFromItemMarketplace', {
                        params: {
                            ...params,
                            amount: amountConverted,
                        },
                    });
                } else {
                    logger.info(params);
                    throw new Error('no realm matching for receiver: ' + messageKey + ' ' + params.to_realm);
                }
            } else if (params.from_realm === 'ItemMarketplace') {
                if (params.to_realm === 'user') {
                    notificationText = t('NotificationMessages:coinReceived', {
                        params: {
                            ...params,
                            amount: amountConverted,
                        },
                    });
                    notificationText = notificationText.replace('da Library of things', "dalla Biblioteca delle Cose");
                } else if (params.to_realm === 'unknown') {
                    notificationText = t('NotificationMessages:coinReceivedFromItemMarketplace', {
                        params: {
                            ...params,
                            amount: amountConverted,
                        },
                    });
                } else if (params.to_realm === 'ItemMarketplace') {
                    notificationText = t('NotificationMessages:coinReceivedFromItemMarketplace', {
                        params: {
                            ...params,
                            amount: amountConverted,
                        },
                    });
                } else {
                    logger.info(params);
                    throw new Error('no realm matching for receiver: ' + messageKey + ' ' + params.to_realm);
                }
            } else {
                throw new Error('no realm matching for sender: ' + messageKey + ' ' + params.from_realm);
            }
            break;

        case messageKeys.COIN_MINTED:
            if (!params.symbol || params.amount == null || params.decimals == null) {
                throw new Error('missing a required parameter for ' + messageKey);
            }
            amountConverted = assetIntegerToDecimalRepresentation(params.amount, params.decimals);
            notificationText = t('NotificationMessages:coinMinted', {
                params: {
                    ...params,
                    amount: amountConverted,
                },
            });
            break;

        case messageKeys.PREORDER_CREATED:
            if (params.borrowerFSName != null && params.itemName != null) {
                if (commonshoodWallet === params.lender) {
                    notificationText = t('preorderCreatedLender', { params });
                } else {
                    notificationText = t('preorderCreatedBorrower', { params });
                }
            }
            break;
        case messageKeys.PREORDER_ACCEPTED:
            // Consolido i casi PREORDER_CREATED e PREORDER_ACCEPTED
            if (params.borrowerFSName != null && params.itemName != null) {
                if (commonshoodWallet === params.lender) {
                    notificationText = t('preorderAcceptedLender', { params });
                } else if (commonshoodWallet === params.borrower) {
                    notificationText = t('preorderAcceptedBorrower', { params });
                }
            }
            break;
        case messageKeys.PREORDER_DENIED:
            if (commonshoodWallet === params.lender) {
                notificationText = t('preorderDeniedLender', { params });
            } else {
                notificationText = t('preorderDeniedBorrower', { params });
            }
            break;
        case messageKeys.PREORDER_STARTED:
            notificationText = t(`preorderStartedGenericMessage`, { params });
            break;
        case messageKeys.PREORDER_CANCELLED:
            if (params.borrowerFSName != null && params.itemName != null) {
                if (commonshoodWallet === params.lender) {
                    notificationText = t('preorderCancelledLender', { params });
                } else {
                    notificationText = t('preorderCancelledBorrower', { params });
                }
            }
            break;
        case messageKeys.PREORDER_ENDED:
            if (params.borrowerFSName != null && params.itemName != null) {
                if (commonshoodWallet === params.lender) {
                    notificationText = t('preorderEndedLender', { params });
                } else {
                    notificationText = t('preorderEndedBorrower', { params });
                }
            }
            break;
        case messageKeys.NFT_ADDED:
        case messageKeys.NFT_REMOVED:
            //do nothing
            break;
        case messageKeys.EXCHANGE_CANCELLED:
            if (!params.coinOffered || !params.coinAccepted) {
                throw new Error('missing a required parameter for ' + messageKey);
            }
            actualCoinAccepted = stringListGenerator(params.coinAccepted)
            actualCoinOffered = stringListGenerator(params.coinOffered)
            notificationText = t('NotificationMessages:exchangeCancelled', {
                params: {
                    ...params,
                    coinAccepted: actualCoinAccepted,
                    coinOffered: actualCoinOffered
                }
            });
            break;

        case messageKeys.EXCHANGE_COMPLETED:
            if (!params.coinOffered || !params.coinAccepted) {
                throw new Error('missing a required parameter for ' + messageKey);
            }
            actualCoinAccepted = stringListGenerator(params.coinAccepted)
            actualCoinOffered = stringListGenerator(params.coinOffered)
            notificationText = t('NotificationMessages:exchangeCompleted', {
                params: {
                    ...params,
                    coinAccepted: actualCoinAccepted,
                    coinOffered: actualCoinOffered
                }
            });
            break;

        case messageKeys.EXCHANGE_RENEWED:
            if (!params.coinOffered || !params.coinAccepted) {
                throw new Error('missing a required parameter for ' + messageKey);
            }
            actualCoinAccepted = stringListGenerator(params.coinAccepted)
            actualCoinOffered = stringListGenerator(params.coinOffered)
            notificationText = t('NotificationMessages:exchangeRenewed', {
                params: {
                    ...params,
                    coinAccepted: actualCoinAccepted,
                    coinOffered: actualCoinOffered
                }
            });
            break;
        case messageKeys.EXCHANGE_ADDED:
            if (!params.coinOffered || !params.coinAccepted) {
                throw new Error('missing a required parameter for ' + messageKey);
            }
            actualCoinAccepted = stringListGenerator(params.coinAccepted)
            actualCoinOffered = stringListGenerator(params.coinOffered)
            notificationText = `Exchange creato con successo. ${actualCoinAccepted} accettate in cambio di ${actualCoinOffered}`;
            break;
        default:
            notificationText = 'Something went wrong - unrecognized messageKey for notification';
    }

    return notificationText;
};


const stringListGenerator = (coinList: CoinList[]) => {
    let actualCoinList: string = ''
    if (coinList.length === 1) {
        actualCoinList += assetIntegerToDecimalRepresentation(coinList[0].amount, coinList[0].decimals) + " " + coinList[0].symbol
    } else {
        for (let i = 0; i < coinList.length; i++) {
            if (i === coinList.length - 1) {
                actualCoinList += assetIntegerToDecimalRepresentation(coinList[i].amount, coinList[i].decimals) + " " + coinList[i].symbol
            }
            else {
                actualCoinList += assetIntegerToDecimalRepresentation(coinList[i].amount, coinList[i].decimals) + " " + coinList[i].symbol + ", "
            }
        }
    }
    return actualCoinList;
}


export default getNotificationText;
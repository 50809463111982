import { Typography, makeStyles, Grid, CircularProgress, IconButton, Button } from "@material-ui/core";
import { ExchangeEntryCard, IExchangeEntryCardInfo } from "../../LibOfThings/Exchange/ExchangeEntryCard";
import ExchangeSummaryDialog, { IExchangeSummaryData } from "../ExchangeSummaryDialog";
import { useEffect, useState } from "react";
import { IExchangeTokenEntry } from "../../LibOfThings/Exchange/ExchangeTokenEntry";
import { getAcceptableExchanges } from "../../../api/exchangeAPI";
import { useAppSelector } from "../../../store/hooks";
import { UserNameType, getUserNameByAddress } from "../../../api/userAPI";

import RotateLeftIcon from "@material-ui/icons/RotateLeft";
const useStyle = makeStyles((theme) => ({
    title: {
        color: '#18181D',
        fontFamily: 'Be Vietnam Pro',
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
        [theme.breakpoints.down('md')]: {
            marginLeft: '1.25rem',
        }
    },
    exchangeCard: {
        width: 243,
        height: 282,
        backgroundColor: '#FFF',
        borderRadius: 19,
        position: 'relative',
        padding: '24px 0',
        color: '#18181d',
        fontFamily: 'Be Vietnam Pro',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal'
    },
    exchangeCardBadge: {
        width: '92.377px',
        height: 20,
        borderRadius: 10,
        backgroundColor: '#DA5D3E',
        color: '#FFF',
        textAlign: 'center',
        fontFamily: 'Be Vietnam Pro',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '18px',
        position: 'absolute',
        top: '-3%',
        left: '50%',
        transform: 'translateX(-50%)',
    },
    exchangeCardExpiryText: {
        color: '#181B1D',
        textAlign: 'center',
        fontFamily: 'Be Vietnam Pro',
        fontSize: '10px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '16px',
    },
    exchangeCardEmailText: {
        color: '#DA5d3e',
        textAlign: 'center',
        fontFamily: 'Be Vietnam Pro',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '18px'
    },
    exchangeDefaultText: {
        color: '#18181d',
        fontFamily: 'Be Vietnam Pro',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal'
    },
    cardSpacing: {
        '& > *': {
            marginLeft: '1.25rem',
            marginTop: '1.25rem'
        },
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center',
            marginLeft: '0'
        }
    },
    button: {
        borderRadius: 10,
    }
}))

const emptyAcceptedExchangeEntry = {
    exchangeInfo: {
        fromUserEmail: '',
        offeredTokens: [],
        desiredTokens: [],
        expiry: new Date(),
        maxExchangeAmount: 1,
        apiObject: {}
    },
    acceptedCount: 1
}

function adaptToObject(input: any, fromUsername?: UserNameType): IExchangeEntryCardInfo {
    const offeredTokens: IExchangeTokenEntry[] = input.coinsOfferedData.map((coin: any) => ({
        symbol: coin.symbol,
        amount: coin.amount,
        tokenImageSrc: coin.logoUrl
    }));

    const desiredTokens: IExchangeTokenEntry[] = input.coinsRequiredData.map((coin: any) => ({
        symbol: coin.symbol,
        amount: coin.amount,
        tokenImageSrc: coin.logoUrl
    }));



    const x = {
        fromUserEmail: fromUsername ? `${fromUsername.first_name} ${fromUsername.last_name}` : 'Unknown',
        offeredTokens,
        desiredTokens,
        expiry: new Date(input.expiration),
        maxExchangeAmount: input.repeatings,
        apiObject: input
    }
    return x;
}

export const ExchangeAllTab: React.FC = () => {
    const style = useStyle();
    const [exchanges, setExchanges] = useState<IExchangeEntryCardInfo[]>([]);
    const [summaryOpen, setSummaryOpen] = useState(false);
    const [summaryExchange, setSummaryExchange] = useState<IExchangeSummaryData>(emptyAcceptedExchangeEntry);
    const [loading, setLoading] = useState(false);
    const user = useAppSelector(state => state.user.currentProfile);
    const [page, setPage] = useState(1);

    const fetchExchanges = async () => {
        try {
            console.log('Fetching exchanges...');
            setLoading(true);
            const apiExchanges = await getAcceptableExchanges(user?.additional_properties?.commonshoodWallet!, page, 15);

            // Convert the retrieved array to match the interface
            const adaptedExchanges = await Promise.all(apiExchanges.map(async (e: any) => {
            const userName = await getUserNameByAddress(e.owner);
                return adaptToObject(e, userName);
            }));
            setPage(page+1);
            if (adaptedExchanges.length === 0) {
                setLoading(false);
                return;
            }
            setExchanges(exchanges=>[...exchanges, ...adaptedExchanges]);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching exchanges:', error);
        }
    };

    useEffect(() => {
        // Fetch exchanges from the API
        fetchExchanges();
    }, []); // Run once on component mount

    const exchangeAcceptedHandler = (e: IExchangeSummaryData, indexOf: number) => {
        setSummaryExchange(e);
        setSummaryOpen(true);
        // If the user accepted the entirety of the repeatings available for a given exchange, we delete its entry
        if (e.exchangeInfo.maxExchangeAmount === e.acceptedCount) {
            setExchanges(prevExchanges => prevExchanges.filter((_, index) => index !== indexOf));
        } else {
            setExchanges(prevExchanges => {
                const updatedExchanges = [...prevExchanges];
                updatedExchanges[indexOf].maxExchangeAmount -= e.acceptedCount;
                return updatedExchanges;
            });
        }
    };

    return (
        <>
            <Typography className={style.title} style={{ marginTop: '1.5rem', marginBottom: '34px' }}>Novit&agrave;</Typography>
            {
                loading && <WaitingForApi />
            }
            <Grid container direction="row" className={style.cardSpacing}>
                {exchanges.map((exchange, index) => (
                    <ExchangeEntryCard key={index} exchangeCardInfo={exchange} onExchangeAccepted={(e) => exchangeAcceptedHandler(e, index)} />
                ))}
                
            </Grid>
            <Grid container direction="row" style={{paddingBottom: '5rem', paddingTop:'1.25rem'}} justifyContent="center" alignItems="center" spacing={4}>
                
                <Button
                    variant="contained"
                    color="default"
                    className={style.button}
                    startIcon={<RotateLeftIcon />}
                    onClick={() => fetchExchanges()}
                >
                Carica altri...
                </Button>
            </Grid>
            
            <ExchangeSummaryDialog open={summaryOpen} setOpen={setSummaryOpen} exchangeData={summaryExchange} />
        </>
    );
};


const WaitingForApi = () => (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '200px' }}>
        <CircularProgress />
        <Typography variant="body1" style={{ marginTop: '1.25rem' }}>Caricamento</Typography>
    </div>
)
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { useAppDispatch } from "../../store/hooks";
import { CustomToaster } from "../Notifications/CustomToaster";
import { TutorialContext } from "../../providers/TutorialProvider";
import { CouponWallet } from "./Coupon/CouponWallet";
import { CosoInWallet } from "./COSO/CosoInWallet";
import AilinTabs from "../LibOfThings/BasicComponents/AilinTabs";
import { RealmContext } from "../../providers/RealmProvider";

export const WalletPage = () => {
    const [pageToShow, setPageToShow] = useState<number>(0);
    const { isCurrentRealmChGarden, isCurrentRealmCOSO } = useContext(RealmContext);
    const [labels, setLabels] = useState<string[]>([]);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        if (params.has('page')) 
            setPageToShow(1);
        if(isCurrentRealmCOSO)
            setLabels(['COSO'])
        if(isCurrentRealmChGarden)
            setLabels(['COSO', 'COUPON'])
    }, [])

    return (
        <>
            <CustomToaster />
            <Box height={'800px'}>
                <Grid container direction="row" spacing={1} style={{ padding: '10px' }}>
                    <Grid item xs={12}>
                        <Typography variant="h6">Il mio portafoglio</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <AilinTabs value={pageToShow} labels={labels} onChange={(_x, _val) => setPageToShow(_val)} />
                    </Grid>
                    {
                        isCurrentRealmChGarden && pageToShow === 1 && (
                            <CouponWallet />
                        )
                    }

                    {
                        (pageToShow === 0) && (
                            <CosoInWallet />
                        )}
                </Grid>
            </Box >
        </>
    );
}
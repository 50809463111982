import ReactDOM from 'react-dom';

// Redux
import { Provider } from 'react-redux';
import store from './store/store';

//React router
import { BrowserRouter } from 'react-router-dom';


//i18n - note this is all that's needed to use i18n in the whole app, no HOC, just magic
import './i18n';

//Fonts and icons
import WebFont from 'webfontloader';

// Main App
// import './index.css';
import App from './App';

// Main Config
import config from './config';

//Main MuiTheme
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import theme from './theme/theme'

//Date utilities for datepickers
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

//Snackbar Provider
import { SnackbarProvider } from 'notistack';
import './index.css';
import "./styles/react-paginate.css"
import { ERC721ContractProvider } from './providers/ERC721ContractProvider';
import { LibraryOfThingsContractProvider } from './providers/LibraryOfThingsContractProvider';
import { CalendarContractProvider } from './providers/CalendarContractProvider';
import { LanguageProvider } from './providers/LanguageProvider';
import { TutorialProvider } from './providers/TutorialProvider';
import { FirstLifeProvider } from './providers/FirstLifeProvider';
import { MailProvider } from './providers/MailProvider';
import { CouponProvider } from './providers/CouponProvider';
import { ExchangeFormContext, ExchangeFormProvider } from './providers/ExchangeFormCreationProvider';
import { ContactsProvider } from './providers/ContactsProvider';
import { RealmProvider } from './providers/RealmProvider';

// Load Material Fonts and FA icons
WebFont.load({
    google: {
        families: [
            'Roboto:300,500,700',
            'Roboto+Slab:400,700',
            'Material Icons'
        ]
    }
});

const app = (
    <Provider store={store}>
        <BrowserRouter>
            <LanguageProvider>
                <CalendarContractProvider>
                    <ERC721ContractProvider>
                        <FirstLifeProvider>
                            <LibraryOfThingsContractProvider>
                                <TutorialProvider>
                                    <MuiThemeProvider theme={theme}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <MailProvider>
                                                <CouponProvider>
                                                    <ExchangeFormProvider>
                                                        <ContactsProvider>
                                                            <RealmProvider>
                                                                <SnackbarProvider
                                                                    maxSnack={config.interface.snackbar.maxSnack}
                                                                    transitionDuration={config.interface.snackbar.transitionDuration}
                                                                >
                                                                    <App />
                                                                </SnackbarProvider>
                                                            </RealmProvider>
                                                        </ContactsProvider>
                                                    </ExchangeFormProvider>
                                                </CouponProvider>
                                            </MailProvider>
                                        </MuiPickersUtilsProvider>
                                    </MuiThemeProvider>
                                </TutorialProvider>
                            </LibraryOfThingsContractProvider>
                        </FirstLifeProvider>
                    </ERC721ContractProvider>
                </CalendarContractProvider>
            </LanguageProvider>
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

export interface IExchangeTokenEntry {
    symbol: string,
    amount: number,
    tokenImageSrc?: string
}
export const ExchangeTokenEntry: React.FC<IExchangeTokenEntry> = ({ symbol, amount, tokenImageSrc }) => {
    //For some reason COSO tokens are not properly divided, idk, just take this for good
    const amountWorkaround = symbol.toUpperCase() === "COSO" ? amount / 100 : amount;
    //Formats number to have 2 decimals & adds a dot as thousand separator
    const formattedAmount = amountWorkaround.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ","); 
    return (
        <>
            {tokenImageSrc ? (
                <img
                    src={tokenImageSrc}
                    alt={symbol}
                    style={{
                        width: '20px',
                        height: '20px',
                        borderRadius: '20px',
                        marginRight: '7px',
                    }}
                />
            ) : (
                <div
                    style={{
                        width: '20px',
                        height: '20px',
                        borderRadius: '20px',
                        backgroundColor: 'orange',
                        marginRight: '7px',
                    }}
                />
            )}
            <div>{symbol}</div>
            <div style={{ fontWeight: 'bolder', marginLeft: 'auto', marginRight: '1rem' }}>{formattedAmount}</div>
        </>
    );
}
import { useAppSelector } from "../../../../store/hooks";
import { useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { MyRequestsPreorderInList } from "../PreorderInList/MyRequestsPreorderInList";
import { getPreorders } from "../../../../api/calendar";
import { Preorder } from "../../../../types/calendar.type";
import { NoItemsDisplay } from "../../BasicComponents/NoItemsDisplay";

export const ListOfEndedPreorders = ({ shouldRefresh }: {
    shouldRefresh: boolean
}) => {
    const account = useAppSelector(state => state.user.user?.additional_properties?.commonshoodWallet);
    const [preorders, setPreorders] = useState<Preorder[]>([]);
    const loading = useAppSelector(state => state.calendar.loading);
    const preordersPerPage = useAppSelector(state => state.calendar.preordersPerPage);
    const [page, setPage] = useState(0);

    useEffect(() => {
        if (shouldRefresh) {
            (async () => {
                if (account) {
                    await getPreordersFromMetadata(page);
                }
            })()
        }
    }, [shouldRefresh])

    useEffect(() => {
        (async () => {
            if (account) {
                await getPreordersFromMetadata(page);
            }
        })()
    }, []);

    const getPreordersFromMetadata = async (page: number) => {
        const preorders = await getPreorders(account!, false, page, preordersPerPage, 'Ended');
        if (preorders.preorders != null) {
            setPreorders([...preorders.preorders]);
        }
    }

    if (preorders.length > 0) {
        return (
            <Grid container style={{ position: 'relative', top: '3em' }} spacing={4}>
                {
                    preorders.map((p) => {
                        return <Grid style={{ marginBottom: '100px' }} key={p.preorderID} item xs={12}>
                            <MyRequestsPreorderInList
                                preorder={p}
                                textUnderStepper="Completata"
                            />
                        </Grid>
                    })
                }
            </Grid>
        )
    } else {
        return (
            <NoItemsDisplay style={{ marginTop: '3em' }} />
        )
    }
}
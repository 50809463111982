import { createContext, useState } from "react";

export const CouponContext = createContext<{
    loading: boolean, setLoading: any
}>({
    loading: false,
    setLoading: () => { }
});
export const CouponProvider = (props: any) => {
    const children = props.children;
    const [loading, setLoading] = useState(false);

    return <CouponContext.Provider value={{
        loading,
        setLoading
    }}> {children} </CouponContext.Provider>;
};

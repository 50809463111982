import { Grid, useMediaQuery } from "@material-ui/core";
import { Title } from "../BasicComponents/Title";
import { ListOfCards } from "./ListOfCards";
import { useContext, useEffect, useState } from "react";
import { LibraryOfThingsContractContext } from "../../../providers/LibraryOfThingsContractProvider";
import { Item } from "../../../types/libofthings.type";

export const NewArrivalsContainer = () => {
    const [items, setItems] = useState<Item[]>([]);
    const { getNewArrivals } = useContext(LibraryOfThingsContractContext);
    const isSmallDevice = useMediaQuery("(max-width : 768px)");
    const isMediumDevice = useMediaQuery(
        "only screen and (min-width : 769px) and (max-width : 992px)"
    );
    const otherDevices = useMediaQuery('only screen and (min-width: 992px)');
    const title = 'Nuovi arrivi';

    useEffect(() => {
        (async () => {
            await getItems();
        })();
    }, []);

    const getItems = async () => {
        let res = await getNewArrivals(0, 6);
        setItems(res);
    }

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Title title={title} />
                </Grid>
                <Grid item xs={12} style={{ marginTop: '1em' }}>
                    {
                        isSmallDevice && <ListOfCards
                            callback={getItems}
                            items={items.slice(0, 2)}
                        />
                    }
                    {
                        isMediumDevice && <ListOfCards
                            callback={getItems}
                            items={items.slice(0, 4)}
                        />
                    }
                    {
                        otherDevices && <ListOfCards
                            callback={getItems}
                            items={items}
                        />
                    }
                </Grid>
            </Grid>
        </>
    )
}
import { Box, Grid, Typography, useMediaQuery } from "@material-ui/core";
import { Preorder, PreorderStatusStrings, PreorderStatuses } from "../../../../../types/calendar.type"
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { cancelPreorder, startPreorder } from "../../../../../store/slices/calendarSlice";
import toast from "react-hot-toast";
import { BlackButton } from "../../../../../components/Buttons/BlackButton";
import { useContext, useEffect, useState } from "react";
import { isApproved } from "../../../../../store/slices/nftSlice";
import config from "../../../../../config";
import { Item } from "../../../../../types/libofthings.type";
import { CalendarContractContext } from "../../../../../providers/CalendarContractProvider";
import { hoursToEndDate } from "../../../../../utilities/timer/daysToEndDate";
import { ENDING_PREORDER_WARNING_HOURS } from "../../../../../utilities/costants.calendar";

export const ButtonsToHandlePreorder = ({ borrowerEmail, preorder, callback, openDialogFallback, item, collectionAddress }: {
    preorder: Preorder,
    callback: any,
    openDialogFallback: any,
    item: Item,
    collectionAddress: string,
    borrowerEmail?: string
}) => {
    const dispatch = useAppDispatch();
    const { getPreordersBetweenDates } = useContext(CalendarContractContext);

    const handleStartPreorder = async () => {
        if (preorder.startDate > Date.now() / 1000) {
            toast.error('Non puoi iniziare un ordine prima della data di inizio');
            return;
        }
        try {
            await dispatch(startPreorder(preorder.preorderID));
            const preorders: Preorder[] = await getPreordersBetweenDates(preorder.startDate, preorder.endDate, preorder.itemID);
            callback();
        } catch (e) {
            console.log(e);
            toast.error('Errore nell\'inizio dell\'ordine');
        }
    }

    const handleCancelLending = async () => {
        const toastid = toast.loading('Annullo l\'ordine');
        try {
            await dispatch(cancelPreorder(preorder.preorderID, true));
            callback();
        } catch (e) {
            console.log(e);
            toast.error('Errore nell\'annullamento dell\'ordine');
        } finally {
            toast.dismiss(toastid);
        }
    }

    return (
        <ButtonsToHandlePreorderDesktop
            preorder={preorder}
            openDialogFallback={openDialogFallback}
            handleStartPreorder={handleStartPreorder}
            handleCancelLending={handleCancelLending}
            item={item}
            collectionAddress={collectionAddress}
            borrowerEmail={borrowerEmail}
        />
    );
}

const ButtonsToHandlePreorderDesktop = ({ borrowerEmail, preorder, openDialogFallback, handleStartPreorder, handleCancelLending, item, collectionAddress }: {
    preorder: Preorder,
    openDialogFallback: any,
    handleStartPreorder: () => Promise<void>,
    handleCancelLending: () => Promise<void>,
    item: Item,
    collectionAddress: string,
    borrowerEmail?: string
}) => {
    const profile = useAppSelector(state => state.user.currentProfile);
    const [userDidApprove, setUserDidApprove] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const [hoursMissing, setHoursMissing] = useState(0);
    const loading = useAppSelector(state => state.calendar.loading);

    useEffect(() => {
        setHoursMissing(hoursToEndDate(preorder.endDate));
    }, [preorder]);


    useEffect(() => {
        checkIfAddressIsApproved();
    }, []);

    const checkIfAddressIsApproved = async () => {
        (async () => {
            const currentAddressApprovedEndOfPreorder = await dispatch(isApproved(collectionAddress, config.smartContracts.CALENDAR_ADDRESS, item.idInCollection))
            if (currentAddressApprovedEndOfPreorder != null) {
                setUserDidApprove(currentAddressApprovedEndOfPreorder);
            }
        })();
    }


    return (
        <Grid container style={{ textAlign: 'center' }}>
            {
                (preorder.status === PreorderStatuses.Started
                    && preorder.borrower === profile?.additional_properties?.commonshoodWallet) && (
                    <Grid item>
                        <Typography variant="body1" align="center">Hai riconsegnato l'oggetto? Clicca sul tasto termina per concludere il prestito.</Typography>
                    </Grid>
                )
            }

            <Grid item xs={12} style={{ marginTop: '10px' }}>
                {
                    (preorder.status === PreorderStatuses.Accepted) && (
                        <>
                            <Typography variant="body1" style={{ fontWeight: 'bold', margin: '16px' }}>Hai consegnato l'oggetto? Clicca sul tasto conferma per iniziare il prestito</Typography>
                            <BlackButton disabled={loading} style={{ maxWidth: '264px', color: 'white' }} onClick={handleStartPreorder} text={'Conferma'} />

                        </>
                    )
                }
                {
                    (preorder.status === PreorderStatuses.Started) && (
                        <>
                            {
                                userDidApprove ? <>
                                    {
                                        <>
                                            {(hoursMissing > 0 && hoursMissing <= ENDING_PREORDER_WARNING_HOURS && borrowerEmail) ? (
                                                <>
                                                    <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <img src="/svgs/warning.svg" alt="warning icon" />
                                                        <Typography variant="body1" style={{ padding: '10px' }}>
                                                            Mancano poche ore al termine del prestito, clicca su accetta termine se ti è stato restituito l'oggetto
                                                        </Typography>
                                                    </Box>
                                                    <BlackButton disabled={loading} style={{ maxWidth: '264px', color: 'white', marginTop: '16px' }} onClick={openDialogFallback} text={'Accetta termine'} />
                                                </>
                                            ) : (
                                                <>
                                                    <Typography variant="body1">&Egrave; stato richiesto il termine del prestito. In attesa della tua risposta.</Typography>
                                                    <BlackButton disabled={loading} style={{ maxWidth: '264px', color: 'white', marginTop: '16px' }} onClick={openDialogFallback} text={'Accetta termine'} />
                                                </>

                                            )}
                                        </>
                                    }

                                </> : (
                                    <>
                                        {
                                            (hoursMissing > 0 && hoursMissing <= ENDING_PREORDER_WARNING_HOURS && borrowerEmail) ? (
                                                <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <img src="/svgs/warning.svg" alt="warning icon" />
                                                    <Typography variant="body1" style={{ fontWeight: 'bold', marginLeft: '16px' }}>
                                                        Contatta {borrowerEmail} <br /> per la riconsegna dell'oggetto
                                                    </Typography>
                                                </Box>
                                            ) : (
                                                <Typography variant="body1" style={{ fontWeight: 'bold', margin: '16px' }}>
                                                    Attendi la fine del prestito
                                                </Typography>
                                            )
                                        }
                                    </>

                                )
                            }
                        </>

                    )
                }
            </Grid >
        </Grid >
    )
}
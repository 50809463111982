import { FormikProps } from "formik"
import { ItemInfoInForm } from "../../../types/items.type"
import { Button, FormControlLabel, Grid, MenuItem, Radio, RadioGroup, Select, TextField, Typography, makeStyles } from "@material-ui/core";
import { LibraryOfThingsContractContext } from "../../../providers/LibraryOfThingsContractProvider";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { RealmContext } from "../../../providers/RealmProvider";

const style = makeStyles({
    textField: {
        width: '100%',
        maxWidth: '300px',
        borderBottom: '1px',
        borderColor: 'black',
        borderRadius: '10px',
    },
    container: {
        left: '1em',
        position: 'relative',
        ['@media (min-width: 600px)']: {
            marginTop: '2.5em'
        }
    },
    select: {
        width: '100%',
        maxWidth: '300px',
        position: 'relative',
        background: 'rgba(248, 248, 248, 1)',
        borderBottom: '1px',
        borderColor: 'black',
        borderRadius: '10px',
    },
    radioGroup: {
        ['@media (min-width: 600px)']: {
            flexDirection: 'row',
        }
    },
    priceLabel: {
        position: 'relative',
    },
    publishButton: {
        borderRadius: '15px',
        width: '200px',
        color: 'white',
        background: 'black',
        '&:hover': {
            background: 'rgba(235, 176, 76, 1)',
            color: 'black'
        },
        '&:disabled': {
            background: 'rgba(129, 129, 129, 1)',
            color: 'black'
        },
        '&:focus': {
            background: 'rgba(218, 93, 62, 1)',
            color: 'black'
        }
    },
    cancelButton: {
        borderRadius: '15px',
        width: '100px',
        borderColor: 'none',
        background: 'none',
        border: 'none',
        ['@media (max-width: 600px)']: {
            marginTop: '1em'
        }
    },
    buttonContainer: {
        ['@media (max-width: 600px)']: {
            textAlign: 'center'
        }
    }
})

export const UploadItemRightPanel = ({ formik }: {
    formik: FormikProps<ItemInfoInForm>
}) => {
    const classes = style();
    const { loading } = useContext(LibraryOfThingsContractContext);
    const { isCurrentRealmCOSO, isCurrentRealmChGarden } = useContext(RealmContext);
    const { t } = useTranslation('LibOfThings');

    const handleChangeTextField = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target != null && event.target.value != null) {
            const file = event.target.value;
            formik.setFieldValue('name', file);
        }
    }
    const handleChangeCategory = (event: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
    }>) => {
        if (event.target != null && event.target.value != null) {
            formik.setFieldValue('category', event.target.value);
        }
    }

    return (
        <Grid container className={classes.container} spacing={2}>
            <Grid item xs={12}>
                <TextField
                    value={formik.values.name}
                    id="standard-basic"
                    label={`${t('insertItemName')}*`}
                    onChange={handleChangeTextField}
                    className={classes.textField}
                    variant="outlined"
                />
                {formik.errors.name && formik.touched.name ? (
                    <div>{formik.errors.name}</div>
                ) : null}
            </Grid>
            <Grid item xs={12}>
                {
                    isCurrentRealmCOSO && (
                        <Select
                            variant="outlined"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            className={classes.select}
                            value={formik.values.category}
                            label="Categoria"
                            onChange={handleChangeCategory}
                        >
                            <MenuItem value={'GIOCHI'}>Giochi</MenuItem>
                            <MenuItem value={'ELETTRODOMESTICI/CUCINA'}>Elettrodomestici / Cucina</MenuItem>
                            <MenuItem value={'ARREDI'}>Arredi</MenuItem>
                            <MenuItem value={'TRASPORTO'}>Trasporto</MenuItem>
                            <MenuItem value={'ARTE MUSICA CULTURA'}>Arte musica cultura</MenuItem>
                            <MenuItem value={'FAI DA TE'}>Fai da te</MenuItem>
                            <MenuItem value={'ABBIGLIAMENTO'}>Abbigliamento</MenuItem>
                            <MenuItem value={'PIANTE'}>Piante</MenuItem>
                            <MenuItem value={'ARTIGIANATO'}>Artigianato</MenuItem>
                            <MenuItem value={'ALTRO'}>Altro</MenuItem>
                        </Select>
                    )
                }
                {
                    isCurrentRealmChGarden && (
                        <Select
                            variant="outlined"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            className={classes.select}
                            label="Categoria"
                            value={formik.values.category}
                            onChange={handleChangeCategory}
                        >
                            <MenuItem value={'Giardinaggio'}>Giardinaggio</MenuItem>
                        </Select>
                    )
                }
            </Grid>
            <Grid item xs={12} style={{ marginBottom: '16px' }}>
                <Typography variant="body1">Indica se l'oggetto va utilizzato con la tua supervisione o in autonomia:</Typography>
                <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    onChange={(e) => {
                        formik.setFieldValue('requireSupervision', e.target.value === 'true');
                    }}
                >
                    <FormControlLabel value="false" control={<Radio />} label="in autonomia" />
                    <FormControlLabel value="true" control={<Radio />} label="con la mia supervisione" />
                </RadioGroup>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: '3em', marginTop: '1em' }}>
                <Grid container className={classes.buttonContainer}>
                    <Grid item xs={12} sm={5} >
                        <Button
                            type="submit"
                            variant="outlined"
                            disabled={loading}
                            className={classes.publishButton}>
                            Pubblica
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

    );
}
import { FormikProps } from "formik"
import { ExchangeInfoInForm } from "./ExchangeFormContainer"
import { Box, Grid, Input, TextField, Typography, makeStyles, useMediaQuery } from "@material-ui/core"
import { OrangeLine } from "../../Wallet/Coupon/CreationForm/OrangeLine"
import { BlackButton } from "../../../components/Buttons/BlackButton"
import { TransparentButton } from "../../../components/Buttons/TransparentButton"
import { useContext } from "react"
import { ExchangeFormContext } from "../../../providers/ExchangeFormCreationProvider"
import { InputField } from "../../../components/Input/InputField"
import toast from "react-hot-toast"
import { useAppSelector } from "../../../store/hooks"
import config from "../../../config"

const useStyle = makeStyles({
    container: {
        marginTop: '3em',
        '@media (max-width:600px)': {
            marginTop: '2em',
            marginLeft: '16px'
        }
    },
    card: {
        width: '236px',
        margin: '0 auto',
        '@media (max-width:600px)': {
            width: '150px'
        }
    },
    amountContainer: {
        background: 'white',
        border: '1.5px solid black',
        borderRadius: '40px',
        width: '139px',
        textAlign: 'center',
        '@media (max-width:600px)': {
            width: '104px'
        }
    }
})

export const FifthStep = ({ formik }: {
    formik: FormikProps<ExchangeInfoInForm>
}) => {
    const isMobile = useMediaQuery('(max-width:600px)');

    return (
        <>
            <Grid container className={useStyle().container}>
                <Description />
                <Grid item container xs={12} sm={7}>
                    <Grid item sm={6}>
                        <TokensOffered formik={formik} />
                    </Grid>
                    {
                        !isMobile && (
                            <Grid item sm={1} style={{ textAlign: 'center', marginTop: '7%' }}>
                                <img src="/svgs/coupons/grey_line_vertical.svg" style={{ width: '3%' }} />
                            </Grid>
                        )
                    }
                    <Grid item sm={5}>
                        <CouponsRequested formik={formik} />
                    </Grid>
                    <Grid item xs={12} sm={12} style={{ marginTop: '2em' }}>
                        <ExchangeDatePicker formik={formik} />
                    </Grid>
                    <Grid item xs={12} sm={5} style={{ marginTop: '16px' }}>
                        <NumberOfExchangeAvailables formik={formik} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container item>
                <ContinueButtons formik={formik} />
            </Grid>
        </>
    )
}

const tokensOfferedStyle = makeStyles({
    title: {
        '@media (min-width:600px)': {
            textAlign: 'center',
            marginBottom: '3em'
        },
    },
    offerLabel: {
        '@media (max-width:600px)': {
            marginBottom: '1em'
        }
    },
    requestLabel: {
        '@media (max-width:600px)': {
            marginBottom: '1em'
        }
    },
    tokensOfferedContainer: {
        '@media (max-width:600px)': {
            marginBottom: '2em'
        }
    }
})

const TokensOffered = ({ formik }: {
    formik: FormikProps<ExchangeInfoInForm>
}) => {
    const classes = tokensOfferedStyle();

    return (
        <>
            <Grid container className={classes.tokensOfferedContainer}>
                <Grid item xs={12} className={classes.offerLabel}>
                    <Typography variant='h6' className={tokensOfferedStyle().title}>
                        Richiedi
                    </Typography>
                </Grid>
                <Grid item xs={7} container alignItems="center" justify="center">
                    <TokensOfferedRecap text="COSO - COSO" formik={formik} />
                </Grid>
                <Grid item xs={1} container alignItems="center" justify="center">
                    <Typography variant='h6'>
                        x
                    </Typography>
                </Grid>
                <Grid item xs={4} container alignItems="center" justify="center">
                    <COSOAmount count={formik.values.tokensRequestedAmount} />
                </Grid>
            </Grid>
        </>

    )
}

const CouponsRequested = ({ formik }: {
    formik: FormikProps<ExchangeInfoInForm>
}) => {
    const classes = tokensOfferedStyle();
    const couponSrc = formik.values.couponSrc.replace(config.network.ipfs.default_url, config.network.ipfs.gateway_url)

    return (
        <Grid container>
            <Grid item xs={12} className={classes.offerLabel}>
                <Typography variant='h6' className={tokensOfferedStyle().title}>
                    Offri
                </Typography>
            </Grid>
            <Grid item xs={7} container alignItems="center" justify="center">
                <TokensOfferedRecap text={formik.values.symbolOfCouponRequested} src={couponSrc} formik={formik} />
            </Grid>
            <Grid item xs={1} container alignItems="center" justify="center">
                <Typography variant='h6'>
                    x
                </Typography>
            </Grid>
            <Grid item xs={4} container alignItems="center" justify="center">
                <COSOAmount count={formik.values.couponsRequestedAmount} />
            </Grid>
        </Grid>
    )
}

const TokensOfferedRecap = ({ formik, src = '/svgs/coso_logo_rounded.svg', text }: {
    formik: FormikProps<ExchangeInfoInForm>,
    src?: string,
    text: string
}) => {
    return (
        <Box borderRadius={30} height={60} width={236} style={{
            border: '1.5px solid rgba(24, 27, 29, 1)',
            borderRadius: '32.5px',
            position: 'relative',
            alignItems: 'center',  // Vertically center the content
            justifyContent: 'center'  // Horizontally center the content
        }} className={useStyle().card}>
            <Grid container>
                <Grid item xs={4} style={{ padding: '13px' }}>
                    <img src={src} width={35} height={35}></img>
                </Grid>
                <Grid item xs={5} justifyContent="center" style={{
                    marginTop: '20px'
                }}>
                    <Typography variant='body2' style={{ fontSize: '14px', whiteSpace: 'nowrap' }}>
                        {text}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}



const Description = () => {
    const isMobile = useMediaQuery('(max-width:600px)');

    return (
        <>
            <Grid item xs={12} sm={2} container alignItems="center" justify="center">
                <Box textAlign={isMobile ? 'center' : 'right'}>
                    <Typography variant='h6'>
                        Riepilogo dell'offerta
                    </Typography>
                </Box>
                {
                    !isMobile && (
                        <Box textAlign={isMobile ? 'center' : 'right'} style={{ marginTop: '13em' }}>
                            <Typography variant='h6'>
                                Data di scadenza?
                            </Typography>
                            <Typography variant='h6' style={{ marginTop: '30px' }}>
                                Quantità di scambi disponibili?
                            </Typography>
                        </Box>
                    )
                }

            </Grid>
            {
                isMobile && (
                    <Grid item xs={12} sm={1}>
                        <OrangeLine />
                    </Grid>
                )
            }
            {
                !isMobile && (
                    <Grid item xs={12} sm={1}>
                        <OrangeLine width={'3%'} />
                    </Grid>
                )
            }
        </>
    )
}

const COSOAmount = ({ count }: {
    count: number
}) => {
    return (
        <Box className={useStyle().amountContainer} >
            <Typography variant='h6' style={{ marginTop: '5px', marginBottom: '5px' }}>
                {count}
            </Typography>
        </Box>
    )
}


const exchangeDatePickerUseStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
        border: 'solid rgba(24, 27, 29, 1)',
        borderRadius: '40px',
        padding: 8
    },
}));

const ExchangeDatePicker = ({ formik }: {
    formik: FormikProps<ExchangeInfoInForm>
}) => {
    const classes = exchangeDatePickerUseStyles();

    const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const endDate = new Date(event.target.value);
        const now = new Date();
        if (endDate < now) {
            toast.error('Seleziona una data nel futuro');
        } else {
            formik.setFieldValue('endDate', new Date(event.target.value));
        }
    }

    return (
        <form className={classes.container} noValidate>
            <Input
                onChange={onChange}
                id="date"
                disableUnderline={true}
                type="date"
                className={classes.textField}
                inputProps={{ disableUnderline: true }}
            />
        </form>
    );
}

const numberOfExchangesAvailableStyle = makeStyles({
    textField: {
        '@media (max-width:600px)': {
            maxWidth: '100px',
            marginLeft: '16px'
        },
        '@media (min-width:600px)': {
            maxWidth: '400px'
        },
        '@media (min-width:600px) and (max-width: 860px)': {
            maxWidth: '300px'
        }
    }
})

const NumberOfExchangeAvailables = ({ formik }: {
    formik: FormikProps<ExchangeInfoInForm>
}) => {
    const onQntChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue('amountOfExchangesAvailable', event.target.value);
    }

    return (
        <Grid container >
            <Grid item xs={12} sm={6} style={{ textAlign: 'center' }} className={numberOfExchangesAvailableStyle().textField}>
                <InputField
                    type={'number'}
                    value={formik.values.amountOfExchangesAvailable.toString()}
                    placeholder='_ _ _'
                    variant='outlined'
                    onChange={onQntChange}
                    style={{ textAlign: 'center' }}
                    min={1}
                    max={999}
                />
            </Grid>
        </Grid>
    )
}

const ContinueButtons = ({
    formik
}: {
    formik: FormikProps<ExchangeInfoInForm>
}) => {
    const { setStep } = useContext(ExchangeFormContext);
    const { loading } = useAppSelector(state => state.exchange);

    const isDisabled = () => {
        const endDateInForm = formik.values.endDate;
        const now = new Date();
        if (endDateInForm < now) {
            return true;
        }
        return formik.values.amountOfExchangesAvailable <= 0;
    }

    const onContinue = () => {
        formik.handleSubmit();
    }

    return (
        <>
            <Grid container spacing={2} style={{ marginTop: '16px' }}>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <BlackButton
                        width={227}
                        onClick={onContinue}
                        text={'Pubblica'}
                        disabled={isDisabled() || loading}
                    />
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <TransparentButton
                        width={227}
                        style={{
                            color: 'rgba(218, 93, 62, 1)',
                            border: 'none'
                        }}
                        disabled={loading}
                        onClick={() => {
                            formik.resetForm()
                            setStep(0)
                        }}
                        text={'Annulla'}
                    />
                </Grid>
            </Grid>
        </>
    );
}

/**
 * @param endDate 
 * 
 * Returns the number of days from now to endDate
 * If less than 24 hours then it returns 0
 */
export const hoursToEndDate = (endDate: number) => {
  console.log(endDate);
  const now = new Date().getTime() / 1000;
  const d = (endDate - now) / (60 * 60);
  return Math.max(d, 0);
}
import { Box, Grid, Typography, makeStyles, useMediaQuery } from "@material-ui/core";
import { CouponLogo } from "./CouponLogo";
import { useEffect, useState } from "react";
import config from "../../../../config";
import { useHistory } from "react-router-dom";

const style = makeStyles({
    box: {
        width: '100%',
        height: 'auto',
        borderRadius: '10px',
        border: '1px solid #E0E0E0',
        padding: '16px 0 12px 6px',
        '@media (max-width: 600px)': {
            width: '100%',
            height: '230px',
        }
    },
    couponContainer: {
        '@media (max-width: 600px)': {
            textAlign: 'center'
        }
    }
})

export const CouponCard = ({ coupon, renderAmount = false }: { coupon: any, renderAmount?: boolean }) => {
    const [image, setImage] = useState<string>('');
    const isMobile = useMediaQuery('(max-width:600px)');
    const classes = style();
    const history = useHistory();

    useEffect(() => {
        setImage(coupon.logo.replace(config.network.ipfs.default_url, config.network.ipfs.gateway_url));
    }, [coupon.logo])

    const openCouponOverviewPage = () => {
        history.push(`/coupon/overview/${coupon.symbol}`);
    }

    return (
        <Grid container className={classes.box}>
            <Grid item xs={12} sm={3} className={classes.couponContainer}>
                <CouponLogo logo={image} selected={false} />
            </Grid>
            <Grid item xs={12} sm={6} style={{ textAlign: 'center' }}>
                <CouponName name={coupon.name} symbol={coupon.symbol} />
            </Grid>
            {
                !isMobile && (
                    <>
                        <Grid item xs={3} style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <img onClick={openCouponOverviewPage} style={{ cursor: 'pointer' }} width={34.13} height={34.13} src="/svgs/coupons/circle_with_arrow_icon.svg" alt="arrow" />
                        </Grid>
                        


                    </>

                )
            }
            {
                            renderAmount && (
                                <>
                                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                                        <img src="/svgs/coupons/grey_line_horizontal.svg" alt="line" style={{
                                            width: '90%',
                                            height: '2px'
                                        }} />
                                    </Grid>
                                    <Grid item xs={12} style={{ textAlign: 'center', marginTop: isMobile ? '0px' : '16px'}}>
                                        <Typography variant="h4">
                                            {coupon.balance.balance}
                                        </Typography>
                                    </Grid>
                                </>

                            )
                        }
        </Grid>
    );
}

const CouponName = ({ name, symbol }: { name: string, symbol: string }) => {
    return (
        <>
            <Typography variant='subtitle1'>
                {name}
            </Typography>
            <Typography variant={'caption'}>
                {symbol}
            </Typography>
        </>

    )
}
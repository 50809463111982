import React, { useContext, useEffect, useState } from 'react';
//routing
import { RouteComponentProps, withRouter } from 'react-router-dom';
//i18n
import { useTranslation } from 'react-i18next';

//Redux connector
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
    getSuggestedUsers,
    userGetDataFromId,
    userSetAllProfiles,
    userSetCurrentProfile,
    userSetUserProfile
} from "../../store/slices/userSlice";

import type { ExtendedUserData } from "../../types/user.type";
//Material-UI Components
import { Grid, Hidden, makeStyles } from '@material-ui/core';
//Custom Components
import MainAppBar from '../../components/UI/NavBar/MainAppBar/MainAppBar';
import ListMenu from "../../components/UI/Menu/ListMenu/ListMenu";
//Menu Config
import menuConfig from '../../config/menuConfig';
import { RealtimeNotificationType } from "../../api/notificationAPI";
import getNotificationText from "../../utilities/notification/notification-messages";
import notificationKeys from '../../utilities/notification/messageKeys';
import toast from 'react-hot-toast';
import { newNotfConsumed } from '../../store/slices/notificationSlice';

type LayoutProps = RouteComponentProps & {
    title: any,
    children: React.ReactNode,
};

const styles = makeStyles({
    headerContainer: {
        position: 'relative',
        top: '2em',
        padding: '15px'
    },
    ['@media (max-width: 600px)']: {
        headerContainer: {
            top: '0em',
            padding: '0px'
        }
    }
})

const Layout = (props: LayoutProps) => {
    const { t } = useTranslation(['NotificationMessages', 'Menu', 'Common']);
    const dispatch = useAppDispatch();
    const classes = styles();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const userLoading = useAppSelector(state => state.user.loading);
    const userData = useAppSelector(state => state.user.user);
    const currentProfile = useAppSelector(state => state.user.currentProfile);
    const userProfile = useAppSelector(state => state.user.userProfile);
    const profilesList = useAppSelector(state => state.user.profilesList);
    const ethersInstance = useAppSelector(state => state.ethers.ethersInstance);
    const newNotfID = useAppSelector(state => state.notification.newNotfID)
    const notfOfCurrentSession = useAppSelector(state => state.notification.notificationsOfCurrentSession)
    const notificationList = [...notfOfCurrentSession]
        .sort((notf1, notf2) => {
            const notf1_message = JSON.parse(notf1.message);
            const notf2_message = JSON.parse(notf2.message);
            return notf2_message.timestamp - notf1_message.timestamp;
        })

    const navHandler = (path: string) => {
        drawerCloseHandler();
        setTimeout(() => props.history.push(path), 200);
    };

    const drawerCloseHandler = () => setDrawerOpen(false);

    useEffect(() => {
        const userId = localStorage.getItem('userId');
        if (!userLoading && userId != null) {
            dispatch(userGetDataFromId(userId));
        }

    }, []); //onMount

    useEffect(() => {
        if (
            userData != null && //user profile loaded and ready to be stored
            userProfile == null //we didn't already store it
        ) {
            dispatch(userSetCurrentProfile({ profile: userData }));
            dispatch(userSetUserProfile({ profile: userData }));
        }
    }, [userData, userProfile]);



    useEffect(() => {
        const profiles: (ExtendedUserData)[] = [];
        if (userProfile != null) {
            profiles.push(userProfile);
        }
        dispatch(userSetAllProfiles({ profilesList: profiles }));
    }, [ethersInstance]);

    useEffect(() => {
        if (newNotfID !== null) {
            let lastNotification: RealtimeNotificationType | undefined = notificationList.find(notf => JSON.parse(String(notf._id === newNotfID)));
            if (lastNotification) {
                const notfMessage = buildNotification(lastNotification);
                sendMessage(notfMessage, newNotfID);
            }
        }
    }, [newNotfID, notfOfCurrentSession]);

    const buildNotification = (notification: RealtimeNotificationType) => {
        return JSON.parse(notification.message);
    }

    const sendMessage = (notfMessage: any, newNotfID: string) => {
        let messageString = '';
        try {
            if (currentProfile) {
                let chWallet = currentProfile.additional_properties?.commonshoodWallet
                if (chWallet) {
                    let messageKey = '';
                    if (notfMessage.eventType.includes('EXCHANGE')) {
                        messageKey = notfMessage.eventType;
                    }
                    if (messageKey.length === 0) {
                        if (notfMessage.eventType !== 'COIN_TRANSFER') {
                            messageKey = notfMessage.eventType;
                        } else {
                            if (notfMessage.sender === chWallet) {
                                messageKey = 'COIN_SENT';
                            } else {
                                messageKey = 'COIN_RECEIVED';
                            }
                        }
                    }
                    if (shouldSkipNotification(messageKey)) {
                        //some notifications are not needed for COSO so we just skeep them after having consumed them
                        dispatch(newNotfConsumed({ notificationID: newNotfID }))
                        return;
                    };
                    messageString = getNotificationText(messageKey, notfMessage, t, chWallet);
                    if (messageString.includes('Hai ricevuto 0.00 COSO')) {
                        dispatch(newNotfConsumed({ notificationID: newNotfID }))
                        return;
                    }
                    if (messageKey === 'COIN_SENT' || messageKey === 'COIN_RECEIVED') {
                        dispatch(getSuggestedUsers(1))
                    }
                }
            }
        } catch (error: any) {
            messageString = error.message;
        }
        toast.success(messageString);
        dispatch(newNotfConsumed({ notificationID: newNotfID }))
    }

    const shouldSkipNotification = (key: string) => {
        return (
            key === notificationKeys.NFT_ADDED ||
            key === notificationKeys.NFT_REMOVED ||
            key === notificationKeys.COLLECTION_CREATED ||
            key === notificationKeys.COIN_CREATED
        );
    }


    let mainLayout: JSX.Element | null = (<></>);

    if (profilesList?.length !== 0 && ethersInstance != null) {
        mainLayout = (
            <>
                {
                    window.location.href.includes('tutorial') ? (
                        <>{props.children}</>
                    ) : (
                        <>
                            <Grid container spacing={2}>
                                <Hidden smDown>
                                    <Grid item xs={1} style={{
                                        position: 'relative',
                                        top: '5em',
                                        left: '10px'
                                    }}>
                                        <ListMenu
                                            isVertical={true}
                                            navHandler={navHandler}
                                            itemList={menuConfig(t).menu.items}
                                        />
                                    </Grid>
                                </Hidden>

                                <Hidden mdUp>
                                    <Grid item xs={12} style={{
                                        position: 'fixed',
                                        bottom: '0px',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)'
                                    }}>
                                        <ListMenu
                                            navHandler={navHandler}
                                            itemList={menuConfig(t).menu.items}
                                            isVertical={false}
                                        />
                                    </Grid>
                                </Hidden>
                                <Grid item xs={12} md={11} className={classes.headerContainer}>
                                    <Grid container direction='column' spacing={3}>
                                        {
                                            window.location.href.includes('tutorial') ? null : (
                                                <Grid item xs={12}>
                                                    <MainAppBar />
                                                </Grid>
                                            )
                                        }
                                        <Grid item xs={12}>
                                            {props.children}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid >
                        </>
                    )
                }

            </>
        );
    }
    return (mainLayout);
}
export default withRouter(Layout);

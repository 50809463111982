import config from "../config";
import axios from "../utilities/backend/axios-metadata";
import { Preorder } from "../types/calendar.type";
import { ethers, utils, Wallet } from "ethers";
import { logger } from "../utilities/logger/logger";

export const getCalendarInstance = (ethersInstance: Wallet) => {
    const abi = new utils.Interface(config.smartContracts.CALENDAR_ABI)

    return new ethers.Contract(
        config.smartContracts.CALENDAR_ADDRESS,
        abi,
        ethersInstance
    )
}

const getTokenInstance = (ethersInstance: Wallet, address: string) => {
    const abi_template = new utils.Interface(config.smartContracts.TKN_TMPLT_ABI)

    return new ethers.Contract(
        address,
        abi_template,
        ethersInstance
    )
}

const getTokenFactoryInstance = (ethersInstance: Wallet) => {
    const abi_factory = new utils.Interface(config.smartContracts.TKN_FCTRY_ABI)

    return new ethers.Contract(
        config.smartContracts.TKN_FCTRY_ADDR,
        abi_factory,
        ethersInstance
    );
}

export const createPreorder = async (
    ethers: Wallet,
    id: number,
    startDate: number,
    endDate: number,
    hash: string,
    signature: string,
    from: string,
    symbolOfCoinUsed: string,
    price: number,
    caution: number,
) => {
    let trx;
    try {
        let coin: string;
        const calendarInstance = getCalendarInstance(ethers);
        const tokenFactoryInstance = getTokenFactoryInstance(ethers);
        if (calendarInstance == null) {
            console.error('calendar instance is null in createPreorder');
            throw new Error('Calendar instance is undefined');
        }
        const preorderRequest = {
            _idItem: id,
            _startDate: startDate,
            _endDate: endDate,
            _hash: hash,
            _signature: signature
        }
        coin = await tokenFactoryInstance.getToken(symbolOfCoinUsed)
        const address = coin[0];
        const tokenTemplateInstance = getTokenInstance(ethers, address);
        const days = Math.ceil((endDate - startDate) / 60 / 60 / 24);
        const total = parseInt(caution.toString()) + parseInt((price * days).toString());
        const approve = await tokenTemplateInstance.approve(config.smartContracts.CALENDAR_ADDRESS, total)
        await approve.wait();

        await calendarInstance.getErc20FactoryAddress()

        await tokenFactoryInstance.getToken(symbolOfCoinUsed)
        trx = await calendarInstance
            .createPreorder(preorderRequest)
        trx = await trx.wait();
        console.log(`Creation of a preorder transaction hash: ${trx.transactionHash}`);
        console.log(trx);
    } catch (e: any) {
        console.log(e);
    }
    return trx;
}

export const fetchDatesForItem = async (itemID: number) => {
    let dates: { startDate: number, endDate: number }[] = [];
    try {
        const q = `${config.network.metadata.url}lot/preorders/getDatesForItem?id=${itemID}`;
        const responseForOccupiedSlots = await axios.get(q);
        if (responseForOccupiedSlots.data != null) {
            responseForOccupiedSlots.data.dates.map((p: { startDate: number, endDate: number }) => {
                dates.push({
                    startDate: p.startDate,
                    endDate: p.endDate
                })
            })
        }
    } catch (error) {
        console.log(error);
    }
    return dates;
}

export const getPendingPreordersAPI = async (address: string, isLender: boolean, offset: number, limit: number) => {
    let preorders: Preorder[] = [];
    let totalPreorders = 0;
    try {
        const q = `${config.network.metadata.url}lot/${address}/preorders/Pending?isLender=${isLender ? 'true' : 'false'}&limit=${limit}&offset=${offset}`;
        const responseForPendingEvents = await axios.get(q);
        if (responseForPendingEvents.data != null) {
            preorders = responseForPendingEvents.data.preorders;
            totalPreorders = responseForPendingEvents.data.numberOfPreorders;
        }
    } catch (error) {
        console.log(error);
    }
    return { preorders, totalPreorders };
}

export const acceptPreorderAPI = async (ethers: Wallet, preorderID: string, from: string) => {
    let trx;
    try {
        const calendarInstance = getCalendarInstance(ethers);
        trx = await calendarInstance.acceptPreorder(preorderID)
        trx = await trx.wait();
    } catch (error) {
        const e = error as Error;
        if (e.message != null) {
            throw new Error(e.message);
        }
    }
    return trx;
}

export const denyPreorderAPI = async (ethers: Wallet, preorderID: string, from: string, reason: string) => {
    console.log('denyPreorderAPI was called');
    try {
        const calendarInstance = getCalendarInstance(ethers);
        await calendarInstance.preorderInfo(preorderID)
        let trx = await calendarInstance.denyPreorder(preorderID, reason)
        trx = await trx.wait();
        console.log(trx);
    } catch (e: any) {
        if (e.message != null) {
            throw new Error(e.message);

        } else {
            console.log(e);
        }
    }
}

export const getAcceptedPreordersAPI = async (from: string, isLender: boolean, offset: number, limit: number) => {
    console.log(`getAcceptedPrerodersAPI was called`);
    let preorders: Preorder[] = [];
    let totalPreorders = 0;

    const q = `${config.network.metadata.url}lot/${from}/preorders/Accepted?isLender=${isLender ? 'true' : 'false'}&offset=${offset}&limit=${limit}`;
    const responseForPendingEvents = await axios.get(q);
    console.log(responseForPendingEvents.data);
    if (responseForPendingEvents.data != null) {
        preorders = responseForPendingEvents.data.preorders;
        totalPreorders = responseForPendingEvents.data.numberOfPreorders;
    }
    return { preorders, totalPreorders };
}

export const getCancelledPreordersAPI = async (from: string, isLender: boolean, offset: number, limit: number) => {
    let preorders: Preorder[] = [];
    let numberOfPreorders = 0;
    const q = `${config.network.metadata.url}lot/${from}/preorders/Cancelled?isLender=${isLender ? 'true' : 'false'}&offset=${offset}&limit=${limit}`;
    const responseForPendingEvents = await axios.get(q);

    if (responseForPendingEvents.data != null) {
        responseForPendingEvents.data.preorders.map((p: any) => {
            preorders = responseForPendingEvents.data.preorders;
        })
        numberOfPreorders = responseForPendingEvents.data.numberOfPreorders;
    }
    return { preorders, numberOfPreorders };
}

export const getActivePreordersAPI = async (from: string, isLender: boolean, offset: number, limit: number) => {
    let activePreorders = 0;
    const preorders = await getPreorders(from, isLender, offset, limit, 'Active');
    console.log(preorders);
    return { preorders, activePreorders };
}

export const getPreordersWithStatusEnded = async (from: string, isLender: boolean, offset: number, limit: number) => {
    console.log(`Get ended revision API called`);
    let preorders: Preorder[] = [];
    let numberOfPreorders = 0;
    const q = `${config.network.metadata.url}lot/${from}/preorders/Ended?isLender=${isLender ? 'true' : 'false'}&offset=${offset}&limit=${limit}`;
    const responseForPendingEvents = await axios.get(q);

    if (responseForPendingEvents.data != null) {
        responseForPendingEvents.data.preorders.map((p: any) => {
            preorders = responseForPendingEvents.data.preorders;
        })
        numberOfPreorders += responseForPendingEvents.data.numberOfPreorders;
    }

    return { preorders, numberOfPreorders };
}

export const getPreorders = async (from: string, isLender: boolean, offset: number, limit: number, status: string) => {
    let preorders: Preorder[] = [];
    let numberOfPreorders = 0;
    const q = `${config.network.metadata.url}lot/${from}/preorders/${status}?isLender=${isLender ? 'true' : 'false'}&offset=${offset}&limit=${limit}`;
    const res = await axios.get(q);
    if (res.data != null) {
        res.data.preorders.map((p: any) => {
            preorders = res.data.preorders;
        })
        numberOfPreorders += res.data.numberOfPreorders;
    }
    return { preorders, numberOfPreorders };
}

export const getPreordersWithStatusReviewAPI = async (from: string, isLender: boolean, offset: number, limit: number) => {
    console.log(`getting preorders with status REVIEW`);
    let numberOfPreorders = 0;
    let preorders: Preorder[] = [];
    const q = `${config.network.metadata.url}lot/${from}/preorders/Review?isLender=${isLender ? 'true' : 'false'}&offset=${offset}&limit=${limit}`;
    const responseForPendingEvents = await axios.get(q);
    if (responseForPendingEvents.data != null) {
        preorders = responseForPendingEvents.data.preorders;
        numberOfPreorders = responseForPendingEvents.data.numberOfPreorders;
    }
    return { preorders, numberOfPreorders };
}

export const getPreordersWithStatusStarted = async (address: string, isLender: boolean, offset: number, limit: number) => {
    console.log(`getPreordersWithStatusStarted called`);
    let preorders: Preorder[] = [];
    let numberOfPreorders = 0;
    const q = `${config.network.metadata.url}lot/${address}/preorders/Started?isLender=${isLender ? 'true' : 'false'}&offset=${offset}&limit=${limit}`;
    const responseForPendingEvents = await axios.get(q);
    if (responseForPendingEvents.data != null) {
        preorders = responseForPendingEvents.data.preorders;
        numberOfPreorders = responseForPendingEvents.data.numberOfPreorders;
    }
    return { preorders, numberOfPreorders };
}

export const cancelPreorderAPI = async (ethers: Wallet, from: string, preorderID: string) => {
    console.log(`cancelPreorderAPI was called with preorderID: ${preorderID}`);
    try {
        const calendarInstance = getCalendarInstance(ethers);
        let trx = await calendarInstance.cancelPreorder(preorderID)
        trx = await trx.wait();
        console.log(trx);
    } catch (e: any) {
        if (e.message != null) {
            if (e.message.includes("You can't cancel a preorder you didn't request.")) {
                throw new Error("You can't cancel a preorder you didn't request.");
            } else if (e.message.includes("To cancel a preorder it must not be started or denied.")) {
                throw new Error("To cancel a preorder it must not be started or denied.");
            } else if (e.message.includes("Cannot set address(0) as valid address")) {
                throw new Error("Cannot set address(0) as valid address");
            } else if (e.message.includes("There's no item with this ID registered in the marketplace. Please provide a valid ID.")) {
                throw new Error("There's no item with this ID registered in the marketplace. Please provide a valid ID.");
            } else {
                console.log(e.message);
            }
        } else {
            console.log(e);
        }
    }

}

export const reimburseTokens = async (ethers: Wallet, from: string, preorderID: string) => {
    try {
        console.log(`Reimbursing tokens`);
        const calendarInstance = getCalendarInstance(ethers);
        let reimburse = await calendarInstance.reimbursePreorderTokens(preorderID)
        reimburse = await reimburse.wait();
        logger.info(reimburse);

    } catch (e: any) {
        if (e.message != null) {
            if (e.message.includes("Cannot set address(0) as valid address")) {
                throw new Error("Cannot set address(0) as valid address");
            } else if (e.message.includes("There's no item with this ID registered in the marketplace. Please provide a valid ID.")) {
                throw new Error("There's no item with this ID registered in the marketplace. Please provide a valid ID.");
            } else {
                console.log(e.message);
            }
        } else {
            console.log(e);
        }
    }

}

export const startPreorderAPI = async (ethers: Wallet, from: string, preorderID: string) => {
    let receipt = null;
    try {
        const calendarInstance = getCalendarInstance(ethers);
        const trx = await calendarInstance
            .startLendingPeriod(preorderID)
        receipt = await trx.wait();
        return trx;
    } catch (e: any) {
        console.log(e.message);
    }
}

export const endLendingPeriodAPI = async (ethers: Wallet, from: string, preorderID: string, comment: string, itemWasFine: boolean) => {
    try {
        console.log(`End lending period api called`);
        const calendarInstance = getCalendarInstance(ethers);
        let end = await calendarInstance.endLendingPeriod(preorderID, comment, itemWasFine)
        end = await end.wait();
        console.log(end);
    } catch (e: any) {
        if (e.message != null) {
            if (e.message.includes("Only the borrower of item in the preorder can end the lending period")) {
                throw new Error("Only the borrower of item in the preorder can end the lending period");
            } else if (e.message.includes("This preorder never started or it is already ENDED")) {
                throw new Error("This preorder never started or it is already ENDED");
            } else if (e.message.includes("Collection address not valid")) {
                throw new Error("Collection address not valid");
            } else {
                console.log(e.message);
            }
        } else {
            console.log(e);
        }
    }

}

export const endRevisionPeriodAPI = async (ethers: Wallet, from: string, comment: string, itemIsFine: boolean, preorderID: string) => {
    try {
        console.log('End revision period API was called');
        const calendarInstance = getCalendarInstance(ethers);
        let trx = await calendarInstance
            .endRevision(preorderID, comment, itemIsFine)
        trx = await trx.wait();
        console.log(trx);
    } catch (e: any) {
        if (e.message != null) {
            if (e.message.includes("You're not the lender of the item of this preorder. So you can't end the revision.")) {
                throw new Error("You're not the lender of the item of this preorder. So you can't end the revision.");
            } else if (e.message.includes("The borrower didn't return the item yet. Wait for him to start the revision period")) {
                throw new Error("The borrower didn't return the item yet. Wait for him to start the revision period");
            } else if (e.message.includes("The refund cannot be given to an invalid address.")) {
                throw new Error("The refund cannot be given to an invalid address.");
            } else {
                console.log(e.message);
            }
        } else {
            console.log(e);
        }
    }
}


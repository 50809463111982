import { Formik, FormikProps } from "formik"
import { useDispatch } from "react-redux";
import { coinCreate } from "../../../../store/slices/coinSlice";
import { Grid } from "@material-ui/core";

import { FirstSectionInfoForm } from "./FirstSectionInfoForm";

import toast from "react-hot-toast";
import { CustomToaster } from "../../../Notifications/CustomToaster";
import { CouponCreationRecap } from "./CouponCreationRecap";
import { useContext, useState } from "react";
import { CouponContext } from "../../../../providers/CouponProvider";

export type CouponInfoInForm = {
    name: string;
    symbol: string;
    icon: File | null;
    initialQuantity: number;
    description: string;
}

export const CreateCouponForm = () => {
    const dispatch = useDispatch();
    const [dialogOpen, setDialogOpen] = useState(false);
    const { setLoading } = useContext(CouponContext);

    const onSubmit = async (values: CouponInfoInForm) => {
        const toastId = toast.loading('Creazione in corso...');
        setLoading(true);
        try {
            await dispatch(coinCreate({
                name: values.name,
                symbol: values.symbol,
                iconFile: values.icon,
                initialSupply: values.initialQuantity,
                description: values.description,
                decimals: 0,
                type: 'goods',
                contractFile: null
            }));
            setDialogOpen(true);
            toast.success('Coupon creato con successo');
        } catch(e) {
            toast.error('Errore durante la creazione del coupon');
            console.log(e);
        } finally {
            setLoading(false);
            toast.remove(toastId);
        }
        
    }

    const closeDialog = () => {
        setDialogOpen(false);
    }

    return (
        <>
            <CustomToaster />
            <Grid container style={{ marginBottom: '10em' }}>
                <Grid item xs={12}>
                    <Formik
                        initialValues={{
                            name: '',
                            symbol: '',
                            icon: null,
                            initialQuantity: 0,
                            description: ''
                        }}
                        onSubmit={onSubmit}
                        validate={(values) => { }}
                    >
                        {(formik: FormikProps<CouponInfoInForm>) => (
                            <form onSubmit={formik.handleSubmit} style={{
                                width: '100%',
                                height: '100%',
                                minHeight: '550px',
                            }}>
                                <CouponCreationRecap open={dialogOpen} closeDialog={closeDialog} formik={formik} />
                                <Grid container xs={12} 
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <FirstSectionInfoForm formik={formik} />
                                </Grid>
                            </form>
                        )}
                    </Formik>
                </Grid>
            </Grid>
        </>


    )
}
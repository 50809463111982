import { Box, Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { UploadItemTitle } from "../LibOfThings/UploadItem/UploadItemTitle";
import { CouponActionsHome } from "./CouponActionsHome";
import { CouponCard } from "./Coupon/MyCoupons/CouponCard";
import { useAppSelector } from "../../store/hooks";
import { useEffect, useState } from "react";
import { getTokensOwnedFromMetadata } from "../../api/coinAPI";
import { Skeleton } from "@material-ui/lab";
import { NoItemsDisplay } from "../LibOfThings/BasicComponents/NoItemsDisplay";

export const CouponHomePage = () => {
    const history = useHistory();
    const account = useAppSelector(state => state.user.currentProfile);
    const [coupons, setCoupons] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async () => {
            const address = account?.additional_properties?.commonshoodWallet;
            if (address != null) {
                const tokens = await getTokensOwnedFromMetadata(
                    address,
                    'goods',
                    1,
                    true
                );
                console.log(tokens);
                setCoupons(tokens);
            }
        })()
    }, []);

    return (
        <Box marginLeft={1}>
            <Grid container item xs={12} onClick={() => history.push('/user/wallet')} style={{
                cursor: 'pointer',
                marginTop: '1em',
                marginBottom: '0.75em'
            }}>
                <UploadItemTitle text={'I miei Coupon'} />
            </Grid>
            <CouponActionsHome />
            <Box marginTop={'2em'} marginRight={'1em'} marginBottom={'10em'}>
                <Grid container spacing={1}>
                    {
                        coupons.map((coupon: any) => {
                            return (
                                <Grid item xs={4} sm={4} md={3}>
                                    <CouponCard coupon={coupon} />
                                </Grid>
                            )
                        })
                    }
                    {
                        loading && (
                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                                <Skeleton variant="rect" height={'100%'} />
                            </Grid>
                        )
                    }
                    {
                        (coupons.length === 0 && !loading) && (
                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                                <NoItemsDisplay style={{ marginTop: '3em' }} text={'Non hai Coupon'} />
                            </Grid>
                        )
                    }
                </Grid>
            </Box>

        </Box>
    );
}
import { useTheme } from "@material-ui/core";
import { Fab, useMediaQuery } from "@material-ui/core"
import { useContext } from "react";
import { RealmContext } from "../../../providers/RealmProvider";

export const TutorialFab = ({ callback }: {
    callback: () => void
}) => {
    const theme = useTheme();
    const { isCurrentRealmCOSO } = useContext(RealmContext);
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        <>
            {
                isCurrentRealmCOSO && (
                    <>
                        {
                            isMobile ? <></> : <>
                                <Fab onClick={() => { callback() }} size="medium" style={{ position: 'fixed', left: '10px', bottom: '20px', background: 'white' }} >
                                    <img src="/svgs/tutorial_logo.svg" style={{ width: '40px', height: '40px' }} />
                                </Fab>
                            </>}
                    </>
                )
            }

        </>

    )
}
import { Box, useMediaQuery } from "@material-ui/core"

export const OrangeLine = ({ width }: {
    width?: string
}) => {
    const isMobile = useMediaQuery('(max-width:600px)');

    return (
        <Box margin={isMobile ? '20px' : ''} textAlign={isMobile ? '' : 'center'}>
            {
                isMobile ?
                    <img src="/svgs/coupons/orange_line_coupon_creation_mobile.svg" alt="line" style={{ width: '100%' }} /> :
                    <img src="/svgs/coupons/orange_line_form_horizontal_full.svg" alt="line" style={{ width: width == null ? '3%' : width }} />
            }
        </Box>
    )
}